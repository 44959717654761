import { Observable, Subject, Subscription } from "rxjs";
import { Context, ShadowPass, Texture2D } from "webgl-operate";

import { assertExists } from "../helpers";
import { Config } from "./dmRenderer";

export class ShadowProvider {
    private _shadowPass?: ShadowPass;
    private readonly _context: Context;

    private readonly _config$: <K extends keyof Config>(property: K) => Observable<Config[K]>;
    private readonly _subscriptions: Subscription[] = [];
    private readonly _shadowMap$ = new Subject<Texture2D | undefined>();

    public constructor(context: Context, config$: <K extends keyof Config>(property: K) => Observable<Config[K]>) {
        this._context = context;
        this._config$ = config$;

        this._config$("shadow.enabled").subscribe(this.setEnabled.bind(this));
    }

    public release(): void {
        this.setEnabled(false);
    }

    public get shadowMap$(): Observable<Texture2D | undefined> {
        return this._shadowMap$.asObservable();
    }

    public frame(draw: () => void): void {
        if (this._shadowPass === undefined) {
            return;
        }

        this._shadowPass.frame(draw);
    }

    private setEnabled(enabled: boolean): void {
        if (enabled) {
            this.enable();
        } else {
            this.disable();
        }
    }

    private enable(): void {
        if (this._shadowPass !== undefined) {
            return;
        }

        const shadowPass = new ShadowPass(this._context);
        shadowPass.initialize(ShadowPass.ShadowMappingType.ExponentialVarianceShadowMapping, [512, 512]);
        this._shadowPass = shadowPass;

        this._subscriptions.push(
            this._config$("shadow.blurSize").subscribe((blurSize: GLsizei): void => {
                shadowPass.blurSize = blurSize;
            })
        );
        this._subscriptions.push(
            this._config$("shadow.mapSize").subscribe((size: GLsizei): void => {
                shadowPass.resize([size, size]);
                shadowPass.resizeBlurTexture([size, size]);
            })
        );

        this._shadowMap$.next(shadowPass.shadowMapTexture);
    }

    private disable(): void {
        if (this._shadowPass === undefined) {
            return;
        }

        this._shadowPass.uninitialize();
        this._shadowPass = undefined;
        while (this._subscriptions.length > 0) {
            assertExists(this._subscriptions.pop()).unsubscribe();
        }

        this._shadowMap$.next(undefined);
    }
}
