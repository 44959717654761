import { BehaviorSubject, Observable } from "rxjs";
import { Context, Invalidate, MouseEventProvider, Renderer, TouchEventProvider, vec2 } from "webgl-operate";
import { GLclampf4, GLfloat2, GLsizei2 } from "webgl-operate/lib/tuples";

import { assertExists } from "../helpers";
import { MagnetSetController } from "../magnetSetController";
import { ObservableProperty } from "../observableProperty";
import { ParticleController } from "../particleController";
import { DmRendererImplementation } from "./dmRendererImplementation";
import { PickResult } from "./objectPicker";

export { PickResult };

export class Config {
    public "magnets.height": GLfloat = 0.04; // World space
    public "magnets.offset": GLfloat = 0.15; // World space
    public "magnets.radius": GLfloat = 0.03; // World space
    public "magnets.anchorWidth": GLfloat = 2; // Pixels
    public "magnets.color": GLclampf4 = [0.8, 0.2, 0.2, 0.5];
    public "particles.heightScale": GLfloat = 0.1; // World space
    public "particles.heightBase": GLfloat = 0.0; // World space
    public "particles.radius": GLfloat = 0.01; // World space
    public "shadow.mapSize": GLsizei = 2048;
    public "shadow.blurSize": GLsizei = 9;
    public "shadow.positiveExponent": GLfloat = 30;
    public "shadow.negativeExponent": GLfloat = 10;
    public "shadow.bleedingReduction": GLfloat = 0.3;
    public "shadow.enabled": boolean = false;
    public "reflection.enabled": boolean = false;
    public "fxaa.enabled": boolean = false;
}

export interface RendererConfig {
    frameSize: GLsizei2;
    canvasSize: GLsizei2;
    clearColor: GLclampf4;
}

export class DmRenderer extends Renderer {
    private static readonly PRESETS: { [name: string]: Partial<Config> } = {
        low: { "fxaa.enabled": false, "shadow.enabled": false, "reflection.enabled": false },
        medium: { "fxaa.enabled": true, "shadow.enabled": false, "reflection.enabled": true },
        high: {
            "fxaa.enabled": true,
            "shadow.enabled": true,
            "reflection.enabled": true,
            "shadow.mapSize": 2048,
            "shadow.blurSize": 9,
        },
        maximum: {
            "fxaa.enabled": true,
            "shadow.enabled": true,
            "reflection.enabled": true,
            "shadow.mapSize": 4096,
            "shadow.blurSize": 19,
        },
    };

    public readonly preset: ObservableProperty<string | undefined>;

    private _implementation?: DmRendererImplementation;
    private readonly _magnetController: MagnetSetController;
    private readonly _particleController: ParticleController;
    private readonly _rendererConfig$: BehaviorSubject<RendererConfig>;
    private _particleHeights$?: Observable<Float32Array | undefined>;
    private _particleColors$?: Observable<Float32Array | undefined>;
    private _particleSchemeUrl$?: Observable<string | undefined>;
    private _preset: string | undefined = "low";
    private readonly _preset$ = new BehaviorSubject(this._preset);
    private _config: Config = { ...new Config(), ...DmRenderer.PRESETS[assertExists(this._preset)] };
    private readonly _config$: BehaviorSubject<Config>;
    private _redrawRequested = false;

    public constructor(magnetController: MagnetSetController, particleController: ParticleController) {
        super();

        this._magnetController = magnetController;
        this._particleController = particleController;

        const getPreset = (): string | undefined => this._preset;
        const setPreset = (preset: string | undefined): void => {
            if (preset !== undefined) {
                if (!this.presets.includes(preset)) {
                    throw new RangeError(`Unknown preset: ${preset}`);
                }
                const settings = DmRenderer.PRESETS[preset];

                this._config = { ...this._config, ...settings };
                this._config$.next(this._config);
            }

            this._preset = preset;
            this._preset$.next(preset);
        };

        this.preset = {
            // eslint-disable-next-line id-length
            $: this._preset$,
            get value(): string | undefined {
                return getPreset();
            },
            set value(preset: string | undefined) {
                setPreset(preset);
            },
        };

        this._config$ = new BehaviorSubject(this._config);
        this._rendererConfig$ = new BehaviorSubject({
            frameSize: this._frameSize,
            canvasSize: this._canvasSize,
            clearColor: this._clearColor,
        });
    }

    public get config$(): Observable<Config> {
        return this._config$.asObservable();
    }

    public get<K extends keyof Config>(key: K): Config[K] {
        return this._config[key];
    }

    public set<K extends keyof Config>(key: K, value: Config[K]): void {
        // Make sure each config update results in a distinct object to avoid breaking rxjs' distinctUntilKeyChanged()
        this._config = { ...this._config };
        this._config[key] = value;
        this._config$.next(this._config);

        if (this.preset.value !== undefined) {
            if (Object.getOwnPropertyNames(DmRenderer.PRESETS[this.preset.value]).includes(key)) {
                this.preset.value = undefined;
            }
        }
    }

    // eslint-disable-next-line class-methods-use-this
    public get presets(): string[] {
        return Object.getOwnPropertyNames(DmRenderer.PRESETS);
    }

    public set particleHeights(heights: Observable<Float32Array | undefined> | undefined) {
        this._particleHeights$ = heights;
        if (this._implementation !== undefined) {
            this._implementation.particleHeights$ = heights;
        }
    }

    public set particleColors(colors: Observable<Float32Array | undefined> | undefined) {
        this._particleColors$ = colors;
        if (this._implementation !== undefined) {
            this._implementation.particleColors$ = colors;
        }
    }

    public set particleSchemeUrl(url: Observable<string | undefined> | undefined) {
        this._particleSchemeUrl$ = url;
        if (this._implementation !== undefined) {
            this._implementation.particleSchemeUrl$ = url;
        }
    }

    public pick(viewportPosition: GLfloat2 | vec2): PickResult | undefined {
        const implementation = assertExists(this._implementation, "Must be initialized");
        return implementation.pick(viewportPosition);
    }

    protected onInitialize(
        context: Context,
        invalidate: Invalidate,
        mouseEventProvider: MouseEventProvider,
        touchEventProvider: TouchEventProvider
    ): boolean {
        this._implementation = new DmRendererImplementation(
            context,
            mouseEventProvider,
            touchEventProvider,
            this.config$,
            this._rendererConfig$,
            this._magnetController,
            this._particleController
        );

        this._implementation.particleHeights$ = this._particleHeights$;
        this._implementation.particleColors$ = this._particleColors$;
        this._implementation.particleSchemeUrl$ = this._particleSchemeUrl$;

        this._implementation.redraw$.subscribe((force: boolean): void => {
            invalidate(force);
            this._redrawRequested = true;
        });

        return true;
    }

    protected onUninitialize(): void {
        const implementation = assertExists(this._implementation, "Must be initialized");

        implementation.release();
        this._implementation = undefined;
    }

    protected onUpdate(): boolean {
        const implementation = assertExists(this._implementation, "Must be initialized");

        if (this._altered.frameSize || this._altered.canvasSize || this._altered.clearColor) {
            this._rendererConfig$.next({
                frameSize: this._frameSize,
                canvasSize: this._canvasSize,
                clearColor: this._clearColor,
            });
        }

        implementation.update();

        return this._redrawRequested;
    }

    protected onPrepare(): void {
        // Reset this first to let passes request subsequent redraws
        this._redrawRequested = false;

        assertExists(this._implementation, "Must be initialized").prepare();

        this._altered.reset();
    }

    protected onFrame(): void {
        const implementation = assertExists(this._implementation, "Must be initialized");
        implementation.frame();
    }

    protected onSwap(): void {
        const implementation = assertExists(this._implementation, "Must be initialized");
        implementation.swap();
    }
}
