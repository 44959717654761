import { Context, Texture2D, Wizard } from "webgl-operate";

export function glEnumFromString(name: string, gl: WebGLRenderingContext): GLenum {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value = (gl as any)[name];
    if (value === undefined) {
        throw new TypeError(`Unknown/unsupported GL enum: ${name}`);
    }
    if (typeof value !== "number") {
        throw new TypeError(`Expected type number for property ${name} on WebGLRenderingContext`);
    }

    return value;
}

export function createGBuffer(
    context: Context,
    name: string,
    internalFormat: GLenum,
    format: GLenum,
    type: GLenum,
    filter?: GLenum
): Texture2D {
    const gl = context.gl as WebGLRenderingContext;
    const filterOrDefault = filter === undefined ? gl.NEAREST : filter;

    const texture = new Texture2D(context, `${name}Buffer`);
    texture.initialize(1, 1, internalFormat, format, type);
    texture.filter(filterOrDefault, filterOrDefault);
    texture.wrap(gl.CLAMP_TO_EDGE, gl.CLAMP_TO_EDGE);

    return texture;
}

export function createRgbaBuffer(context: Context, name: string, filter?: GLenum): Texture2D {
    const gl = context.gl as WebGLRenderingContext;
    const rgba8 = Wizard.queryInternalTextureFormat(context, gl.RGBA, Wizard.Precision.byte)[0];
    return createGBuffer(context, name, rgba8, gl.RGBA, gl.UNSIGNED_BYTE, filter);
}

export function createDepthBuffer(context: Context, name: string): Texture2D {
    const gl = context.gl as WebGLRenderingContext;
    return createGBuffer(context, name, gl.DEPTH_COMPONENT16, gl.DEPTH_COMPONENT, gl.UNSIGNED_SHORT);
}
