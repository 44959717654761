import { combineLatest } from "rxjs";
import { Program, VertexArray } from "webgl-operate";

import { RenderPass, RenderPassParameters } from "../renderPass";
import { ParticleSharedState } from "./particlePassGroup";
import fragmentShader from "./particleReflectionPass.frag";
import vertexShader from "./particleReflectionPass.vert";

export class ParticleReflectionPass extends RenderPass {
    private readonly _shared: ParticleSharedState;
    private readonly _vertexArray: VertexArray;
    private readonly _program: Program;
    private _instanceCount: GLsizei = 0;

    public constructor(shared: ParticleSharedState, baseParameters: RenderPassParameters) {
        super(baseParameters, "ParticleReflection");
        this._shared = shared;

        const gl = this._context.gl as WebGLRenderingContext;

        // Set up shader
        this._program = this.loadShader(vertexShader, fragmentShader, ["in_vertex", "in_height", "in_colorValue"]);
        this._program.bind();
        gl.uniform1i(this._program.uniform("schemeTexture"), 0);
        gl.uniform1i(this._program.uniform("positionTexture"), 1);
        gl.uniform1i(this._program.uniform("shadowMap"), 2);

        this._vertexArray = this.createVertexArray([
            { buffer: shared.vertexBuffer, size: 3, type: gl.FLOAT },
            { buffer: shared.heightBuffer, size: 1, type: gl.FLOAT, divisor: 1 },
            { buffer: shared.colorBuffer, size: 1, type: gl.FLOAT, divisor: 1 },
        ]);

        this._textures[0] = this._shared.schemeTexture;

        this.createSubscriptions();
    }

    protected onRelease(): void {
        this._vertexArray.uninitialize();
        this._program.uninitialize();
    }

    protected onFrame(): void {
        const gl = this._context.gl as WebGLRenderingContext;
        const gl2 = this._context.gl2facade;

        gl.enable(gl.DEPTH_TEST);

        this._program.bind();
        this._vertexArray.bind();

        gl2.drawArraysInstanced(gl.TRIANGLE_STRIP, 0, this._shared.vertexCount, this._instanceCount);

        gl.disable(gl.DEPTH_TEST);
    }

    private createSubscriptions(): void {
        this.subscribeTexture(this._shared.positionTexture$, 1);
        this.subscribeUniform1i(this._shared.positionTextureSize$, this._program, "positionTextureSize");
        this.subscribeUniform1f(this.config$("particles.heightScale"), this._program, "heightScale");
        this.subscribeUniform1f(this.config$("particles.heightBase"), this._program, "heightBase");
        this.subscribeUniform1f(this.config$("particles.radius"), this._program, "radius");

        this.subscribeUniformMat4(this._camera.viewProjection$, this._program, "viewProjection");
        this.subscribeUniform3f(this._camera.eye$, this._program, "eye");
        this.subscribeUniform3f(this._light.position$, this._program, "lightPosition");
        this.subscribeUniformColor(this._light.color$, this._program, "lightColor");

        this.subscribeTexture(this._shared.shadowMap$, 2);
        this.subscribeUniform1i(this._shared.shadowEnabled$, this._program, "shadowEnabled");
        this.subscribeUniform2f(
            combineLatest(this.config$("shadow.positiveExponent"), this.config$("shadow.negativeExponent")),
            this._program,
            "shadowExponents"
        );
        this.subscribeUniform1f(this.config$("shadow.bleedingReduction"), this._program, "shadowBleedingReduction");
        this.subscribeUniformMat4(this._light.viewProjection$, this._program, "shadowViewProjection");
        this.subscribeUniform2f(this._light.nearFar$, this._program, "shadowNearFar");

        this._shared.instanceCount$.pipe(this.tapRedraw()).subscribe((instanceCount: GLsizei): void => {
            this._instanceCount = instanceCount;
        });
    }
}
