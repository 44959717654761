
/* spellchecker: disable */

import { CameraModifier } from './cameramodifier';

/* spellchecker: enable */

export class PanModifier extends CameraModifier {

    update(): void {

    }

}
