import * as jsonrpc from "jsonrpc-lite";

import { coerceToArray } from "./helpers";

/* eslint-disable @typescript-eslint/no-type-alias */
export type RpcParam = jsonrpc.Defined;
export type RpcParams = jsonrpc.RpcParams;
export type RpcResult = jsonrpc.Defined | void;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RpcFunction = (...params: any[]) => Promise<RpcResult> | RpcResult;
declare type IParsedObject =
    | jsonrpc.IParsedObjectSuccess
    | jsonrpc.IParsedObjectNotification
    | jsonrpc.IParsedObjectRequest
    | jsonrpc.IParsedObjectError
    | jsonrpc.IParsedObjectInvalid;
/* eslint-enable @typescript-eslint/no-type-alias */

interface PromiseResolver {
    resolve(value: RpcResult): void;
    reject(error: object): void;
}

const apply: (func: RpcFunction, params?: RpcParams) => RpcResult = (
    func: RpcFunction,
    params?: RpcParams
): RpcResult => {
    if (params === undefined) {
        return func();
    }
    if (Array.isArray(params)) {
        return func.apply(func, params);
    }

    return func(params);
};

export class RpcEndpoint {
    public onsend?: (data: string) => void;

    private _nextId: number = 0;
    private readonly _functions: Map<string, RpcFunction> = new Map();
    private readonly _pendingResponses: Map<jsonrpc.ID, PromiseResolver> = new Map<jsonrpc.ID, PromiseResolver>();

    // eslint-disable-next-line require-await
    public async requestRemote(method: string, ...params: RpcParam[]): Promise<RpcResult> {
        const id = this._nextId;
        this._nextId += 1;
        const request = jsonrpc.request(id, method, params);
        this._send(JSON.stringify(request));

        return this.createPendingResponse(id);
    }

    // eslint-disable-next-line @typescript-eslint/require-await
    public async notifyRemote(method: string, ...params: RpcParam[]): Promise<void> {
        const notification = jsonrpc.notification(method, params);
        this._send(JSON.stringify(notification));
    }

    // eslint-disable-next-line @typescript-eslint/require-await
    public async postMessage(data: string): Promise<void> {
        const messages: IParsedObject[] = coerceToArray(jsonrpc.parse(data));

        for (const message of messages) {
            switch (message.type) {
                case "notification":
                    this.dispatchNotification(message.payload);
                    break;

                case "request": {
                    const request = message.payload;
                    const result = this.dispatchRequest(request);
                    this._send(JSON.stringify(result));
                    break;
                }
                case "success": {
                    const success = message.payload;
                    this.takePendingResponse(success.id).resolve(success.result);
                    break;
                }
                case "error": {
                    const error = message.payload;
                    this.takePendingResponse(error.id).reject(error.error);
                    break;
                }
                default:
                    console.log(`Unknown: ${JSON.stringify(message)}`);
            }
        }
    }

    public register(name: string, func: RpcFunction): void {
        this._functions.set(name, func);
    }

    private dispatchNotification(notification: jsonrpc.NotificationObject): void {
        const func: RpcFunction | undefined = this._functions.get(notification.method);
        if (func === undefined) {
            console.error(`RPC call to unknown notification method: ${notification.method}`);
        } else {
            try {
                apply(func, notification.params);
            } catch (error) {
                console.error(`RPC notification ${JSON.stringify(notification)}: ${error}`);
            }
        }
    }

    private dispatchRequest(request: jsonrpc.RequestObject): jsonrpc.JsonRpc {
        const func: RpcFunction | undefined = this._functions.get(request.method);
        if (func === undefined) {
            return jsonrpc.error(request.id, jsonrpc.JsonRpcError.methodNotFound(request.method));
        }

        try {
            const result = apply(func, request.params);

            return jsonrpc.success(request.id, result === undefined ? null : result);
        } catch (error) {
            return jsonrpc.error(request.id, new jsonrpc.JsonRpcError(String(error), -1));
        }
    }

    // eslint-disable-next-line @typescript-eslint/promise-function-async
    private createPendingResponse(id: jsonrpc.ID): Promise<RpcResult> {
        return new Promise<RpcResult>((resolve: (value: RpcResult) => void, reject: (error: object) => void): void => {
            this._pendingResponses.set(id, { reject, resolve });
        });
    }

    private takePendingResponse(id: jsonrpc.ID): PromiseResolver {
        const resolver: PromiseResolver | undefined = this._pendingResponses.get(id);
        if (resolver === undefined) {
            throw Error(`No pending response with id ${id}`);
        }

        this._pendingResponses.delete(id);

        return resolver;
    }

    private _send(data: string): void {
        if (this.onsend === undefined) {
            throw new TypeError("onsend is undefined");
        }

        this.onsend(data);
    }
}
