import { distinctUntilChangedImmutable } from "distinct-until-changed-immutable";
import { MonoTypeOperatorFunction, Observable, Subject, Subscription } from "rxjs";
import { mapTo, pluck } from "rxjs/operators";
import { Buffer, Color, Context, mat4, Program, Shader, Texture2D, vec2, vec3, vec4, VertexArray } from "webgl-operate";
import { GLfloat2, GLfloat3, GLfloat4 } from "webgl-operate/lib/tuples";

import { subscribeBuffer, subscribeUniform, tapRedraw } from "../operators";
import { Camera } from "./camera";
import { Config } from "./dmRenderer";
import { Light } from "./light";

export interface VertexAttributeParameters {
    buffer: Buffer;
    size: GLint;
    type: GLenum;
    normalized?: GLboolean;
    stride?: GLsizei;
    offset?: GLintptr;
    divisor?: GLuint;
}

export interface RenderPassParameters {
    context: Context;
    camera: Camera;
    light: Light;
    config$: Observable<Config>;
}

export abstract class RenderPass {
    public readonly name: string;
    public readonly redraw$: Subject<boolean> = new Subject();

    protected readonly _config$: Observable<Config>;
    protected readonly _context: Context;
    protected readonly _gl: WebGLRenderingContext;
    protected readonly _camera: Camera;
    protected readonly _light: Light;

    protected readonly _textures: (Texture2D | undefined)[] = [];

    private _redrawRequested = false;

    protected constructor({ context, camera, light, config$ }: RenderPassParameters, name: string) {
        this._context = context;
        this._camera = camera;
        this._light = light;
        this._config$ = config$;
        this.name = name;
        this._gl = this._context.gl as WebGLRenderingContext;

        this.redraw$.subscribe((): void => {
            this._redrawRequested = true;
        });
    }

    public get redrawRequested(): boolean {
        return this._redrawRequested;
    }

    public release(): void {
        this.onRelease();
    }

    /**
     *
     */
    public update(): void {
        this.onUpdate();
    }

    /**
     * Prepares the rendering of the next frame (or subsequent frames when multi-frame rendering).
     * This is part of the controllable interface. The renderer should reconfigure as lazy as possible.
     */
    public prepare(): void {
        this.onPrepare();
        this._redrawRequested = false;
    }

    /**
     * Controllable interface intended to trigger rendering of a full pass of the renderer that results in either an
     * intermediate frame for accumulation to a full multi-frame or full frame for itself.  The inheritor should invoke
     * frames of relevant rendering and processing stages.
     * @param camera - The current camera forwarded to onFrame.
     */
    public frame(): void {
        const gl = this._context.gl as WebGLRenderingContext;

        for (let unit = 0; unit < this._textures.length; unit += 1) {
            const texture = this._textures[unit];
            if (texture !== undefined) {
                texture.bind(gl.TEXTURE0 + unit);
            }
        }

        this.onFrame();
    }

    public tapRedraw<T>(force: boolean = false): MonoTypeOperatorFunction<T> {
        return tapRedraw(this.redraw$, force);
    }

    public subscribeUniform<T>(
        value: Observable<T>,
        program: Program,
        uniform: string,
        setter: (uniform: WebGLUniformLocation, value: T) => void,
        log = false
    ): Subscription {
        return subscribeUniform(program, uniform, setter, value, this.redraw$, log);
    }

    public subscribeUniformMat4(value: Observable<mat4>, program: Program, uniform: string, log = false): Subscription {
        return this.subscribeUniform(
            value,
            program,
            uniform,
            (location: WebGLUniformLocation, mat: mat4): void => {
                this._gl.uniformMatrix4fv(location, false, mat);
            },
            log
        );
    }

    public subscribeUniform1f(
        value: Observable<GLfloat>,
        program: Program,
        uniform: string,
        log = false
    ): Subscription {
        return this.subscribeUniform(value, program, uniform, this._gl.uniform1f.bind(this._gl), log);
    }

    public subscribeUniform2f(
        value: Observable<GLfloat2 | vec2>,
        program: Program,
        uniform: string,
        log = false
    ): Subscription {
        return this.subscribeUniform(value, program, uniform, this._gl.uniform2fv.bind(this._gl), log);
    }

    public subscribeUniform3f(
        value: Observable<GLfloat3 | vec3>,
        program: Program,
        uniform: string,
        log = false
    ): Subscription {
        return this.subscribeUniform(value, program, uniform, this._gl.uniform3fv.bind(this._gl), log);
    }

    public subscribeUniform4f(
        value: Observable<GLfloat4 | vec4>,
        program: Program,
        uniform: string,
        log = false
    ): Subscription {
        return this.subscribeUniform(value, program, uniform, this._gl.uniform4fv.bind(this._gl), log);
    }

    public subscribeUniform1i(value: Observable<GLint>, program: Program, uniform: string, log = false): Subscription {
        return this.subscribeUniform(value, program, uniform, this._gl.uniform1i.bind(this._gl), log);
    }

    public subscribeUniformColor(
        value: Observable<Color>,
        program: Program,
        uniform: string,
        log = false
    ): Subscription {
        const gl = this._context.gl as WebGLRenderingContext;

        return this.subscribeUniform(
            value,
            program,
            uniform,
            (location: WebGLUniformLocation, color: Color): void => {
                gl.uniform4fv(location, color.rgba);
            },
            log
        );
    }

    public subscribeBuffer(
        data: Observable<ArrayBufferView | ArrayBuffer>,
        buffer: Buffer,
        usage: GLenum
    ): Subscription {
        return subscribeBuffer(data, buffer, usage, this.redraw$);
    }

    protected subscribeTexture(texture$: Observable<Texture2D | undefined>, unit: GLuint): Subscription {
        return texture$.pipe(this.tapRedraw()).subscribe((texture: Texture2D | undefined): void => {
            this._textures[unit] = texture;
            while (this._textures.length > 0 && this._textures[this._textures.length - 1] === undefined) {
                this._textures.pop();
            }
        });
    }

    protected config$<K extends keyof Config>(key: K): Observable<Config[K]> {
        return this._config$.pipe(
            pluck(key),
            distinctUntilChangedImmutable()
        );
    }

    protected redrawOn(trigger: Observable<unknown>, force: boolean = false): Subscription {
        return trigger.pipe(mapTo(force)).subscribe(this.redraw$);
    }

    protected loadShader(vertexShaderSource: string, fragmentShaderSource: string, attributes?: string[]): Program {
        const gl = this._context.gl as WebGLRenderingContext;

        const vertexShader = new Shader(this._context, gl.VERTEX_SHADER, `${this.name}.vertexShader`);
        vertexShader.initialize(vertexShaderSource);
        const fragmentShader = new Shader(this._context, gl.FRAGMENT_SHADER, `${this.name}.fragmentShader`);
        fragmentShader.initialize(fragmentShaderSource);
        const program = new Program(this._context, `${this.name}.program`);
        program.initialize([vertexShader, fragmentShader]);

        if (attributes !== undefined) {
            for (let index = 0; index < attributes.length; index += 1) {
                program.attribute(attributes[index], index);
            }
        }

        // Must relink the program after calls to bindAttribLocation (via above program.attribute())
        program.link();

        return program;
    }

    protected createVertexArray(attributes: VertexAttributeParameters[]): VertexArray {
        const gl2 = this._context.gl2facade;

        const vertexArray = new VertexArray(this._context, `${this.name}.vertexArray`);
        vertexArray.initialize(
            (): void => {
                for (let index = 0; index < attributes.length; index += 1) {
                    const attribute = attributes[index];
                    attribute.buffer.attribEnable(
                        index,
                        attribute.size,
                        attribute.type,
                        attribute.normalized,
                        attribute.stride,
                        attribute.offset,
                        true,
                        false
                    );
                    if (attribute.divisor !== undefined) {
                        gl2.vertexAttribDivisor(index, attribute.divisor);
                    }
                }
            },
            (): void => {
                // No unbind required
            }
        );

        return vertexArray;
    }

    /**
     * Actual update call specified by inheritor.
     */
    // eslint-disable-next-line class-methods-use-this
    protected onUpdate(): void {
        // Empty by default
    }

    /**
     * Actual prepare call specified by inheritor.
     */
    // eslint-disable-next-line class-methods-use-this
    protected onPrepare(): void {
        // Empty by default
    }

    /**
     * Actual frame call specified by inheritor.
     */
    protected abstract onFrame(): void;

    protected abstract onRelease(): void;
}
