import { combineLatest, Subject } from "rxjs";
import { Buffer } from "webgl-operate";
import { GLfloat2 } from "webgl-operate/lib/tuples";

import { assertExists } from "../../helpers";
import { MagnetController } from "../../magnetController";
import { MagnetSetController } from "../../magnetSetController";
import { RenderPass, RenderPassParameters } from "../renderPass";
import { MagnetAnchorReflectionPass } from "./magnetAnchorReflectionPass";
import { MagnetAnchorScenePass } from "./magnetAnchorScenePass";
import { MagnetAnchorShadowPass } from "./magnetAnchorShadowPass";
import { MagnetTopScenePass } from "./magnetTopScenePass";
import { MagnetTopShadowPass } from "./magnetTopShadowPass";

/* eslint-disable @typescript-eslint/indent */
const SQRT3 = Math.sqrt(3);
// prettier-ignore
const TOP_VERTEX_DATA = new Float32Array([
    /*
     * These vertices consitute a triangle strip that forms the front half (3 sides) of a hexagonal prism with full top
     * cap and no bottom cap. During rendering, they are discretely rotated in 60° steps (to always be congruent with
     * the grid cell) so that the middle side faces the camera. The rotation is computed for each instance individually
     * in the vertex shader. The fragment shader then performs a simplified first-order-rays-only raytracing of a
     * cylinder corresponding to the hexagons incircle and discards any fragments outside of the cylinder. The result is
     * an instanced, always pixel-perfect rendering of a cylinder.
    */
  /*      X     Y   Z */
    // Sides
     0, 0,  2 / SQRT3,
     0, 1,  2 / SQRT3,
     1, 0,  1 / SQRT3,
     1, 1,  1 / SQRT3,
     1, 0, -1 / SQRT3,
     1, 1, -1 / SQRT3,
     0, 0, -2 / SQRT3,
     0, 1, -2 / SQRT3,

     // Top
     0, 1, -2 / SQRT3,
     1, 1, -1 / SQRT3,
    -1, 1, -1 / SQRT3,
     1, 1,  1 / SQRT3,
    -1, 1,  1 / SQRT3,
     0, 1,  2 / SQRT3,
]);

// prettier-ignore
const ANCHOR_VERTEX_DATA = new Float32Array([
  /* X   Y */
    -1,  0,
     1,  0,
    -1,  1,
     1,  1,
]);
/* eslint-enable @typescript-eslint/indent */

export interface MagnetSharedState {
    instanceCount: GLsizei;
    readonly instanceBuffer: Buffer;
    readonly topVertexCount: GLsizei;
    readonly topVertexBuffer: Buffer;
    readonly anchorVertexCount: GLsizei;
    readonly anchorVertexBuffer: Buffer;
}

export class MagnetPassGroup extends RenderPass {
    public readonly topScenePass: MagnetTopScenePass;
    public readonly topShadowPass: MagnetTopShadowPass;

    public readonly anchorScenePass: MagnetAnchorScenePass;
    public readonly anchorShadowPass: MagnetAnchorShadowPass;
    public readonly anchorReflectionPass: MagnetAnchorReflectionPass;

    private readonly _shared: MagnetSharedState;

    private _positionData: Float32Array = new Float32Array();
    private readonly _instanceIdById$: Subject<Map<number, GLuint>> = new Subject();
    private readonly _instanceIdById: Map<number, GLuint> = new Map();

    public constructor(magnetController: MagnetSetController, baseParameters: RenderPassParameters) {
        super(baseParameters, "Magnet");

        magnetController.magnets$.subscribe(this.observeMagnet);

        this._shared = {
            instanceCount: 0,
            instanceBuffer: this.createInstanceBuffer(),
            topVertexCount: TOP_VERTEX_DATA.length / 3,
            topVertexBuffer: this.createTopVertexBuffer(),
            anchorVertexCount: ANCHOR_VERTEX_DATA.length / 2,
            anchorVertexBuffer: this.createAnchorVertexBuffer(),
        };

        this.topScenePass = new MagnetTopScenePass(this._shared, baseParameters);
        this.topScenePass.redraw$.subscribe(this.redraw$);
        this.topShadowPass = new MagnetTopShadowPass(this._shared, baseParameters);
        this.topShadowPass.redraw$.subscribe(this.redraw$);
        this.anchorScenePass = new MagnetAnchorScenePass(this._shared, baseParameters);
        this.anchorScenePass.redraw$.subscribe(this.redraw$);
        this.anchorShadowPass = new MagnetAnchorShadowPass(this._shared, baseParameters);
        this.anchorShadowPass.redraw$.subscribe(this.redraw$);
        this.anchorReflectionPass = new MagnetAnchorReflectionPass(this._shared, baseParameters);
        this.anchorReflectionPass.redraw$.subscribe(this.redraw$);
    }

    public magnetId(instanceId: GLuint): number {
        for (const [id, candidateInstanceId] of this._instanceIdById) {
            if (candidateInstanceId === instanceId) {
                return id;
            }
        }

        throw new RangeError(`Instance ID out of range: ${instanceId}`);
    }

    public magnetPosition(instanceId: GLuint): GLfloat2 {
        return [this._positionData[instanceId * 2], this._positionData[instanceId * 2 + 1]];
    }

    protected onRelease(): void {
        this.topScenePass.release();
        this.topShadowPass.release();
        this.anchorScenePass.release();
        this.anchorShadowPass.release();
        this.anchorReflectionPass.release();
        this._shared.topVertexBuffer.uninitialize();
        this._shared.anchorVertexBuffer.uninitialize();
    }

    protected onUpdate(): void {
        this.topScenePass.update();
        this.topShadowPass.update();
        this.anchorScenePass.update();
        this.anchorShadowPass.update();
        this.anchorReflectionPass.update();
    }

    protected onPrepare(): void {
        this._shared.instanceCount = this._instanceIdById.size;
        this.topScenePass.prepare();
        this.topShadowPass.prepare();
        this.anchorScenePass.prepare();
        this.anchorShadowPass.prepare();
        this.anchorReflectionPass.prepare();
    }

    // eslint-disable-next-line class-methods-use-this
    protected onFrame(): void {
        throw new Error("Don't call MagnetPassGroup.frame(), call MagnetPassGroup.[xyz]Pass.frame() instead!");
    }

    private createTopVertexBuffer(): Buffer {
        const gl = this._context.gl as WebGLRenderingContext;

        const buffer = new Buffer(this._context, `${this.name}.topVertexBuffer`);
        buffer.initialize(gl.ARRAY_BUFFER);
        buffer.data(TOP_VERTEX_DATA, gl.STATIC_DRAW);

        return buffer;
    }

    private createAnchorVertexBuffer(): Buffer {
        const gl = this._context.gl as WebGLRenderingContext;

        const buffer = new Buffer(this._context, `${this.name}.anchorVertexBuffer`);
        buffer.initialize(gl.ARRAY_BUFFER);
        buffer.data(ANCHOR_VERTEX_DATA, gl.STATIC_DRAW);

        return buffer;
    }

    private createInstanceBuffer(): Buffer {
        const gl = this._context.gl as WebGLRenderingContext;

        const buffer = new Buffer(this._context, `${this.name}.instanceBuffer`);
        buffer.initialize(gl.ARRAY_BUFFER);

        return buffer;
    }

    private reindex(): GLsizei {
        let instanceId = 0;
        for (const id of this._instanceIdById.keys()) {
            this._instanceIdById.set(id, instanceId);
            instanceId += 1;
        }

        const instanceCount = instanceId;
        this._positionData = new Float32Array(instanceCount * 2);

        this._instanceIdById$.next(this._instanceIdById);

        return instanceCount;
    }

    private readonly observeMagnet = (magnet: MagnetController): void => {
        const subscription = combineLatest(magnet.position.$, this._instanceIdById$)
            .pipe(this.tapRedraw())
            .subscribe(([position, instanceIdById]: [GLfloat2, Map<number, GLuint>]): void => {
                const gl = this._context.gl as WebGLRenderingContext;
                this._positionData.set(position, assertExists(instanceIdById.get(magnet.id)) * 2);
                this._shared.instanceBuffer.data(this._positionData, gl.STREAM_DRAW, true, false);
            });

        /*
         * Subscribe to completion (=> remove magnet), must do this outside of combineLatest because that only completes
         * after _all_ source have completed, which this._instanceIdById never will
         */
        magnet.position.$.subscribe(undefined, undefined, (): void => {
            subscription.unsubscribe();
            this._instanceIdById.delete(magnet.id);
            this.reindex();
        });

        this._instanceIdById.set(magnet.id, -1);
        this.reindex();
    };
}
