/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */

import { mat4, vec2, vec3, vec4 } from "gl-matrix";
import normalizeWheel from "normalize-wheel";
import {
    Camera,
    EventHandler,
    gl_matrix_extensions,
    Invalidate,
    MouseEventProvider,
    TouchEventProvider,
} from "webgl-operate";

import { assert, assertExists } from "../helpers";
import { intersectMouseRayPlane } from "./math";

/**
 * Navigation modes used for identification of the current navigation intend, which is derived based on the event
 * types or gestures, regardless of the active navigation metaphor and its constraints.
 */
export enum NavigationModes {
    Pan,
    Rotate,
}

export class Navigation {
    private static readonly ROTATION_DOF: vec2 = vec2.fromValues(Math.PI * -0.5, Math.PI * -0.5);

    public binding: Map<string, NavigationModes> = new Map([
        ["0+ctrl", NavigationModes.Pan],
        ["0+meta", NavigationModes.Pan],
        ["0+ctrl+shift", NavigationModes.Rotate],
        ["0+meta+shift", NavigationModes.Rotate],
        ["1", NavigationModes.Pan],
        ["1+shift", NavigationModes.Rotate],
    ]);

    /** @see {@link camera} */
    private readonly _camera: Camera;

    /**
     * Identifies the active mode.
     */
    private _mode: NavigationModes | undefined;

    /**
     * Identifies the mouse button that initiated the current action (mode)
     */
    private _provokingButton: number | undefined;

    private _panReferencePosition: vec3 | undefined;
    private _rotateReferencePosition: vec2 | undefined;

    /**
     * Even handler used to forward/map events to specific camera modifiers.
     */
    private readonly _eventHandler: EventHandler;

    public constructor(
        callback: Invalidate,
        mouseEventProvider: MouseEventProvider,
        touchEventProvider: TouchEventProvider,
        camera: Camera
    ) {
        this._camera = camera;

        /* Create event handler that listens to mouse events. */
        this._eventHandler = new EventHandler(callback, mouseEventProvider, touchEventProvider);

        /* Listen to mouse events. */
        this._eventHandler.pushMouseDownHandler((latests: MouseEvent[]): void => {
            this.onMouseDown(latests);
        });
        this._eventHandler.pushMouseUpHandler((latests: MouseEvent[]): void => {
            this.onMouseUp(latests);
        });
        this._eventHandler.pushMouseMoveHandler((latests: MouseEvent[]): void => {
            this.onMouseMove(latests);
        });
        this._eventHandler.pushMouseWheelHandler((latests: MouseEvent[]): void => {
            this.onWheel(latests);
        });
    }

    /**
     * Update should invoke navigation specific event processing. When using, e.g., an event handler, the event handlers
     * update method should be called in order to have navigation specific event processing invoked.
     */
    public update(): void {
        this._eventHandler.update();
    }

    private selectMode(event: MouseEvent): NavigationModes | undefined {
        const eventDesc =
            `${event.button}` +
            `${event.altKey ? "+alt" : ""}` +
            `${event.ctrlKey ? "+ctrl" : ""}` +
            `${event.metaKey ? "+meta" : ""}` +
            `${event.shiftKey ? "+shift" : ""}`;

        return this.binding.get(eventDesc);
    }

    private getViewportPosition(event: MouseEvent): vec2 {
        const camera = assertExists(this._camera, "Expected valid camera");

        const point = this._eventHandler.offsets(event, true)[0];

        return vec2.fromValues(point[0], camera.viewport[1] - point[1] - 1);
    }

    private onMouseDown(latests: MouseEvent[]): void {
        const event = latests[latests.length - 1];

        if (this._mode === undefined) {
            this._mode = this.selectMode(event);

            if (this._mode !== undefined) {
                this._provokingButton = event.button;
                this.start(this._mode, event);
                event.preventDefault();
            }
        }
    }

    private onMouseUp(latests: MouseEvent[]): void {
        const event = latests[latests.length - 1];

        if (event.button === this._provokingButton) {
            assert(this._mode !== undefined, "Expected active mode");

            this._mode = undefined;
            this._provokingButton = undefined;
            event.preventDefault();
        }
    }

    private onMouseMove(latests: MouseEvent[]): void {
        const event = latests[latests.length - 1];

        if (this._mode === undefined) {
            return;
        }

        this.process(this._mode, event);

        event.preventDefault();
    }

    private onWheel(latests: MouseEvent[]): void {
        const camera = assertExists(this._camera, "Expected valid camera");
        assert(camera.center[1] === 0, "Center must be constrained to ground plane");

        const event = latests[latests.length - 1];
        if (!(event instanceof WheelEvent)) {
            throw new Error("Expected instance of WheelEvent");
        }

        const { spinY } = normalizeWheel(event);

        let scale = -0.04 * gl_matrix_extensions.clamp(spinY, -1, 1);
        if (scale > 0) {
            scale = 1 / (1 - scale) - 1;
        }

        const newEye = vec3.subtract(vec3.create(), camera.center, camera.eye);
        vec3.scale(newEye, newEye, scale);
        vec3.add(newEye, newEye, camera.eye);

        camera.eye = newEye;
    }

    private start(mode: NavigationModes, event: MouseEvent): void {
        switch (mode) {
            case NavigationModes.Pan:
                this.startPan(event);
                break;
            case NavigationModes.Rotate:
                this.startRotate(event);
                break;
            default:
                assert(false, "Undefined mode");
        }
    }

    private process(mode: NavigationModes, event: MouseEvent): void {
        switch (mode) {
            case NavigationModes.Pan:
                this.processPan(event);
                break;
            case NavigationModes.Rotate:
                this.processRotate(event);
                break;
            default:
                assert(false, "Undefined mode");
        }
    }

    private startPan(event: MouseEvent): void {
        const camera = assertExists(this._camera, "Expected valid camera");
        const viewProjectionInverse = assertExists(
            camera.viewProjectionInverse,
            "Expected valid viewProjectionInverse"
        );

        const mousePosition = this.getViewportPosition(event);
        this._panReferencePosition = intersectMouseRayPlane(
            mousePosition,
            [0, 0, 0],
            [0, 1, 0],
            viewProjectionInverse,
            camera.viewport
        );
    }

    private processPan(event: MouseEvent): void {
        const camera = assertExists(this._camera, "Expected valid camera");
        const viewProjectionInverse = assertExists(
            camera.viewProjectionInverse,
            "Expected valid viewProjectionInverse"
        );
        const panReferencePosition = assertExists(
            this._panReferencePosition,
            "Expected valid panReferencePosition"
        );

        /*
         * The first click of the interaction yields an object space position m_referencePosition.
         * this point is our constraint for panning, that means for every mouse
         * position there has to be an appropriate positioning for the scene, so
         * that the point under the mouse remains m_referencePosition.
         * With this point and the up normal we build a plane, that defines the
         * panning space. For panning, a ray is created, pointing from the screen
         * pixel into the view frustum. This ray then is converted to object space
         * and used to intersect with the plane at p.
         * The delta of m_referencePosition and p is the translation required for panning.
         */

        const mousePosition = this.getViewportPosition(event);

        const clampedPosition = gl_matrix_extensions.clamp2(
            vec2.create(),
            mousePosition,
            [0, 0],
            camera.viewport
        );
        const newPosition: vec3 | undefined = intersectMouseRayPlane(
            clampedPosition,
            panReferencePosition,
            [0, 1, 0],
            viewProjectionInverse,
            camera.viewport
        );

        if (newPosition !== undefined) {
            const delta = vec3.subtract(vec3.create(), panReferencePosition, newPosition);
            camera.eye = vec3.add(vec3.create(), camera.eye, delta);
            camera.center = vec3.add(vec3.create(), camera.center, delta);
        }
    }

    private startRotate(event: MouseEvent): void {
        this._rotateReferencePosition = this.getViewportPosition(event);
    }

    private processRotate(event: MouseEvent): void {
        const camera = assertExists(this._camera, "Expected valid camera");
        const rotateReferencePosition = assertExists(
            this._rotateReferencePosition,
            "Expected valid rotateReferencePosition"
        );

        // Compute rotation angle from mouse movement
        const mousePosition = this.getViewportPosition(event);
        const delta = vec2.subtract(vec2.create(), mousePosition, rotateReferencePosition);
        this._rotateReferencePosition = mousePosition;

        vec2.scale(delta, delta, 1 / camera.viewport[1]);
        const angle = vec2.multiply(vec2.create(), delta, Navigation.ROTATION_DOF);

        // Compute rotation axis from movement direction
        const ray = vec3.subtract(vec3.create(), camera.eye, camera.center);
        const distance = vec3.length(ray);
        const direction = vec3.scale(vec3.create(), ray, 1 / distance);
        const rotAxis = vec3.cross(vec3.create(), camera.up, direction);

        /*
         * Constrain vangle to [0°,90°] w.r.t. world up (0, 1, 0)
         * Explanation: dot(direction, worldup / (normalize(direction) * normalize(worldup) degrades to direction.y
         */
        const maxVangle = Math.acos(direction[1]);
        const minVangle = Math.PI * -0.5 + maxVangle;
        angle[1] = gl_matrix_extensions.clamp(angle[1], minVangle, maxVangle);

        // Create rotation matrix
        const rotation = mat4.create();
        mat4.rotate(rotation, rotation, angle[0], [0, 1, 0]);
        mat4.rotate(rotation, rotation, -angle[1], rotAxis);

        // Compute rotated view direction
        const newDirection4 = vec4.transformMat4(
            vec4.create(),
            gl_matrix_extensions.fromVec3(direction),
            rotation
        );
        const newDirection = vec3.fromValues(newDirection4[0], newDirection4[1], newDirection4[2]);
        vec3.normalize(newDirection, newDirection);
        const newRay = vec3.scale(vec3.create(), newDirection, distance);

        // Compute rotated up
        const newUp4 = vec4.transformMat4(vec4.create(), gl_matrix_extensions.fromVec3(camera.up), rotation);
        const newUp = vec3.fromValues(newUp4[0], newUp4[1], newUp4[2]);
        vec3.normalize(newUp, newUp);

        // Update camera
        camera.eye = vec3.add(vec3.create(), camera.center, newRay);
        camera.up = newUp;
    }
}
