import { Button, createStyles, makeStyles, Paper, Popover, Theme, Typography } from "@material-ui/core";
import { PopoverPosition } from "@material-ui/core/Popover";
import { StyleRules } from "@material-ui/core/styles";
import React, { FunctionComponent, PropsWithChildren } from "react";

import { MagnetController } from "../magnetController";
import { MagnetControls } from "./magnetControls";
import { useObservable, useObservableProperty } from "./useObservable";

const useStyles = makeStyles(
    (theme: Theme): StyleRules =>
        createStyles({
            content: { padding: theme.spacing(2) },
            removeContainer: { textAlign: "right" },
        })
);

export interface DmContextMenuProps {
    magnet: MagnetController;
    anchorPosition: PopoverPosition;
    onClose?: () => void;
}

export const DmContextMenu: FunctionComponent<DmContextMenuProps> = (
    props: PropsWithChildren<DmContextMenuProps>
): JSX.Element | null => {
    const classes = useStyles();
    const name = useObservableProperty(props.magnet.name);
    useObservable(props.magnet.onClose$, undefined, props.onClose);

    return (
        <Popover open anchorReference="anchorPosition" anchorPosition={props.anchorPosition} onClose={props.onClose}>
            <Paper className={classes.content}>
                <Typography variant="h6">Magnet &quot;{name}&quot;</Typography>
                <MagnetControls magnet={props.magnet} />
                <div className={classes.removeContainer}>
                    <Button color="secondary" onClick={props.magnet.remove.bind(props.magnet)}>
                        Remove
                    </Button>
                </div>
            </Paper>
        </Popover>
    );
};
