import { Observable, Subject } from "rxjs";
import { Context, Framebuffer, NdcFillingTriangle, Program, Shader, Texture2D, Wizard } from "webgl-operate";

import { latestWhen, tapRedraw } from "../../operators";
import { ParticleController } from "../../particleController";
import fragmentShaderSource from "./positionIntegration.frag";
import vertexShaderSource from "./positionIntegration.vert";

export class PositionIntegrationPass {
    public name: string = "PositionIntegration";

    private readonly _context: Context;

    private readonly _ndcTriangle: NdcFillingTriangle;
    private readonly _targetPositionTexture: Texture2D;

    private readonly _program: Program;

    private readonly _integratedPositionTexture: [Texture2D, Texture2D];
    private readonly _framebuffer: [Framebuffer, Framebuffer];
    private _currentFramebufferIndex = 0;
    private _remainingAnimationFrames = 0;
    private _redrawRequested = false;
    private _paddedBuffer = new Uint16Array();

    private readonly _particleController$: ParticleController;
    private readonly _prepareTrigger$: Subject<void> = new Subject();
    private readonly _redraw$: Subject<boolean> = new Subject();
    private readonly _integratedPositionTexture$: Subject<Texture2D> = new Subject();

    public constructor(context: Context, particleController$: ParticleController) {
        this._context = context;
        this._particleController$ = particleController$;

        const gl = this._context.gl as WebGLRenderingContext;

        this._integratedPositionTexture = [
            this.createTexture("integratedPositionTexture0"),
            this.createTexture("integratedPositionTexture1"),
        ];
        this._framebuffer = [this.createFramebuffer(0), this.createFramebuffer(1)];

        this._program = this.loadShader();
        this._program.bind();
        gl.uniform1i(this._program.uniform("targetPositionTexture"), 0);
        gl.uniform1i(this._program.uniform("currentPositionTexture"), 1);

        this._ndcTriangle = new NdcFillingTriangle(context, `${this.name}.geometry`);
        this._ndcTriangle.initialize(this._program.attribute("in_position"));

        this._targetPositionTexture = this.createTexture("targetPositionTexture");

        this._redraw$.subscribe((): void => {
            this._redrawRequested = true;
        });
        this.subscribeToPositions();
    }

    public release(): void {
        this._ndcTriangle.uninitialize();
        this._targetPositionTexture.uninitialize();
        this._program.uninitialize();
        this._framebuffer.forEach((framebuffer: Framebuffer): void => framebuffer.uninitialize());
        this._integratedPositionTexture.forEach((texture: Texture2D): void => texture.uninitialize());
    }

    public get redrawRequested(): boolean {
        return this._redrawRequested;
    }

    public get redraw$(): Observable<boolean> {
        return this._redraw$.asObservable();
    }

    public get integratedPositionTexture$(): Observable<Texture2D> {
        return this._integratedPositionTexture$.asObservable();
    }

    public process(): void {
        const gl = this._context.gl as WebGLRenderingContext;

        this._prepareTrigger$.next();

        gl.viewport(0.0, 0.0, ...this._integratedPositionTexture[this._currentFramebufferIndex].size);
        this._framebuffer[this._currentFramebufferIndex].bind();
        this._program.bind();
        this._targetPositionTexture.bind(gl.TEXTURE0);
        this._integratedPositionTexture[1 - this._currentFramebufferIndex].bind(gl.TEXTURE1);
        this._ndcTriangle.bind();
        this._ndcTriangle.draw();

        this._integratedPositionTexture$.next(this._integratedPositionTexture[this._currentFramebufferIndex]);

        this._currentFramebufferIndex = 1 - this._currentFramebufferIndex;
        this._redrawRequested = false;
        if (this._remainingAnimationFrames > 0) {
            this._redraw$.next(true);
            this._remainingAnimationFrames -= 1;
        }
    }

    private createTexture(name: string): Texture2D {
        const gl = this._context.gl as WebGLRenderingContext;

        const [internalFormat, type] = Wizard.queryInternalTextureFormat(this._context, gl.RGBA, Wizard.Precision.byte);
        const texture = new Texture2D(this._context, `${this.name}.${name}`);
        texture.initialize(1, 1, internalFormat, gl.RGBA, type);
        texture.wrap(gl.CLAMP_TO_EDGE, gl.CLAMP_TO_EDGE);
        texture.filter(gl.NEAREST, gl.NEAREST);

        return texture;
    }

    private createFramebuffer(index: GLuint): Framebuffer {
        const gl = this._context.gl as WebGLRenderingContext;

        const framebuffer = new Framebuffer(this._context, `${this.name}.framebuffer${index}`);
        framebuffer.initialize([[gl.COLOR_ATTACHMENT0, this._integratedPositionTexture[index]]]);

        return framebuffer;
    }

    private loadShader(): Program {
        const gl = this._context.gl as WebGLRenderingContext;

        const vertexShader = new Shader(this._context, gl.VERTEX_SHADER, `${this.name}.vertexShader`);
        vertexShader.initialize(vertexShaderSource);
        const fragmentShader = new Shader(this._context, gl.FRAGMENT_SHADER, `${this.name}.fragmentShader`);
        fragmentShader.initialize(fragmentShaderSource);
        const program = new Program(this._context);
        program.initialize([vertexShader, fragmentShader]);

        return program;
    }

    private resize(squareSize: GLsizei): void {
        this._integratedPositionTexture.forEach((texture: Texture2D): void => texture.resize(squareSize, squareSize));
        this._targetPositionTexture.resize(squareSize, squareSize);
        this._paddedBuffer = new Uint16Array(squareSize * squareSize * 2);
    }

    private withPadding(data: Uint16Array, callback: (data: Uint16Array) => void): void {
        if (data.length === this._paddedBuffer.length) {
            callback(data);
            return;
        }

        this._paddedBuffer.set(data);
        callback(this._paddedBuffer);
    }

    private subscribeToPositions(): void {
        this._particleController$
            .pipe(
                tapRedraw(this._redraw$),
                latestWhen(this._prepareTrigger$)
            )
            .subscribe((data: Uint16Array): void => {
                const squareSize = Math.max(1, Math.ceil(Math.sqrt(data.length / 2)));
                const resizeRequired = this._targetPositionTexture.width !== squareSize;
                if (resizeRequired) {
                    this.resize(squareSize);
                }
                this.withPadding(data, (paddedData: Uint16Array): void => {
                    const uint8Data = new Uint8Array(paddedData.buffer);
                    this._targetPositionTexture.data(uint8Data);
                    if (resizeRequired) {
                        this._integratedPositionTexture.forEach((texture: Texture2D): void => texture.data(uint8Data));
                    }
                });

                this._remainingAnimationFrames = 60;
            });
    }
}
