import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    makeStyles,
    Paper,
    SvgIcon,
    Tab,
    Tabs,
    TextField,
    Theme,
    Typography,
} from "@material-ui/core";
import { createStyles,StyleRules } from "@material-ui/core/styles";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import ErrorIcon from "@material-ui/icons/Error";
import LinkIcon from "@material-ui/icons/Link";
import React, { ChangeEvent, FunctionComponent, PropsWithChildren, useContext, useState } from "react";
import SwipeableViews from "react-swipeable-views";

import { DmSessionContext } from "./dmContext";

const FileUploadIcon: FunctionComponent<SvgIconProps> = (props: PropsWithChildren<SvgIconProps>): JSX.Element => {
    return (
        <SvgIcon {...props}>
            <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
        </SvgIcon>
    );
};

const useStyles = makeStyles(
    (theme: Theme): StyleRules =>
        createStyles({
            fileLabel: {
                verticalAlign: "middle",
                paddingLeft: theme.spacing(2),
            },
            errorIcon: {
                verticalAlign: "middle",
                marginTop: "-4px",
                marginRight: theme.spacing(1),
            },
            progressPlaceholder: {
                height: 4,
                position: "relative",
                overflow: "hidden",
            },
        })
);

interface ImportLocalFileFragmentProps {
    disabled: boolean;
    onChange: (file: File) => void;
}

const ImportLocalFileFragment: FunctionComponent<ImportLocalFileFragmentProps> = (
    props: PropsWithChildren<ImportLocalFileFragmentProps>
): JSX.Element => {
    const classes = useStyles();
    const [file, setFile] = useState<File | undefined>(undefined);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const files = event.target.files;
        if (files !== null && files.length > 0) {
            setFile(files[0]);
            props.onChange(files[0]);
        }
    };

    return (
        <DialogContent>
            <DialogContentText>Import a dataset from a local file.</DialogContentText>
            <label>
                <input type="file" hidden onChange={handleFileChange} accept=".csv" disabled={props.disabled} />
                <Button variant="contained" component="span" disabled={props.disabled}>
                    Choose File
                </Button>
                <Typography display="inline" className={classes.fileLabel} component="span">
                    {file === undefined ? null : file.name}
                </Typography>
            </label>
        </DialogContent>
    );
};

interface ImportUrlFragmentProps {
    disabled: boolean;
    onChange: (url: string) => void;
}
const ImportUrlFragment: FunctionComponent<ImportUrlFragmentProps> = (
    props: PropsWithChildren<ImportUrlFragmentProps>
): JSX.Element => {
    const [error, setError] = useState(false);
    const handleUrlChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target.checkValidity()) {
            props.onChange(event.target.value);
        }
        setError(!event.target.checkValidity());
    };
    return (
        <DialogContent>
            <DialogContentText>Import a dataset from a URL.</DialogContentText>
            <TextField
                label="URL"
                type="url"
                fullWidth
                variant="filled"
                onChange={handleUrlChange}
                error={error}
                disabled={props.disabled}
            />
        </DialogContent>
    );
};

export interface ImportDatasetDialogProps {
    open: boolean;
    onClose: () => void;
}

// eslint-disable-next-line max-lines-per-function
export const ImportDatasetDialog: FunctionComponent<ImportDatasetDialogProps> = (
    props: PropsWithChildren<ImportDatasetDialogProps>
): JSX.Element => {
    const session = useContext(DmSessionContext);
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);
    const [url, setUrl] = useState("");
    const [file, setFile] = useState<File | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [inProgress, setInProgress] = useState(false);

    const handleSelectTab = (_event: ChangeEvent<{}>, tab: number): void => {
        setSelectedTab(tab);
    };

    const handleClose = (): void => {
        setError(undefined);
        props.onClose();
    };

    const handleImport = (): void => {
        setInProgress(true);

        let fileOrUrl: File | string = url;
        if (selectedTab === 0) {
            if (file === undefined) {
                throw new TypeError("file is undefined");
            }
            fileOrUrl = file;
        }

        setError(undefined);

        session.importDataset(fileOrUrl).then(
            (): void => {
                setInProgress(false);
                handleClose();
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (reason: any): void => {
                setInProgress(false);
                if (reason instanceof Error) {
                    setError(reason.message === "" ? "Unknown error" : reason.message);
                } else {
                    setError(String(reason));
                }
            }
        );
    };

    const importDisabled = (selectedTab === 0 && file === undefined) || (selectedTab === 1 && url === "");

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            disableBackdropClick={inProgress}
            disableEscapeKeyDown={inProgress}
        >
            <Paper square>
                <DialogTitle>Import Dataset</DialogTitle>
                <Tabs
                    variant="fullWidth"
                    value={selectedTab}
                    onChange={handleSelectTab}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab icon={<FileUploadIcon />} disabled={inProgress} />
                    <Tab icon={<LinkIcon />} disabled={inProgress} />
                </Tabs>
            </Paper>
            <SwipeableViews index={selectedTab} onChangeIndex={setSelectedTab}>
                <ImportLocalFileFragment onChange={setFile} disabled={inProgress} />
                <ImportUrlFragment onChange={setUrl} disabled={inProgress} />
            </SwipeableViews>
            {error === undefined ? null : (
                <DialogContent>
                    <ErrorIcon color="error" className={classes.errorIcon} />
                    <Typography color="error" variant="body2" component="span">
                        {error}
                    </Typography>
                </DialogContent>
            )}
            <DialogActions>
                <Button color="primary" onClick={handleClose} disabled={inProgress}>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleImport} disabled={importDisabled || inProgress}>
                    Import
                </Button>
            </DialogActions>
            {inProgress ? <LinearProgress color="primary" /> : <div className={classes.progressPlaceholder} />}
        </Dialog>
    );
};
