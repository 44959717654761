import { createContext } from "react";
import { Observable } from "rxjs";

import { DmSession } from "../dmSession";
import { ObservableProperty } from "../observableProperty";
import { DmRenderer } from "../renderer/dmRenderer";

// eslint-disable-next-line @typescript-eslint/no-object-literal-type-assertion
export const DmSessionContext = createContext<DmSession>({} as DmSession);

// eslint-disable-next-line @typescript-eslint/no-object-literal-type-assertion
export const DmRendererContext = createContext<DmRenderer>({} as DmRenderer);

export interface Labeling {
    particleNames$: Observable<string[]>;
    nameAttribute: ObservableProperty<string | undefined>;
    nameAttributeChoices$: Observable<string[]>;
}

// eslint-disable-next-line @typescript-eslint/no-object-literal-type-assertion
export const LabelingContext = createContext<Labeling>({} as Labeling);
