import { combineLatest, from, Observable } from "rxjs";
import { delay, map, retryWhen, share, switchMap, tap } from "rxjs/operators";

import { AttributeDesc, DatasetDesc } from "./api";
import { ControlServerAdapter } from "./controlServerAdapter";
import { BasicObservableProperty } from "./observableProperty";

type Category = "nominal" | "ordinal" | "numerical";

export class AttributeMap {
    public readonly attribute = new BasicObservableProperty<string | undefined>(undefined);
    public readonly invert = new BasicObservableProperty<boolean>(false);
    public readonly range = new BasicObservableProperty<[number, number]>([0, 1]);
    public readonly enabled = new BasicObservableProperty<boolean>(false);
    public readonly choices$: Observable<string[]>;
    public readonly values$: Observable<Float32Array | undefined>;

    private readonly _attributeDesc$: Observable<AttributeDesc | undefined>;
    private readonly _data$: Observable<number[] | undefined>;
    private readonly _categories: Category[];
    private readonly _remote: ControlServerAdapter;

    // eslint-disable-next-line max-lines-per-function
    public constructor(
        dataset$: Observable<DatasetDesc | undefined>,
        categories: Category[],
        remote: ControlServerAdapter
    ) {
        this._categories = categories;
        this._remote = remote;

        this._attributeDesc$ = combineLatest(this.attribute.$, dataset$).pipe(
            map(([name, dataset]: [string | undefined, DatasetDesc | undefined]): AttributeDesc | undefined => {
                if (name === undefined || dataset === undefined) {
                    return undefined;
                }
                return dataset.attributes.find((candidate: AttributeDesc): boolean => candidate.name === name);
            }),
            share()
        );
        this._attributeDesc$.subscribe((desc: AttributeDesc | undefined): void => {
            this.range.value = desc === undefined ? [0, 1] : [desc.min, desc.max];
        });

        this._data$ = this.observeData();
        this.choices$ = this.observeChoices(dataset$);
        this.values$ = this.observeValues();
    }

    // eslint-disable-next-line class-methods-use-this
    protected map(data: number[], invert: boolean, range: [number, number]): Float32Array {
        const scale = range[1] - range[0];
        return new Float32Array(
            data.map((value: number): number => {
                let mappedValue = (value - range[0]) / scale;
                mappedValue = Math.min(Math.max(mappedValue, 0), 1);
                if (invert) {
                    mappedValue = 1 - mappedValue;
                }
                return mappedValue;
            })
        );
    }

    private observeData(): Observable<number[] | undefined> {
        return this._attributeDesc$.pipe(
            switchMap(
                (attribute: AttributeDesc | undefined): Observable<number[] | undefined> => {
                    return from(
                        (async (): Promise<number[] | undefined> => {
                            if (attribute === undefined) {
                                return undefined;
                            }

                            return this._remote.getAttributeData(attribute.name);
                        })()
                    );
                }
            ),
            retryWhen(
                (errors: Observable<unknown>): Observable<unknown> => {
                    return errors.pipe(
                        tap((error: unknown): void => console.error(`Failed to get attribute data: ${error}`)),
                        delay(500)
                    );
                }
            ),
            share()
        );
    }

    private observeChoices(dataset$: Observable<DatasetDesc | undefined>): Observable<string[]> {
        return dataset$.pipe(
            map((current: DatasetDesc | undefined): string[] => {
                if (current === undefined) {
                    return [];
                }

                return current.attributes
                    .filter((attribute: AttributeDesc): boolean => this._categories.includes(attribute.category))
                    .map((attribute: AttributeDesc): string => attribute.name)
                    .sort((lhs: string, rhs: string): number =>
                        lhs.localeCompare(rhs, undefined, { sensitivity: "base", numeric: true })
                    );
            }),
            share()
        );
    }

    private observeValues(): Observable<Float32Array | undefined> {
        return combineLatest(
            this.invert.$,
            this.range.$,
            this.enabled.$,
            this._data$,
            (
                invert: boolean,
                range: [number, number],
                enabled: boolean,
                rawData: number[] | undefined
            ): Float32Array | undefined => {
                if (!enabled) {
                    return undefined;
                }
                if (rawData === undefined) {
                    return undefined;
                }

                return this.map(rawData, invert, range);
            }
        ).pipe(share());
    }
}
