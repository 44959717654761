
/* spellchecker: disable */

import { CameraModifier } from './cameramodifier';

/* spellchecker: enable */


export class ZoomModifier extends CameraModifier {

    update(): void {

    }

}
