import { MonoTypeOperatorFunction } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

/**
 * Evaluates the provided statement and throws an evaluation error if false.
 * ```
 * assert(foo <= threshold, `value of foo ${foo} exceeds threshold of ${threshold}`);
 * ```
 * @param statement - Result of an statement expected to be true.
 * @param message - Message to be passed to the error (if thrown).
 */
export function assert(statement: boolean, message: string): void {
    if (statement) {
        return;
    }

    /* The parameters are intentionally not forwarded to console.assert since it does not interrupt execution. */
    throw new Error(message);
}

export function assertExists<T>(value: T | undefined | null, message?: string): T {
    if (value === undefined || value === null) {
        throw new Error(message);
    }

    return value;
}

export function coerceToArray<T>(value: T | T[] | undefined): T[] {
    if (value === undefined) {
        return [];
    }

    return new Array<T>().concat(value);
}

export function distinctUntilAnyKeyChanged<T, TKey extends keyof T>(keys: TKey[]): MonoTypeOperatorFunction<T> {
    return distinctUntilChanged(
        (lhs: T, rhs: T): boolean =>
            keys.reduce<boolean>((result: boolean, key: TKey): boolean => result && lhs[key] === rhs[key], true)
    );
}

export function nullToUndefined<T>(value: T | null): T | undefined {
    return value === null ? undefined : value;
}

export function undefinedToNull<T>(value: T | undefined): T | null {
    return value === undefined ? null : value;
}
