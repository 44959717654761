import { Observable, Subject } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

export class ParticleController extends Subject<Uint16Array> {
    public get numParticles(): Observable<number> {
        return this.pipe(
            map((data: Uint16Array): number => data.length / 2),
            distinctUntilChanged()
        );
    }

    public reset(numParticles: number): void {
        this.next(new Uint16Array(numParticles * 2).fill(32767));
    }
}
