import { pluck } from "rxjs/operators";
import { Program, VertexArray } from "webgl-operate";

import { RenderPass, RenderPassParameters } from "../renderPass";
import fragmentShaderSource from "./magnetAnchorReflectionPass.frag";
import vertexShaderSource from "./magnetAnchorReflectionPass.vert";
import { MagnetSharedState } from "./magnetPassGroup";

export class MagnetAnchorReflectionPass extends RenderPass {
    private readonly _shared: MagnetSharedState;
    private readonly _vertexArray: VertexArray;
    private readonly _program: Program;

    public constructor(shared: MagnetSharedState, baseParameters: RenderPassParameters) {
        super(baseParameters, "MagnetAnchorReflection");
        this._shared = shared;

        const gl = this._context.gl as WebGLRenderingContext;

        this._program = this.loadShader(vertexShaderSource, fragmentShaderSource, ["in_vertex", "in_position"]);
        this._vertexArray = this.createVertexArray([
            { buffer: shared.anchorVertexBuffer, size: 2, type: gl.FLOAT },
            { buffer: shared.instanceBuffer, size: 2, type: gl.FLOAT, divisor: 1 },
        ]);

        this.createSubscriptions();
    }

    protected onRelease(): void {
        this._vertexArray.uninitialize();
        this._program.uninitialize();
    }

    protected onFrame(): void {
        if (this._shared.instanceCount === 0) {
            return;
        }

        const gl = this._context.gl as WebGLRenderingContext;
        const gl2 = this._context.gl2facade;

        gl.enable(gl.DEPTH_TEST);
        gl.enable(gl.BLEND);

        this._program.bind();

        // Bind vertex array & draw
        this._vertexArray.bind();
        gl2.drawArraysInstanced(gl.TRIANGLE_STRIP, 0, this._shared.anchorVertexCount, this._shared.instanceCount);

        gl.disable(gl.DEPTH_TEST);
        gl.disable(gl.BLEND);
    }

    private createSubscriptions(): void {
        this.subscribeUniform1f(this.config$("magnets.offset"), this._program, "height");
        this.subscribeUniform1f(this.config$("magnets.anchorWidth"), this._program, "width");
        this.subscribeUniform4f(this.config$("magnets.color"), this._program, "color");

        this.subscribeUniformMat4(this._camera.viewProjection$, this._program, "viewProjection");
        this.subscribeUniform1f(this._camera.inverseViewport$.pipe(pluck(0)), this._program, "pixelWidth");
    }
}
