import { useEffect, useState } from "react";
import { Observable } from "rxjs";

import { ObservableProperty } from "../observableProperty";

export function useObservable<T>(observable: Observable<T>, initialValue: T, onComplete?: () => void): T;
export function useObservable<T>(observable: Observable<T>, initialValue?: T, onComplete?: () => void): T | undefined;
export function useObservable<T>(observable: Observable<T>, initialValue?: T, onComplete?: () => void): T | undefined {
    const [value, setValue] = useState<T | undefined>(initialValue);

    useEffect((): (() => void) => {
        const subscription = observable.subscribe(
            (newValue: T): void => {
                setValue(newValue);
            },
            undefined,
            onComplete
        );
        return (): void => subscription.unsubscribe();
    }, []);

    return value;
}

export function useObservableProperty<T>(property: ObservableProperty<T>): [T, (value: T) => void] {
    return [
        useObservable<T>(property.$, property.value),
        (value: T): void => {
            property.value = value;
        },
    ];
}
