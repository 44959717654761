import { Observable } from "rxjs";
import { ignoreElements } from "rxjs/operators";
import { GLfloat2 } from "webgl-operate/lib/tuples";

import { MagnetDesc } from "./api";
import { ControlServerAdapter } from "./controlServerAdapter";
import { createRemoteObservableProperty, ObservableProperty } from "./observableProperty";

export class MagnetController {
    public readonly id: number;

    public name: ObservableProperty<string>;
    public attribute: ObservableProperty<string>;
    public position: ObservableProperty<GLfloat2>;
    public magnitude: ObservableProperty<number>;
    public mappingReverse: ObservableProperty<boolean>;
    public onClose$: Observable<void>;

    private readonly _remote: ControlServerAdapter;

    public constructor(id: number, desc$: Observable<MagnetDesc>, remote: ControlServerAdapter) {
        this.id = id;
        this._remote = remote;

        // eslint-disable-next-line @typescript-eslint/promise-function-async
        const setMagnet = (desc: Partial<MagnetDesc>): Promise<void> => remote.setMagnet({ id, ...desc });
        this.name = createRemoteObservableProperty(desc$, "name", "", setMagnet);
        this.attribute = createRemoteObservableProperty(desc$, "attribute", "", setMagnet);
        this.position = createRemoteObservableProperty(desc$, "position", [0, 0], setMagnet);
        this.magnitude = createRemoteObservableProperty(desc$, "magnitude", 1, setMagnet);
        this.mappingReverse = createRemoteObservableProperty(desc$, "mappingReverse", false, setMagnet);
        this.onClose$ = desc$.pipe(ignoreElements());
    }

    // eslint-disable-next-line @typescript-eslint/promise-function-async
    public remove(): Promise<void> {
        return this._remote.removeMagnet(this.id);
    }
}
