import { Subject } from "rxjs";

import { SimulationSettings } from "./api";
import { ControlServerAdapter } from "./controlServerAdapter";
import { createRemoteObservableProperty, ObservableProperty } from "./observableProperty";

export class SimulationControlAdapter {
    public readonly radius: ObservableProperty<number>;
    public readonly amplification: ObservableProperty<number>;

    private readonly _settings$ = new Subject<SimulationSettings>();

    public constructor(remote: ControlServerAdapter) {
        this.radius = createRemoteObservableProperty(
            this._settings$,
            "radius",
            0.01,
            remote.setSimulationSettings.bind(remote)
        );
        this.amplification = createRemoteObservableProperty(
            this._settings$,
            "amplification",
            1.0,
            remote.setSimulationSettings.bind(remote)
        );
    }

    public update(settings: SimulationSettings): void {
        this._settings$.next(settings);
    }
}
