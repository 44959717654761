import { Observable, Subject, Subscription } from "rxjs";
import { Context, Framebuffer, Texture2D } from "webgl-operate";
import { GLsizei2 } from "webgl-operate/lib/tuples";

import { assert, assertExists } from "../helpers";
import { Config, RendererConfig } from "./dmRenderer";
import { createDepthBuffer, createRgbaBuffer } from "./glHelpers";

export class ReflectionProvider {
    private colorBuffer?: Texture2D;
    private depthBuffer?: Texture2D;
    private famebuffer?: Framebuffer;
    private readonly _context: Context;

    private readonly _config$: <K extends keyof Config>(property: K) => Observable<Config[K]>;
    private readonly _rendererConfig$: <K extends keyof RendererConfig>(property: K) => Observable<RendererConfig[K]>;
    private readonly _subscriptions: Subscription[] = [];
    private readonly _reflectionMap$ = new Subject<Texture2D | undefined>();

    public constructor(
        context: Context,
        config$: <K extends keyof Config>(property: K) => Observable<Config[K]>,
        rendererConfig$: <K extends keyof RendererConfig>(property: K) => Observable<RendererConfig[K]>
    ) {
        this._context = context;
        this._config$ = config$;
        this._rendererConfig$ = rendererConfig$;

        this._config$("reflection.enabled").subscribe(this.setEnabled.bind(this));
    }

    public release(): void {
        this.setEnabled(false);
    }

    public get reflectionMap$(): Observable<Texture2D | undefined> {
        return this._reflectionMap$.asObservable();
    }

    public frame(draw: () => void): void {
        if (this.famebuffer === undefined) {
            return;
        }

        const gl = this._context.gl as WebGLRenderingContext;

        this.famebuffer.bind();
        gl.clearColor(0, 0, 0, 0);
        // eslint-disable-next-line no-bitwise
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

        draw();
    }

    private setEnabled(enabled: boolean): void {
        if (enabled) {
            this.enable();
        } else {
            this.disable();
        }
    }

    private enable(): void {
        if (this.famebuffer !== undefined) {
            return;
        }
        assert(this.colorBuffer === undefined, "Expected colorBuffer not to exist when framebuffer does not exist");
        assert(this.depthBuffer === undefined, "Expected depthBuffer not to exist when framebuffer does not exist");

        const gl = this._context.gl as WebGLRenderingContext;

        this.colorBuffer = createRgbaBuffer(this._context, "reflectionColor");
        this.depthBuffer = createDepthBuffer(this._context, "reflectionDepth");
        const reflectionFramebuffer = new Framebuffer(this._context, "reflectionFramebuffer");
        reflectionFramebuffer.initialize([
            [gl.COLOR_ATTACHMENT0, this.colorBuffer],
            [gl.DEPTH_ATTACHMENT, this.depthBuffer],
        ]);
        reflectionFramebuffer.clearColor([0, 0, 0, 0]);
        this.famebuffer = reflectionFramebuffer;

        this._subscriptions.push(
            this._rendererConfig$("frameSize").subscribe((frameSize: GLsizei2): void => {
                reflectionFramebuffer.resize(...frameSize);
            })
        );

        this._reflectionMap$.next(this.colorBuffer);
    }

    private disable(): void {
        if (this.famebuffer === undefined) {
            return;
        }

        assertExists(this.colorBuffer, "Expected colorBuffer to exist when framebuffer exists").uninitialize();
        this.colorBuffer = undefined;
        assertExists(this.depthBuffer, "Expected depthBuffer to exist when framebuffer exists").uninitialize();
        this.depthBuffer = undefined;
        this.famebuffer.uninitialize();
        this.famebuffer = undefined;

        while (this._subscriptions.length > 0) {
            assertExists(this._subscriptions.pop()).unsubscribe();
        }

        this._reflectionMap$.next(undefined);
    }
}
