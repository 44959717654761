import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { Buffer, Texture2D, Wizard } from "webgl-operate";

import { SwitchSubject, withDefaultData } from "../../operators";
import { ParticleController } from "../../particleController";
import { RenderPass, RenderPassParameters } from "../renderPass";
import { ParticleReflectionPass } from "./particleReflectionPass";
import { ParticleScenePass } from "./particleScenePass";
import { ParticleShadowPass } from "./particleShadowPass";

/* eslint-disable @typescript-eslint/indent */
const SQRT3 = Math.sqrt(3);
// prettier-ignore
const VERTEX_DATA = new Float32Array([
    /*
     * These vertices consitute a triangle strip that forms the front half (3 sides) of a hexagonal prism with full top
     * cap and no bottom cap. During rendering, they are discretely rotated in 60° steps (to always be congruent with
     * the grid cell) so that the middle side faces the camera. The rotation is computed for each instance individually
     * in the vertex shader. The fragment shader then performs a simplified first-order-rays-only raytracing of a
     * cylinder corresponding to the hexagons incircle and discards any fragments outside of the cylinder. The result is
     * an instanced, always pixel-perfect rendering of a cylinder.
    */
  /*      X     Y   Z */
    // Sides
     0, 0,  2 / SQRT3,
     0, 1,  2 / SQRT3,
     1, 0,  1 / SQRT3,
     1, 1,  1 / SQRT3,
     1, 0, -1 / SQRT3,
     1, 1, -1 / SQRT3,
     0, 0, -2 / SQRT3,
     0, 1, -2 / SQRT3,

     // Top
     0, 1, -2 / SQRT3,
     1, 1, -1 / SQRT3,
    -1, 1, -1 / SQRT3,
     1, 1,  1 / SQRT3,
    -1, 1,  1 / SQRT3,
     0, 1,  2 / SQRT3,
]);
/* eslint-enable @typescript-eslint/indent */

export interface ParticleSharedState {
    readonly instanceCount$: BehaviorSubject<GLsizei>;
    readonly vertexCount: GLsizei;
    readonly vertexBuffer: Buffer;
    readonly heightBuffer: Buffer;
    readonly colorBuffer: Buffer;
    readonly schemeTexture: Texture2D;
    readonly positionTexture$: Observable<Texture2D | undefined>;
    readonly positionTextureSize$: Observable<GLsizei>;
    readonly shadowMap$: Observable<Texture2D | undefined>;
    readonly shadowEnabled$: Observable<GLuint>;
}

export class ParticlePassGroup extends RenderPass {
    public readonly positionTexture$ = new SwitchSubject<Texture2D | undefined>(undefined);
    public readonly heights$ = new SwitchSubject<Float32Array | undefined>(undefined);
    public readonly colors$ = new SwitchSubject<Float32Array | undefined>(undefined);
    public readonly schemeUrl$ = new SwitchSubject<string | undefined>(undefined);
    public readonly shadowMap$ = new SwitchSubject<Texture2D | undefined>(undefined);

    public readonly scenePass: ParticleScenePass;
    public readonly reflectionPass: ParticleReflectionPass;
    public readonly shadowPass: ParticleShadowPass;

    private readonly _particleController: ParticleController;
    private readonly _shared: ParticleSharedState;

    public constructor(particleController: ParticleController, baseParameters: RenderPassParameters) {
        super(baseParameters, "Particle");
        this._particleController = particleController;

        this._shared = {
            instanceCount$: new BehaviorSubject(0),
            vertexCount: VERTEX_DATA.length / 3,
            vertexBuffer: this.createVertexBuffer(),
            heightBuffer: this.createHeightBuffer(),
            colorBuffer: this.createColorBuffer(),
            schemeTexture: this.createSchemeTexture(),
            positionTexture$: this.positionTexture$,
            positionTextureSize$: this.positionTexture$.pipe(
                map((texture: Texture2D | undefined): GLsizei => (texture === undefined ? 0 : texture.width)),
                distinctUntilChanged()
            ),
            shadowMap$: this.shadowMap$,
            shadowEnabled$: this.shadowMap$.pipe(
                map((texture: Texture2D | undefined): GLuint => (texture === undefined ? 0 : 1))
            ),
        };

        this._particleController.numParticles.subscribe(this._shared.instanceCount$);

        this.scenePass = new ParticleScenePass(this._shared, baseParameters);
        this.scenePass.redraw$.subscribe(this.redraw$);
        this.reflectionPass = new ParticleReflectionPass(this._shared, baseParameters);
        this.reflectionPass.redraw$.subscribe(this.redraw$);
        this.shadowPass = new ParticleShadowPass(this._shared, baseParameters);
        this.shadowPass.redraw$.subscribe(this.redraw$);
    }

    protected onRelease(): void {
        this.scenePass.release();
        this.reflectionPass.release();
        this.shadowPass.release();

        this._shared.vertexBuffer.uninitialize();
        this._shared.heightBuffer.uninitialize();
        this._shared.colorBuffer.uninitialize();
        this._shared.schemeTexture.uninitialize();
    }

    protected onUpdate(): void {
        this.scenePass.update();
        this.reflectionPass.update();
        this.shadowPass.update();
    }

    protected onPrepare(): void {
        this.scenePass.prepare();
        this.reflectionPass.prepare();
        this.shadowPass.prepare();
    }

    // eslint-disable-next-line class-methods-use-this
    protected onFrame(): void {
        throw new Error("Don't call ParticlePassGroup.frame(), call ParticlePassGroup.[xyz]Pass.frame() instead!");
    }

    private createVertexBuffer(): Buffer {
        const gl = this._context.gl as WebGLRenderingContext;

        const vertexBuffer = new Buffer(this._context, `${this.name}.vertexBuffer`);
        vertexBuffer.initialize(gl.ARRAY_BUFFER);
        vertexBuffer.data(VERTEX_DATA, gl.STATIC_DRAW);

        return vertexBuffer;
    }

    private createHeightBuffer(): Buffer {
        const gl = this._context.gl as WebGLRenderingContext;

        const buffer = new Buffer(this._context, `${this.name}.heightBuffer`);
        buffer.initialize(gl.ARRAY_BUFFER);
        this.subscribeBuffer(
            this.heights$.pipe(withDefaultData(this._particleController.numParticles, 0.0, Float32Array)),
            buffer,
            gl.STATIC_DRAW
        );

        return buffer;
    }

    private createColorBuffer(): Buffer {
        const gl = this._context.gl as WebGLRenderingContext;

        const buffer = new Buffer(this._context, `${this.name}.colorBuffer`);
        buffer.initialize(gl.ARRAY_BUFFER);
        this.subscribeBuffer(
            this.colors$.pipe(withDefaultData(this._particleController.numParticles, 0.5, Float32Array)),
            buffer,
            gl.STATIC_DRAW
        );

        return buffer;
    }

    private createSchemeTexture(): Texture2D {
        const gl = this._context.gl as WebGLRenderingContext;

        const [internalFormat, type] = Wizard.queryInternalTextureFormat(this._context, gl.RGB, Wizard.Precision.byte);
        const texture = new Texture2D(this._context, `${this.name}.schemeTexture`);
        texture.initialize(256, 1, internalFormat, gl.RGB, type);

        this.schemeUrl$.pipe(this.tapRedraw()).subscribe((url: string | undefined): void => {
            if (url === undefined) {
                texture.resize(1, 1, true, false);
                texture.data(new Uint8Array([127, 127, 127]), true, false);
            } else {
                texture
                    .fetch(url)
                    .then(
                        (): void => this.redraw$.next(),
                        (reason: unknown): void => console.error(`Failed to load scheme texture: ${reason}`)
                    );
            }
        });

        return texture;
    }
}
