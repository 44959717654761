import { AppBar, createStyles, IconButton, makeStyles, Theme, Toolbar, Tooltip, Typography } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import MenuIcon from "@material-ui/icons/Menu";
import React, { FunctionComponent, PropsWithChildren, useCallback, useContext, useState } from "react";

import { DmSessionContext } from "./dmContext";
import { HelpTooltip } from "./helpTooltip";
import { useObservable } from "./useObservable";

const useStyles = makeStyles(
    (theme: Theme): StyleRules =>
        createStyles({
            appBar: { zIndex: theme.zIndex.drawer + 1 },
            menuButton: {
                marginRight: theme.spacing(2),
            },
            spacer: {
                flexGrow: 1,
            },
            tooltipPopper: {
                zIndex: theme.zIndex.modal - 1,
            },
            tooltipPaper: {
                padding: theme.spacing(2),
            },
            tooltipContent: {
                verticalAlign: "middle",
                display: "inline",
            },
        })
);

export interface DmAppBarProps {
    toggleDrawer: () => void;
    onImportDataset: () => void;
    onCloseDataset: () => void;
}

// eslint-disable-next-line max-lines-per-function
export const DmAppBar: FunctionComponent<DmAppBarProps> = (props: PropsWithChildren<DmAppBarProps>): JSX.Element => {
    const session = useContext(DmSessionContext);
    const classes = useStyles();
    const dataset = useObservable(session.dataset, undefined);
    const [tooltipAnchor, setTooltipAnchor] = useState<HTMLElement | null>(null);
    const tooltipAnchorRef = useCallback(setTooltipAnchor, []);
    const [importClicked, setImportClicked] = useState(false);

    function onImportClicked(): void {
        if (!importClicked) {
            setImportClicked(true);
        }
        props.onImportDataset();
    }

    return (
        <>
            <AppBar className={classes.appBar} position="fixed">
                <Toolbar>
                    <Tooltip title="Settings">
                        <IconButton
                            className={classes.menuButton}
                            color="inherit"
                            edge="start"
                            onClick={props.toggleDrawer}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Tooltip>
                    <Typography variant="h6">
                        Dust & Magnet{dataset === undefined ? "" : `\u2003\u2014\u2003${dataset.name}`}
                    </Typography>
                    <div className={classes.spacer} />
                    <Tooltip title="Import Dataset">
                        <IconButton color="inherit" onClick={onImportClicked} ref={tooltipAnchorRef}>
                            <ImportExportIcon />
                        </IconButton>
                    </Tooltip>
                    {dataset === undefined ? null : (
                        <Tooltip title="Close Dataset">
                            <IconButton color="inherit" onClick={props.onCloseDataset}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Toolbar>
            </AppBar>
            <HelpTooltip
                open={!importClicked && dataset === undefined}
                anchorEl={tooltipAnchor}
                text="No data loaded. Click here to import a CSV file."
                placement="bottom-end"
                delay={3000}
            />
        </>
    );
};
