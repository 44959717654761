import {
    BehaviorSubject,
    combineLatest,
    MonoTypeOperatorFunction,
    NextObserver,
    Observable,
    Observer,
    Operator,
    OperatorFunction,
    Subject,
    Subscriber,
    Subscription,
    TeardownLogic,
} from "rxjs";
import { map, multicast, refCount, tap } from "rxjs/operators";
import { isArray } from "util";
import { Buffer, Program } from "webgl-operate";

class LatestWhenSubscriber<T> extends Subscriber<T> {
    private _emitted: boolean = false;
    private _latestValue?: T;

    public constructor(destination: Observer<T>, notifier: Observable<unknown>) {
        super(destination);
        this.add(
            notifier.subscribe((): void => {
                if (
                    this._emitted &&
                    this.destination.next !== undefined &&
                    this.destination.closed !== undefined &&
                    !this.destination.closed
                ) {
                    this.destination.next(this._latestValue);
                    this._emitted = false;
                }
            })
        );
    }

    protected _next(value: T): void {
        this._latestValue = value;
        this._emitted = true;
    }
}

class LatestWhenOperator<T> implements Operator<T, T> {
    private readonly _notifier: Observable<unknown>;

    public constructor(notifier: Observable<unknown>) {
        this._notifier = notifier;
    }

    public call(subscriber: Subscriber<T>, source: Observable<T>): TeardownLogic {
        return source.subscribe(new LatestWhenSubscriber(subscriber, this._notifier));
    }
}

export function latestWhen<T>(notifier: Observable<unknown>): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>): Observable<T> => source.lift(new LatestWhenOperator(notifier));
}

export function tapTo<T, R>(observer: NextObserver<R>, value: R): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>): Observable<T> =>
        source.pipe(
            tap(
                (): void => observer.next(value),
                observer.error === undefined ? undefined : observer.error.bind(observer),
                observer.complete === undefined ? undefined : observer.complete.bind(observer)
            )
        );
}

export function tapRedraw<T>(redraw: NextObserver<boolean>, force: boolean = false): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>): Observable<T> => source.pipe(tapTo(redraw, force));
}

export function subscribeBuffer(
    data: Observable<ArrayBufferView | ArrayBuffer>,
    buffer: Buffer,
    usage: GLenum,
    redraw?: NextObserver<boolean>
): Subscription {
    const withRedraw = redraw === undefined ? data : data.pipe(tapRedraw(redraw));
    return withRedraw.subscribe((currentData: ArrayBufferView | ArrayBuffer): void => {
        buffer.data(currentData, usage);
    });
}

// eslint-disable-next-line max-params
export function subscribeUniform<T>(
    program: Program,
    uniform: string,
    setter: (location: WebGLUniformLocation, value: T) => void,
    value: Observable<T>,
    redraw?: NextObserver<boolean>,
    log = false
): Subscription {
    const location = program.uniform(uniform);
    const withRedraw = redraw === undefined ? value : value.pipe(tapRedraw(redraw));
    return withRedraw.subscribe((currentValue: T): void => {
        program.bind();
        if (log) {
            console.log(`Setting uniform ${program.identifier}.${uniform} = ${currentValue}`);
        }
        setter(location, currentValue);
    });
}

type TypedArray =
    | Int8Array
    | Uint8Array
    | Int16Array
    | Uint16Array
    | Int32Array
    | Uint32Array
    | Uint8ClampedArray
    | Float32Array
    | Float64Array;
export function withDefaultData<T extends TypedArray | number[]>(
    length: Observable<number>,
    value: number | number[],
    ArrayType: new (length: number) => T
): OperatorFunction<T | undefined, T> {
    return (source: Observable<T | undefined>): Observable<T> =>
        combineLatest(
            source,
            length,
            (data: T | undefined, currentLength: number): T => {
                if (data !== undefined && data.length === currentLength) {
                    return data;
                }

                const valueArray = isArray(value) ? value : [value];
                const defaultData = new ArrayType(currentLength);
                for (let i = 0; i < currentLength; i += 1) {
                    for (let valueIndex = 0; valueIndex < valueArray.length; valueIndex += 1) {
                        defaultData[i * valueArray.length + valueIndex] = valueArray[valueIndex];
                    }
                }

                return defaultData;
            }
        );
}

export class SwitchSubject<T> extends Observable<T> {
    private readonly _subject: BehaviorSubject<T>;
    private _subscription?: Subscription;

    public constructor(initialValue: T) {
        super();

        this._subject = new BehaviorSubject(initialValue);
    }

    public _subscribe(subscriber: Subscriber<T>): TeardownLogic {
        return this._subject.subscribe(subscriber);
    }

    public set src(source: Observable<T> | undefined) {
        if (this._subscription !== undefined) {
            this._subscription.unsubscribe();
            this._subscription = undefined;
        }
        if (source !== undefined) {
            this._subscription = source.subscribe(this._subject);
        }
    }
}

export function shareBehavior<T>(initialValue: () => T): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>): Observable<T> =>
        refCount()(multicast((): Subject<T> => new BehaviorSubject<T>(initialValue()))(source)) as Observable<T>;
}

export function ifMapTo<T1, T2>(ifValue: T1, elseValue: T2): OperatorFunction<boolean, T1 | T2> {
    return (source: Observable<boolean>): Observable<T1 | T2> =>
        map((value: boolean): T1 | T2 => (value ? ifValue : elseValue))(source);
}
