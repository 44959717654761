/* eslint-disable max-lines */

import { mat4, vec3 } from "gl-matrix";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Camera as WebglOperateCamera } from "webgl-operate";
import { GLfloat2, GLsizei2 } from "webgl-operate/lib/tuples";

import { shareBehavior } from "../operators";

const DEG2RAD = 0.017453292519943295;

export class Camera extends WebglOperateCamera {
    protected readonly _eye$ = new BehaviorSubject(this._eye);
    protected readonly _center$ = new BehaviorSubject(this._center);
    protected readonly _up$ = new BehaviorSubject(this._up);

    protected readonly _fovy$ = new BehaviorSubject(this._fovy);
    protected readonly _near$ = new BehaviorSubject(this._near);
    protected readonly _far$ = new BehaviorSubject(this._far);
    protected readonly _viewport$ = new BehaviorSubject(this._viewport);
    protected readonly _aspect$ = new BehaviorSubject(this._aspect);

    protected readonly _view$: Observable<mat4>;
    protected readonly _viewInverse$: Observable<mat4 | null>;
    protected readonly _projection$: Observable<mat4>;
    protected readonly _projectionInverse$: Observable<mat4 | null>;
    protected readonly _viewProjection$: Observable<mat4>;
    protected readonly _viewProjectionInverse$: Observable<mat4 | null>;

    public constructor(initialEye?: vec3, initialCenter?: vec3, initialUp?: vec3) {
        super(initialEye, initialCenter, initialUp);

        this._view$ = combineLatest(
            this._eye$,
            this._center$,
            this._up$,
            (eye: vec3, center: vec3, up: vec3): mat4 => mat4.lookAt(mat4.create(), eye, center, up)
        ).pipe(shareBehavior((): mat4 => this.view));
        this._viewInverse$ = this._view$.pipe(
            map((view: mat4): mat4 | null => mat4.invert(mat4.create(), view)),
            shareBehavior((): mat4 | null => this.viewInverse)
        );

        this._projection$ = combineLatest(
            this._fovy$,
            this._aspect$,
            this._near$,
            this._far$,
            (fovy: GLfloat, aspect: GLfloat, near: GLfloat, far: GLfloat): mat4 =>
                mat4.perspective(mat4.create(), fovy * DEG2RAD, aspect, near, far)
        ).pipe(shareBehavior((): mat4 => this.projection));
        this._projectionInverse$ = this._projection$.pipe(
            map((projection: mat4): mat4 | null => mat4.invert(mat4.create(), projection)),
            shareBehavior((): mat4 | null => this.projectionInverse)
        );

        this._viewProjection$ = combineLatest(
            this._view$,
            this._projection$,
            (view: mat4, projection: mat4): mat4 => mat4.multiply(mat4.create(), projection, view)
        ).pipe(shareBehavior((): mat4 => this.viewProjection));
        this._viewProjectionInverse$ = this._viewProjection$.pipe(
            map((viewProjection: mat4): mat4 | null => mat4.invert(mat4.create(), viewProjection)),
            shareBehavior((): mat4 | null => this.viewProjectionInverse)
        );
    }

    public get eye$(): Observable<vec3> {
        return this._eye$.asObservable();
    }

    public get eye(): vec3 {
        return this._eye;
    }

    public set eye(eye: vec3) {
        if (vec3.equals(this._eye, eye)) {
            return;
        }
        this._eye = vec3.clone(eye);
        this.invalidate(true, false);
        this._eye$.next(eye);
    }

    public get center$(): Observable<vec3> {
        return this._center$.asObservable();
    }

    public get center(): vec3 {
        return this._center;
    }

    public set center(center: vec3) {
        if (vec3.equals(this._center, center)) {
            return;
        }
        this._center = vec3.clone(center);
        this.invalidate(true, false);
        this._center$.next(center);
    }

    public get up$(): Observable<vec3> {
        return this._up$.asObservable();
    }

    public get up(): vec3 {
        return this._up;
    }

    public set up(up: vec3) {
        if (vec3.equals(this._up, up)) {
            return;
        }
        this._up = vec3.clone(up);
        this.invalidate(true, false);
        this._up$.next(up);
    }

    public get fovy$(): Observable<GLfloat> {
        return this._fovy$.asObservable();
    }

    public get fovy(): GLfloat {
        return this._fovy;
    }

    public set fovy(fovy: GLfloat) {
        if (this._fovy === fovy) {
            return;
        }
        this._fovy = fovy;
        this.invalidate(false, true);
        this._fovy$.next(fovy);
    }

    public get near$(): Observable<GLfloat> {
        return this._near$.asObservable();
    }

    public get near(): GLfloat {
        return this._near;
    }

    public set near(near: GLfloat) {
        if (this._near === near) {
            return;
        }
        this._near = near;
        this.invalidate(false, true);
        this._near$.next(near);
    }

    public get far$(): Observable<GLfloat> {
        return this._far$.asObservable();
    }

    public get far(): GLfloat {
        return this._far;
    }

    public set far(far: GLfloat) {
        if (this._far === far) {
            return;
        }
        this._far = far;
        this.invalidate(false, true);
        this._far$.next(far);
    }

    public get nearFar$(): Observable<GLfloat2> {
        return combineLatest(this.near$, this.far$);
    }

    public get viewport$(): Observable<GLsizei2> {
        return this._viewport$.asObservable();
    }

    public get viewport(): GLsizei2 {
        return this._viewport;
    }

    public set viewport(viewport: GLsizei2) {
        if (this._viewport[0] === viewport[0] && this._viewport[1] === viewport[1]) {
            return;
        }
        this._viewport = viewport.slice() as GLsizei2;
        this._viewport$.next(this.viewport);
    }

    public get inverseViewport$(): Observable<GLfloat2> {
        return this.viewport$.pipe(map((viewport: GLsizei2): GLsizei2 => [1 / viewport[0], 1 / viewport[1]]));
    }

    public get inverseViewport(): GLfloat2 {
        return [1 / this.viewport[0], 1 / this.viewport[1]];
    }

    public get aspect$(): Observable<GLfloat> {
        return this._aspect$.asObservable();
    }

    public get aspect(): GLfloat {
        return this._aspect;
    }

    public set aspect(aspect: GLfloat) {
        if (this._aspect === aspect) {
            return;
        }
        this._aspect = aspect;
        this.invalidate(false, true);
        this._aspect$.next(aspect);
    }

    public get view$(): Observable<mat4> {
        return this._view$;
    }

    public get view$Inverse(): Observable<mat4 | null> {
        return this._viewInverse$;
    }

    public get projection$(): Observable<mat4> {
        return this._projection$;
    }

    public get projectionInverse$(): Observable<mat4 | null> {
        return this._projectionInverse$;
    }

    public get viewProjection$(): Observable<mat4> {
        return this._viewProjection$;
    }

    public get viewProjectionInverse$(): Observable<mat4 | null> {
        return this._viewProjectionInverse$;
    }
}
