import React, { ComponentType, FunctionComponent, useEffect, useState } from "react";

export interface WithOpenDelayProps {
    delay?: number;
    repeat?: boolean;
}

export function withOpenDelay<Props extends { open: boolean }>(
    WrappedComponent: ComponentType<Props>
): FunctionComponent<Props & WithOpenDelayProps> {
    const wrapped: FunctionComponent<Props & WithOpenDelayProps> = (props: Props & WithOpenDelayProps): JSX.Element => {
        const { open, delay, repeat, ...forwardProps } = props;

        const [delayedOpen, setDelayedOpen] = useState(false);
        const [wasOpen, setWasOpen] = useState(false);
        const [timerId, setTimerId] = useState<number | undefined>(undefined);

        // the desired open state
        const shouldOpen = open && ((repeat !== undefined && repeat) || !wasOpen);

        // close if is open, but should not
        if (!shouldOpen && delayedOpen) {
            setDelayedOpen(false);
            setWasOpen(true);
        }

        useEffect((): void => {
            // trigger timeout if is not open, but should
            if (shouldOpen && timerId === undefined) {
                setTimerId(
                    setTimeout((): void => {
                        setDelayedOpen(true);
                    }, delay)
                );
            }
            // cancel active timeout if should not open anymore
            if (!shouldOpen && timerId !== undefined) {
                clearTimeout(timerId);
                setTimerId(undefined);
            }
        }, [shouldOpen, delay, timerId]);

        return <WrappedComponent {...(forwardProps as Props)} open={delayedOpen} />;
    };

    wrapped.displayName = `WithOpenDelay(${[WrappedComponent.displayName, WrappedComponent.name, "Component"].find(
        (value): boolean => value !== undefined
    )})`;

    return wrapped;
}
