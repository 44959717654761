import { vec3 } from "gl-matrix";
import { BehaviorSubject, Observable } from "rxjs";
import { Color } from "webgl-operate";

import { Camera } from "./camera";

export class Light extends Camera {
    protected _color: Color = new Color([1, 1, 1], 1);
    protected readonly _color$ = new BehaviorSubject(this._color);

    public constructor(position?: vec3, color?: Color) {
        super(position);

        if (color !== undefined) {
            this.color = color;
        }
    }

    public get position$(): Observable<vec3> {
        return this.eye$;
    }

    public get position(): vec3 {
        return this.eye;
    }

    public set position(position: vec3) {
        this.eye = position;
    }

    public get color$(): Observable<Color> {
        return this._color$.asObservable();
    }

    public get color(): Color {
        return this._color;
    }

    public set color(color: Color) {
        if (color === this._color) {
            return;
        }

        this._color = color;
        this._color$.next(color);
    }
}
