import "typeface-roboto";

import React, { FunctionComponent, useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { DmClient } from "./components/dmClient";

async function fetchServerUrl(): Promise<string> {
    try {
        const response = await fetch("config.json");
        if (response.ok) {
            const config = await response.json();
            if ("serverUrl" in config) {
                console.log(`Using configured server URL: ${config.serverUrl}`);
                return config.serverUrl;
            }
        }
    } catch (error) {
        // fallback to default below
    }

    console.log("Using default server URL: localhost:3000");
    return "localhost:3000";
}

const Index: FunctionComponent = (): JSX.Element => {
    const [serverUrl, setServerUrl] = useState();
    useEffect((): void => {
        fetchServerUrl().then(setServerUrl, console.error.bind(console));
    }, []);

    if (serverUrl !== undefined) {
        return <DmClient serverUrl={serverUrl} />;
    }
    return <div>Connecting...</div>;
};

ReactDOM.render(<Index />, document.getElementById("root"));
