import { NdcFillingTriangle, Program, Texture2D } from "webgl-operate";

import { assert, assertExists } from "../../helpers";
import { RenderPass, RenderPassParameters } from "../renderPass";
import fragmentShaderSource from "./fxaaPass.frag";
import vertexShaderSource from "./fxaaPass.vert";

export class FxaaPass extends RenderPass {
    public colorTexture?: Texture2D;

    private readonly _program: Program;
    private readonly _ndcTriangle: NdcFillingTriangle;

    public constructor(baseParameters: RenderPassParameters) {
        super(baseParameters, "Fxaa");

        assert(this._context.backend === "webgl2", "FXAA currently requires WebGL2");

        const gl = this._context.gl as WebGLRenderingContext;

        this._program = this.loadShader(vertexShaderSource, fragmentShaderSource, ["in_position"]);
        this._program.bind();
        gl.uniform1i(this._program.uniform("colorTexture"), 0);

        this._ndcTriangle = new NdcFillingTriangle(this._context, `${this.name}.geometry`);
        this._ndcTriangle.initialize(0);

        this.subscribeUniform2f(this._camera.inverseViewport$, this._program, "inverseViewport");
    }

    protected onRelease(): void {
        this._program.uninitialize();
        this._ndcTriangle.uninitialize();
    }

    protected onFrame(): void {
        const gl = this._context.gl as WebGLRenderingContext;

        this._program.bind();
        assertExists(this.colorTexture).bind(gl.TEXTURE0);
        this._ndcTriangle.bind();
        this._ndcTriangle.draw();
    }
}
