import { BehaviorSubject, Observable, Subject } from "rxjs";

import { MagnetDesc } from "./api";
import { ControlServerAdapter } from "./controlServerAdapter";
import { assert, assertExists } from "./helpers";
import { MagnetController } from "./magnetController";

export class MagnetSetController {
    private readonly _magnets = new Map<number, [MagnetController, BehaviorSubject<MagnetDesc>]>();
    private readonly _magnets$ = new Subject<MagnetController>();
    private readonly _remote: ControlServerAdapter;

    public constructor(remote: ControlServerAdapter) {
        this._remote = remote;
    }

    public get magnets(): MagnetController[] {
        return [...this._magnets.values()].map(
            ([controller, _]: [MagnetController, BehaviorSubject<MagnetDesc>]): MagnetController => controller
        );
    }

    public get magnets$(): Observable<MagnetController> {
        return this._magnets$.asObservable();
    }

    public createMagnet(desc: MagnetDesc): void {
        assert(!this._magnets.has(desc.id), "Magnet ids must be unique");

        const subject = new BehaviorSubject<MagnetDesc>(desc);
        const controller = new MagnetController(desc.id, subject.asObservable(), this._remote);
        this._magnets.set(desc.id, [controller, subject]);
        this._magnets$.next(controller);
    }

    public setMagnet(desc: MagnetDesc): void {
        const magnet = assertExists(this._magnets.get(desc.id), `Unknown magnet id: ${desc.id}`);
        magnet[1].next(desc);
    }

    public removeMagnet(desc: MagnetDesc): void {
        const magnet = assertExists(this._magnets.get(desc.id), `Unknown magnet id: ${desc.id}`);
        magnet[1].complete();

        this._magnets.delete(desc.id);
    }

    public magnet(id: number): MagnetController | undefined {
        const magnet = this._magnets.get(id);
        if (magnet === undefined) {
            return undefined;
        }

        return magnet[0];
    }
}
