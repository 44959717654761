/* eslint-disable max-params */

import { mat4, vec2, vec3, vec4 } from "gl-matrix";
import { GLfloat2, GLfloat3 } from "webgl-operate/lib/tuples";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type vec2orArray = vec2 | GLfloat2;
// eslint-disable-next-line @typescript-eslint/no-type-alias
export type vec3orArray = vec3 | GLfloat3;

export function unproject(
    windowPosition: vec2orArray,
    depth: GLfloat,
    viewProjectionInverted: mat4,
    viewport: vec2orArray
): vec3 {
    // Transform viewport to [-1;+1] (including z!)
    const ndcPosition = vec4.fromValues(
        (windowPosition[0] / viewport[0]) * 2 - 1,
        (windowPosition[1] / viewport[1]) * 2 - 1,
        depth * 2 - 1,
        1
    );

    // Unproject this point back to object space
    const unprojected = vec4.transformMat4(vec4.create(), ndcPosition, viewProjectionInverted);

    return vec3.fromValues(
        unprojected[0] / unprojected[3],
        unprojected[1] / unprojected[3],
        unprojected[2] / unprojected[3]
    );
}

export function intersectRayPlane(
    rayBegin: vec3orArray,
    rayEnd: vec3orArray,
    planePosition: vec3orArray,
    planeNormal: vec3orArray
): vec3 | undefined {
    const ray = vec3.subtract(vec3.create(), rayEnd, rayBegin);

    if (ray[0] === 0 && ray[1] === 0) {
        return undefined;
    }

    // Intersect with plane in point normal form
    const lDotN = vec3.dot(ray, planeNormal);

    const scale = vec3.dot(vec3.subtract(vec3.create(), planePosition, rayBegin), planeNormal) / lDotN;
    const position = vec3.scale(vec3.create(), ray, scale);
    vec3.add(position, position, rayBegin); // Retrieve point via the ray

    return position;
}

export function intersectMouseRayPlane(
    mousePosition: vec2orArray,
    planePosition: vec3orArray,
    planeNormal: vec3orArray,
    viewProjectionInverted: mat4,
    viewport: vec2orArray
): vec3 | undefined {
    // Build a ray in object space from screen space mouse position and get intersection with near and far planes.
    const pointNear = unproject(mousePosition, 0, viewProjectionInverted, viewport);
    const pointFar = unproject(mousePosition, 1, viewProjectionInverted, viewport);

    return intersectRayPlane(pointNear, pointFar, planePosition, planeNormal);
}
