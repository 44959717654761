import { Program, VertexArray } from "webgl-operate";

import { RenderPass, RenderPassParameters } from "../renderPass";
import { MagnetSharedState } from "./magnetPassGroup";
import fragmentShaderSource from "./magnetTopScenePass.frag";
import vertexShaderSource from "./magnetTopScenePass.vert";

export class MagnetTopScenePass extends RenderPass {
    private readonly _shared: MagnetSharedState;
    private readonly _vertexArray: VertexArray;
    private readonly _program: Program;

    public constructor(shared: MagnetSharedState, baseParameters: RenderPassParameters) {
        super(baseParameters, "MagnetTopScene");
        this._shared = shared;

        const gl = this._context.gl as WebGLRenderingContext;

        this._program = this.loadShader(vertexShaderSource, fragmentShaderSource, ["in_vertex", "in_position"]);
        this._vertexArray = this.createVertexArray([
            { buffer: shared.topVertexBuffer, size: 3, type: gl.FLOAT },
            { buffer: shared.instanceBuffer, size: 2, type: gl.FLOAT, divisor: 1 },
        ]);

        this.createSubscriptions();
    }

    protected onRelease(): void {
        this._vertexArray.uninitialize();
        this._program.uninitialize();
    }

    protected onFrame(): void {
        const gl = this._context.gl as WebGLRenderingContext;
        const gl2 = this._context.gl2facade;

        gl.enable(gl.DEPTH_TEST);
        gl.enable(gl.BLEND);
        gl.enable(gl.CULL_FACE);
        gl.frontFace(gl.CW);

        this._program.bind();
        this._vertexArray.bind();

        gl2.drawArraysInstanced(gl.TRIANGLE_STRIP, 0, this._shared.topVertexCount, this._shared.instanceCount);

        gl.disable(gl.DEPTH_TEST);
        gl.disable(gl.BLEND);
        gl.disable(gl.CULL_FACE);
        gl.frontFace(gl.CCW);
    }

    private createSubscriptions(): void {
        this.subscribeUniform1f(this.config$("magnets.height"), this._program, "height");
        this.subscribeUniform1f(this.config$("magnets.offset"), this._program, "offset");
        this.subscribeUniform1f(this.config$("magnets.radius"), this._program, "radius");
        this.subscribeUniform4f(this.config$("magnets.color"), this._program, "color");

        this.subscribeUniformMat4(this._camera.viewProjection$, this._program, "viewProjection");
        this.subscribeUniform3f(this._camera.eye$, this._program, "eye");
        this.subscribeUniform3f(this._light.position$, this._program, "lightPosition");
        this.subscribeUniformColor(this._light.color$, this._program, "lightColor");
    }
}
