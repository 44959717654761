import { vec2, vec3 } from "gl-matrix";
import {
    Camera,
    EventHandler,
    gl_matrix_extensions,
    Invalidate,
    MouseEventProvider,
    TouchEventProvider,
} from "webgl-operate";
import { GLfloat2 } from "webgl-operate/lib/tuples";

import { assertExists } from "../helpers";
import { MagnetController } from "../magnetController";
import { MagnetSetController } from "../magnetSetController";
import { intersectMouseRayPlane } from "./math";
import { ObjectPicker, PickResult } from "./objectPicker";

export class Interaction {
    /** @see {@link camera} */
    private _camera?: Camera;

    /**
     * Even handler used to forward/map events to specific camera modifiers.
     */
    private readonly _eventHandler: EventHandler;
    private readonly _objectPicker: ObjectPicker;

    private readonly _magnetSetController: MagnetSetController;
    private _selectedMagnet?: MagnetController;
    private _referencePosition?: vec3;
    private _magnetReferencePosition: GLfloat2 = [0, 0];

    // eslint-disable-next-line max-params
    public constructor(
        magnetSetController: MagnetSetController,
        callback: Invalidate,
        mouseEventProvider: MouseEventProvider,
        touchEventProvider: TouchEventProvider,
        objectPicker: ObjectPicker,
        camera: Camera
    ) {
        this._magnetSetController = magnetSetController;
        this._objectPicker = objectPicker;
        this._camera = camera;

        /* Create event handler that listens to mouse events. */
        this._eventHandler = new EventHandler(callback, mouseEventProvider, touchEventProvider);

        /* Listen to mouse events. */
        this._eventHandler.pushMouseDownHandler((latests: MouseEvent[]): void => {
            this.onMouseDown(latests);
        });
        this._eventHandler.pushMouseUpHandler((latests: MouseEvent[]): void => {
            this.onMouseUp(latests);
        });
        this._eventHandler.pushMouseMoveHandler((latests: MouseEvent[]): void => {
            this.onMouseMove(latests);
        });
    }

    /**
     * Update should invoke navigation specific event processing. When using, e.g., an event handler, the event handlers
     * update method should be called in order to have navigation specific event processing invoked.
     */
    public update(): void {
        this._eventHandler.update();
    }

    private getViewportPosition(event: MouseEvent): vec2 {
        return this._eventHandler.offsets(event, true)[0];
    }

    private onMouseDown(latests: MouseEvent[]): void {
        const camera = assertExists(this._camera, "Expected valid camera");
        const viewProjectionInverse = assertExists(
            camera.viewProjectionInverse,
            "Expected valid viewProjectionInverse"
        );

        const event = latests[latests.length - 1];

        if (event.button === 0) {
            const viewportPosition = this.getViewportPosition(event);

            this._selectedMagnet = undefined;

            const pickResult: PickResult | undefined = this._objectPicker.pick(viewportPosition);
            if (pickResult !== undefined) {
                if (pickResult.objectType === "magnet") {
                    this._selectedMagnet = assertExists(
                        this._magnetSetController.magnet(pickResult.objectId),
                        "Unknown magnet selected"
                    );
                    this._referencePosition = this._objectPicker.coordsAt(
                        viewportPosition,
                        undefined,
                        viewProjectionInverse
                    );
                    this._magnetReferencePosition = this._selectedMagnet.position.value.slice() as GLfloat2;
                }
            }
            event.preventDefault();
        }
    }

    private onMouseUp(latests: MouseEvent[]): void {
        const event = latests[latests.length - 1];

        if (event.button === 0) {
            this._selectedMagnet = undefined;
            event.preventDefault();
        }
    }

    private onMouseMove(latests: MouseEvent[]): void {
        if (this._selectedMagnet === undefined) {
            return;
        }

        const camera = assertExists(this._camera, "Expected valid camera");
        const viewProjectionInverse = assertExists(
            camera.viewProjectionInverse,
            "Expected valid viewProjectionInverse"
        );
        const referencePosition = assertExists(this._referencePosition, "Expected valid referencePosition");

        const event = latests[latests.length - 1];

        let viewportPosition = this.getViewportPosition(event);
        viewportPosition[1] = camera.viewport[1] - viewportPosition[1] - 1;
        viewportPosition = gl_matrix_extensions.clamp2(
            viewportPosition,
            viewportPosition,
            [0, 0],
            vec2.subtract(vec2.create(), camera.viewport, [1, 1])
        );

        const newPosition: vec3 | undefined = intersectMouseRayPlane(
            viewportPosition,
            referencePosition,
            [0, 1, 0],
            viewProjectionInverse,
            camera.viewport
        );
        if (newPosition !== undefined) {
            const delta = vec3.subtract(newPosition, newPosition, referencePosition);
            this._selectedMagnet.position.value = [
                this._magnetReferencePosition[0] + delta[0],
                this._magnetReferencePosition[1] + delta[2],
            ];
        }

        event.preventDefault();
    }

    /**
     * The camera that is to be modified in response to various events.
     */
    public set camera(camera: Camera) {
        this._camera = camera;
    }
}
