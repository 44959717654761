import { Grow, Paper, Popper, Typography } from "@material-ui/core";
import { createStyles, makeStyles, StyleRules, Theme } from "@material-ui/core/styles";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import React, { FunctionComponent } from "react";

import { withOpenDelay } from "./withOpenDelay";

const useStyles = makeStyles(
    (theme: Theme): StyleRules =>
        createStyles({
            tooltipPopper: {
                zIndex: theme.zIndex.modal - 1,
            },
            tooltipPaper: {
                padding: theme.spacing(2),
                backgroundColor: theme.palette.grey[800],
                color: theme.palette.getContrastText(theme.palette.grey[800]),
                display: "flex",
            },
        })
);

export type HelpTooltipPlacement = "bottom-end" | "bottom-start" | "left" | "right" | "top-end" | "top-start";

export interface HelpTooltipProps {
    open: boolean;
    placement: HelpTooltipPlacement;
    anchorEl: HTMLElement | null;
    text: string;
    noarrow?: boolean;
}

function preArrow(
    placement: HelpTooltipPlacement,
    noarrow: boolean | undefined,
    className: string
): JSX.Element | null {
    if (noarrow !== undefined && noarrow) {
        return null;
    }
    switch (placement) {
        case "bottom-start":
            return <ArrowUpwardIcon className={className} style={{ marginRight: "8px" }} />;
        case "right":
            return <ArrowLeftIcon className={className} style={{ marginRight: "8px" }} />;
        case "top-start":
            return <ArrowDownwardIcon className={className} style={{ marginRight: "8px" }} />;
        default:
            return null;
    }
}

function postArrow(
    placement: HelpTooltipPlacement,
    noarrow: boolean | undefined,
    className: string
): JSX.Element | null {
    if (noarrow !== undefined && noarrow) {
        return null;
    }
    switch (placement) {
        case "bottom-end":
            return <ArrowUpwardIcon className={className} style={{ marginLeft: "8px" }} />;
        case "left":
            return <ArrowRightIcon className={className} style={{ marginLeft: "8px" }} />;
        case "top-end":
            return <ArrowDownwardIcon className={className} style={{ marginLeft: "8px" }} />;
        default:
            return null;
    }
}

const InnerHelpTooltip: FunctionComponent<HelpTooltipProps> = (props: HelpTooltipProps): JSX.Element => {
    const classes = useStyles();
    return (
        <Popper
            open={props.open}
            anchorEl={props.anchorEl}
            className={classes.tooltipPopper}
            placement={props.placement}
            transition
        >
            {({ TransitionProps }): JSX.Element => (
                <Grow {...TransitionProps}>
                    <Paper className={classes.tooltipPaper}>
                        {preArrow(props.placement, props.noarrow, classes.tooltipContent)}
                        <Typography className={classes.tooltipContent}>{props.text}</Typography>
                        {postArrow(props.placement, props.noarrow, classes.tooltipContent)}
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
};

export const HelpTooltip = withOpenDelay(InnerHelpTooltip);
