import { combineLatest } from "rxjs";
import { Program, VertexArray } from "webgl-operate";

import { RenderPass, RenderPassParameters } from "../renderPass";
import { MagnetSharedState } from "./magnetPassGroup";
import fragmentShaderSource from "./magnetTopShadowPass.frag";
import vertexShaderSource from "./magnetTopShadowPass.vert";

export class MagnetTopShadowPass extends RenderPass {
    private readonly _shared: MagnetSharedState;
    private readonly _vertexArray: VertexArray;
    private readonly _program: Program;

    public constructor(shared: MagnetSharedState, baseParameters: RenderPassParameters) {
        super(baseParameters, "MagnetTopShadow");
        this._shared = shared;

        const gl = this._context.gl as WebGLRenderingContext;

        this._program = this.loadShader(vertexShaderSource, fragmentShaderSource, ["in_vertex", "in_position"]);
        this._vertexArray = this.createVertexArray([
            { buffer: shared.topVertexBuffer, size: 3, type: gl.FLOAT },
            { buffer: shared.instanceBuffer, size: 2, type: gl.FLOAT, divisor: 1 },
        ]);

        this.createSubscriptions();
    }

    protected onRelease(): void {
        this._vertexArray.uninitialize();
        this._program.uninitialize();
    }

    protected onFrame(): void {
        const gl = this._context.gl as WebGLRenderingContext;
        const gl2 = this._context.gl2facade;

        gl.enable(gl.DEPTH_TEST);
        gl.enable(gl.CULL_FACE);
        gl.frontFace(gl.CW);

        this._program.bind();
        this._vertexArray.bind();

        gl2.drawArraysInstanced(gl.TRIANGLE_STRIP, 0, this._shared.topVertexCount, this._shared.instanceCount);

        gl.disable(gl.DEPTH_TEST);
        gl.disable(gl.CULL_FACE);
        gl.frontFace(gl.CCW);
    }

    private createSubscriptions(): void {
        this.subscribeUniform1f(this.config$("magnets.height"), this._program, "height");
        this.subscribeUniform1f(this.config$("magnets.offset"), this._program, "offset");
        this.subscribeUniform1f(this.config$("magnets.radius"), this._program, "radius");

        this.subscribeUniformMat4(this._light.viewProjection$, this._program, "viewProjection");
        this.subscribeUniform3f(this._light.eye$, this._program, "eye");
        this.subscribeUniform2f(this._light.nearFar$, this._program, "nearFar");
        this.subscribeUniform2f(
            combineLatest(this.config$("shadow.positiveExponent"), this.config$("shadow.negativeExponent")),
            this._program,
            "exponents"
        );
    }
}
