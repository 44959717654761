import { createStyles, Drawer, makeStyles, Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import React, { FunctionComponent, PropsWithChildren } from "react";

import { DmSideMenu } from "./dmSideMenu";

const drawerWidth = 350;

const useStyles = makeStyles(
    (theme: Theme): StyleRules =>
        createStyles({
            drawer: {
                width: drawerWidth,
                flexShrink: 0,
            },
            drawerPaper: {
                width: drawerWidth,
                background: "rgba(255, 255, 255, 0.7)",
            },
            toolbar: theme.mixins.toolbar,
        })
);

export interface DmDrawerProps {
    open: boolean;
}

export const DmDrawer: FunctionComponent<DmDrawerProps> = (props: PropsWithChildren<DmDrawerProps>): JSX.Element => {
    const classes = useStyles();
    return (
        <Drawer
            anchor="left"
            classes={{
                paper: classes.drawerPaper,
            }}
            className={classes.drawer}
            open={props.open}
            PaperProps={{ elevation: 3 }}
            variant="persistent"
        >
            <div className={classes.toolbar} />
            <DmSideMenu />
        </Drawer>
    );
};
