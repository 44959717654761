import React, { FunctionComponent, PropsWithChildren } from "react";

import { AttributeMap } from "../attributeMap";
import { ControlGroup } from "./controlGroup";
import { SelectionPropertyControl } from "./selectionPropertyControl";
import { SwitchPropertyControl } from "./switchPropertyControl";
import { useObservableProperty } from "./useObservable";

export interface HeightMapControlProps {
    map: AttributeMap;
    disableGutter?: boolean;
}

export const HeightMapControl: FunctionComponent<HeightMapControlProps> = (
    props: PropsWithChildren<HeightMapControlProps>
): JSX.Element | null => {
    const [enabled, setEnabled] = useObservableProperty(props.map.enabled);

    return (
        <ControlGroup
            title="Height Mapping"
            checked={enabled}
            onChange={setEnabled}
            disableGutter={props.disableGutter}
        >
            <SelectionPropertyControl target={props.map.attribute} choices$={props.map.choices$} label="Attribute" />
            <SwitchPropertyControl target={props.map.invert} label="Invert" />
        </ControlGroup>
    );
};
