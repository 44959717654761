import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles/withStyles";
import React, { FunctionComponent, PropsWithChildren, useContext, useState } from "react";
import { map } from "rxjs/operators";

import { AttributeDesc, DatasetDesc } from "../api";
import { MagnetController } from "../magnetController";
import { DmSessionContext } from "./dmContext";
import { SelectionPropertyControl } from "./selectionPropertyControl";
import { SliderPropertyControl } from "./sliderPropertyControl";
import { SwitchPropertyControl } from "./switchPropertyControl";

const useStyles = makeStyles(
    (theme: Theme): StyleRules =>
        createStyles({
            spacer: {
                height: theme.spacing(1),
            },
        })
);

export interface MagnetControlsProps {
    magnet: MagnetController;
}

// eslint-disable-next-line max-lines-per-function
export const MagnetControls: FunctionComponent<MagnetControlsProps> = (
    props: PropsWithChildren<MagnetControlsProps>
): JSX.Element | null => {
    const classes = useStyles();
    const session = useContext(DmSessionContext);
    const [choices$] = useState(
        session.dataset.pipe(
            map((current: DatasetDesc | undefined): string[] => {
                if (current === undefined) {
                    return [];
                }

                return current.attributes
                    .map((attribute: AttributeDesc): string => attribute.name)
                    .sort((lhs: string, rhs: string): number =>
                        lhs.localeCompare(rhs, undefined, { sensitivity: "base", numeric: true })
                    );
            })
        )
    );

    return (
        <div>
            <SelectionPropertyControl target={props.magnet.attribute} choices$={choices$} label="Attribute" />
            <SwitchPropertyControl target={props.magnet.mappingReverse} label="Invert" />
            <div className={classes.spacer} />
            <SliderPropertyControl
                target={props.magnet.magnitude}
                label="Magnitude"
                scale={10}
                min={-10}
                max={10}
                marks={[{ value: -10, label: "-" }, { value: 0, label: "0" }, { value: 10, label: "+" }]}
            />
        </div>
    );
};
