/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/member-ordering */

import { CssBaseline } from "@material-ui/core";
import React, { MouseEvent, PureComponent } from "react";
import { combineLatest, from, Observable, Subject } from "rxjs";
import { map, share, switchMap } from "rxjs/operators";

import { AttributeDesc, DatasetDesc } from "../api";
import { DmSession } from "../dmSession";
import { BasicObservableProperty } from "../observableProperty";
import { DmRenderer, PickResult } from "../renderer/dmRenderer";
import { DmRendererContext, DmSessionContext, Labeling, LabelingContext } from "./dmContext";
import { ContextMenuRequest, DmContextMenuProvider } from "./dmContextMenuProvider";
import { DmControls } from "./dmControls";
import { GlUnderlay } from "./glunderlay";
import { TooltipProvider, TooltipRequest } from "./tooltipProvider";

class LabelingProvider implements Labeling {
    public readonly particleNames$: Observable<string[]>;
    public readonly nameAttribute = new BasicObservableProperty<string | undefined>(undefined);
    public readonly nameAttributeChoices$: Observable<string[]>;

    public constructor(session: DmSession) {
        this.particleNames$ = combineLatest(session.dataset, this.nameAttribute.$).pipe(
            switchMap(
                ([dataset, attribute]: [DatasetDesc | undefined, string | undefined]): Observable<string[]> => {
                    return from(
                        (async (): Promise<string[]> => {
                            if (dataset === undefined) {
                                return [];
                            }

                            return session.remote.getRawAttributeData(
                                attribute === undefined ? dataset.attributes[0].name : attribute
                            );
                        })()
                    );
                }
            ),
            share()
        );

        this.nameAttributeChoices$ = session.dataset.pipe(
            map((current: DatasetDesc | undefined): string[] => {
                if (current === undefined) {
                    return [];
                }

                return current.attributes
                    .map((attribute: AttributeDesc): string => attribute.name)
                    .sort((lhs: string, rhs: string): number =>
                        lhs.localeCompare(rhs, undefined, { sensitivity: "base", numeric: true })
                    );
            }),
            share()
        );
    }
}

export interface DmClientProps {
    serverUrl: string;
}

export class DmClient extends PureComponent<DmClientProps> {
    private readonly _session: DmSession;
    private readonly _renderer: DmRenderer;
    private readonly _contextMenuRequests = new Subject<ContextMenuRequest>();
    private readonly _tooltipRequests = new Subject<TooltipRequest>();
    private readonly _labeling: LabelingProvider;

    public constructor(props: DmClientProps) {
        super(props);

        this._session = new DmSession(props.serverUrl);
        this._renderer = new DmRenderer(this._session.magnetController, this._session.particleController);
        this._renderer.particleHeights = this._session.heightMap.values$;
        this._renderer.particleColors = this._session.colorMap.values$;
        this._renderer.particleSchemeUrl = this._session.colorMap.schemeObjectUrl$;
        this._session.simulationController.radius.$.subscribe((radius: number): void => {
            this._renderer.set("particles.radius", radius);
        });

        this._labeling = new LabelingProvider(this._session);
    }

    public async componentDidMount(): Promise<void> {
        await this._session.connect();
        this._session.dataset.subscribe((dataset: DatasetDesc | undefined): void => {
            if (dataset === undefined) {
                document.title = "Dust & Magnet";
            } else {
                document.title = `[D&M] ${dataset.name}`;
            }
        });
    }

    // eslint-disable-next-line class-methods-use-this
    public componentWillUnmount(): void {
        // TODO: session.disconnect()
    }

    // eslint-disable-next-line class-methods-use-this
    private handleContextMenu(event: MouseEvent<HTMLDivElement>): void {
        const target = event.currentTarget;
        const rect = target.getBoundingClientRect();
        const position: [number, number] = [
            Math.floor(event.clientX - rect.left),
            Math.floor(event.clientY - rect.top),
        ];

        const pickResult: PickResult | undefined = this._renderer.pick(position);
        if (pickResult !== undefined) {
            if (pickResult.objectType === "magnet") {
                this._contextMenuRequests.next({
                    magnetId: pickResult.objectId,
                    anchorPosition: { left: event.clientX, top: event.clientY },
                });
            }
        }
        event.preventDefault();
    }

    private handleTooltip(position: [number, number]): void {
        const pickResult: PickResult | undefined = this._renderer.pick(position);
        if (pickResult !== undefined && pickResult.objectType === "particle") {
            this._tooltipRequests.next({
                anchorPosition: { left: position[0], top: position[1] },
                particleId: pickResult.objectId,
            });
        }
    }

    // eslint-disable-next-line class-methods-use-this
    public render(): JSX.Element {
        return (
            <DmSessionContext.Provider value={this._session}>
                <DmRendererContext.Provider value={this._renderer}>
                    <LabelingContext.Provider value={this._labeling}>
                        <CssBaseline />
                        <GlUnderlay
                            renderer={this._renderer}
                            onContextMenu={this.handleContextMenu.bind(this)}
                            onTooltip={this.handleTooltip.bind(this)}
                        >
                            <DmControls />
                            <TooltipProvider requests={this._tooltipRequests} />
                            <DmContextMenuProvider requests={this._contextMenuRequests} />
                        </GlUnderlay>
                    </LabelingContext.Provider>
                </DmRendererContext.Provider>
            </DmSessionContext.Provider>
        );
    }
}
