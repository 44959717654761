import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Color, FontFace, Framebuffer, Label, LabelRenderPass, Projected3DLabel, Text } from "webgl-operate";
import { GLfloat2, GLfloat3 } from "webgl-operate/lib/tuples";

import { assert, distinctUntilAnyKeyChanged } from "../../helpers";
import { MagnetController } from "../../magnetController";
import { MagnetSetController } from "../../magnetSetController";
import { Config } from "../dmRenderer";
import { RenderPass, RenderPassParameters } from "../renderPass";

export class LabelPass extends RenderPass {
    private readonly _labelPass: LabelRenderPass;
    private readonly _offset: Observable<GLfloat>;
    private _fontFace?: FontFace;
    private readonly _labels: Map<number, Projected3DLabel> = new Map();

    public constructor(magnetController: MagnetSetController, baseParameters: RenderPassParameters) {
        super(baseParameters, "Label");

        this._labelPass = new LabelRenderPass(this._context);
        this._labelPass.initialize();
        this._labelPass.depthMask = false;
        this._labelPass.camera = this._camera;

        FontFace.fromFile("./data/fonts/opensans2048p160d16.fnt", this._context)
            .then((fontFace): void => {
                this._fontFace = fontFace;
                for (const label of this._labelPass.labels) {
                    label.fontFace = fontFace;
                }
                this.redraw$.next(false);
            })
            .catch((reason: string): void => console.error(`Failed to load font face: ${reason}`));

        magnetController.magnets$.subscribe(this.observeMagnet);

        this._offset = this._config$.pipe(
            distinctUntilAnyKeyChanged(["magnets.height", "magnets.offset"]),
            map((config: Config): GLfloat => config["magnets.height"] + config["magnets.offset"] + 0.02)
        );
    }

    public set framebuffer(framebuffer: Framebuffer) {
        this._labelPass.target = framebuffer;
    }

    protected onRelease(): void {
        this._labelPass.uninitialize();
    }

    protected onUpdate(): void {
        this._labelPass.update();
    }

    protected onFrame(): void {
        this._labelPass.frame();
    }

    private readonly observeMagnet = (magnet: MagnetController): void => {
        assert(!this._labels.has(magnet.id), "Duplicate magnet id");

        // Create label for new magnet
        const label = new Projected3DLabel(new Text(""), Label.Type.Dynamic, this._fontFace);
        label.color = new Color([0, 0, 0]);
        this._labels.set(magnet.id, label);
        this._labelPass.labels = Array.from(this._labels.values());

        // Subscribe to attribute changes (=> label text) && completion (=> remove label)
        magnet.attribute.$.pipe(this.tapRedraw()).subscribe(
            (attribute: string): void => {
                label.text = new Text(attribute);
            },
            undefined,
            (): void => {
                this._labels.delete(magnet.id);
                this._labelPass.labels = Array.from(this._labels.values());
                this.redraw$.next(false);
            }
        );

        // Subscribe to position changes (=> label position)
        combineLatest(
            magnet.position.$,
            this._offset,
            (position: GLfloat2, offset: GLfloat): GLfloat3 => [position[0], offset, position[1]]
        )
            .pipe(this.tapRedraw())
            .subscribe((position: GLfloat3): void => {
                label.position = position;
            });
    };
}
