import { combineLatest } from "rxjs";
import { Program, VertexArray } from "webgl-operate";

import { RenderPass, RenderPassParameters } from "../renderPass";
import { ParticleSharedState } from "./particlePassGroup";
import fragmentShaderSource from "./particleShadowPass.frag";
import vertexShaderSource from "./particleShadowPass.vert";

export class ParticleShadowPass extends RenderPass {
    private readonly _shared: ParticleSharedState;
    private readonly _vertexArray: VertexArray;
    private readonly _program: Program;
    private _instanceCount: GLsizei = 0;

    public constructor(shared: ParticleSharedState, baseParameters: RenderPassParameters) {
        super(baseParameters, "ParticleShadow");
        this._shared = shared;

        const gl = this._context.gl as WebGLRenderingContext;

        // Set up shader
        this._program = this.loadShader(vertexShaderSource, fragmentShaderSource, ["in_vertex", "in_height"]);
        this._program.bind();
        gl.uniform1i(this._program.uniform("positionTexture"), 0);

        this._vertexArray = this.createVertexArray([
            { buffer: shared.vertexBuffer, size: 3, type: gl.FLOAT },
            { buffer: shared.heightBuffer, size: 1, type: gl.FLOAT, divisor: 1 },
        ]);

        this.createSubscriptions();
    }

    protected onRelease(): void {
        this._vertexArray.uninitialize();
        this._program.uninitialize();
    }

    protected onFrame(): void {
        const gl = this._context.gl as WebGLRenderingContext;
        const gl2 = this._context.gl2facade;

        gl.enable(gl.DEPTH_TEST);

        this._program.bind();
        this._vertexArray.bind();

        gl2.drawArraysInstanced(gl.TRIANGLE_STRIP, 0, this._shared.vertexCount, this._instanceCount);

        gl.disable(gl.DEPTH_TEST);
    }

    private createSubscriptions(): void {
        this.subscribeTexture(this._shared.positionTexture$, 0);
        this.subscribeUniform1i(this._shared.positionTextureSize$, this._program, "positionTextureSize");
        this.subscribeUniform1f(this.config$("particles.heightScale"), this._program, "heightScale");
        this.subscribeUniform1f(this.config$("particles.heightBase"), this._program, "heightBase");
        this.subscribeUniform1f(this.config$("particles.radius"), this._program, "radius");

        this.subscribeUniformMat4(this._light.viewProjection$, this._program, "viewProjection");
        this.subscribeUniform3f(this._light.eye$, this._program, "eye");
        this.subscribeUniform2f(this._light.nearFar$, this._program, "nearFar");
        this.subscribeUniform2f(
            combineLatest(this.config$("shadow.positiveExponent"), this.config$("shadow.negativeExponent")),
            this._program,
            "exponents"
        );

        this._shared.instanceCount$.pipe(this.tapRedraw()).subscribe((instanceCount: GLsizei): void => {
            this._instanceCount = instanceCount;
        });
    }
}
