import { makeStyles, Paper, Typography } from "@material-ui/core";
import { createStyles, StyleRules, Theme } from "@material-ui/core/styles";
import React, { FunctionComponent, useContext, useState } from "react";
import { Observable } from "rxjs";

import { AddMagnet } from "./addMagnet";
import { DmAppBar } from "./dmAppBar";
import { DmSessionContext } from "./dmContext";
import { DmDrawer } from "./dmDrawer";
import { ImportDatasetDialog } from "./importDatasetDialog";

const useStyles = makeStyles(
    (theme: Theme): StyleRules =>
        createStyles({
            helpBar: {
                position: "fixed",
                bottom: 0,
                padding: theme.spacing(1),
                width: "100%",
                background: "rgba(255, 255, 255, 0.7)",
            },
        })
);

export interface DmControlsProps {
    nameAttribute$: Observable<string | undefined>;
}

export const DmControls: FunctionComponent = (): JSX.Element => {
    const classes = useStyles();
    const session = useContext(DmSessionContext);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [importDialogOpen, setImportDialogOpen] = useState(false);

    const toggleDrawer = (): void => {
        setDrawerOpen(!drawerOpen);
    };

    const handleCloseDataset = (): void => {
        session.closeDataset().catch(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (reason: any): void => {
                console.log(`Failed to close dataset: ${reason}`);
            }
        );
    };

    return (
        <>
            <DmAppBar
                toggleDrawer={toggleDrawer}
                onImportDataset={(): void => setImportDialogOpen(true)}
                onCloseDataset={handleCloseDataset}
            />
            <DmDrawer open={drawerOpen} />
            <Paper className={classes.helpBar} square>
                <Typography>
                    Pan: ctrl/cmd + mouse left &mdash; Rotate: ctrl/cmd + shift + mouse left &mdash; Zoom: mouse wheel
                    &mdash; Context menu: mouse right
                </Typography>
            </Paper>
            <AddMagnet />
            <ImportDatasetDialog open={importDialogOpen} onClose={(): void => setImportDialogOpen(false)} />
        </>
    );
};
