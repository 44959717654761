import { combineLatest } from "rxjs";
import { pluck } from "rxjs/operators";
import { Program, VertexArray } from "webgl-operate";

import { RenderPass, RenderPassParameters } from "../renderPass";
import fragmentShaderSource from "./magnetAnchorShadowPass.frag";
import vertexShaderSource from "./magnetAnchorShadowPass.vert";
import { MagnetSharedState } from "./magnetPassGroup";

export class MagnetAnchorShadowPass extends RenderPass {
    private readonly _shared: MagnetSharedState;
    private readonly _vertexArray: VertexArray;
    private readonly _program: Program;

    public constructor(shared: MagnetSharedState, baseParameters: RenderPassParameters) {
        super(baseParameters, "MagnetAnchorShadow");
        this._shared = shared;

        const gl = this._context.gl as WebGLRenderingContext;

        this._program = this.loadShader(vertexShaderSource, fragmentShaderSource, ["in_vertex", "in_position"]);
        this._vertexArray = this.createVertexArray([
            { buffer: shared.anchorVertexBuffer, size: 2, type: gl.FLOAT },
            { buffer: shared.instanceBuffer, size: 2, type: gl.FLOAT, divisor: 1 },
        ]);

        this.createSubscriptions();
    }

    protected onRelease(): void {
        this._vertexArray.uninitialize();
        this._program.uninitialize();
    }

    protected onFrame(): void {
        if (this._shared.instanceCount === 0) {
            return;
        }

        const gl = this._context.gl as WebGLRenderingContext;
        const gl2 = this._context.gl2facade;

        gl.enable(gl.DEPTH_TEST);

        this._program.bind();

        // Bind vertex array & draw
        this._vertexArray.bind();
        gl2.drawArraysInstanced(gl.TRIANGLE_STRIP, 0, this._shared.anchorVertexCount, this._shared.instanceCount);

        gl.disable(gl.DEPTH_TEST);
    }

    private createSubscriptions(): void {
        this.subscribeUniform1f(this.config$("magnets.offset"), this._program, "height");
        this.subscribeUniform1f(this.config$("magnets.anchorWidth"), this._program, "width");

        this.subscribeUniformMat4(this._light.viewProjection$, this._program, "viewProjection");
        this.subscribeUniform3f(this._light.eye$, this._program, "eye");
        this.subscribeUniform2f(this._light.nearFar$, this._program, "nearFar");
        this.subscribeUniform2f(
            combineLatest(this.config$("shadow.positiveExponent"), this.config$("shadow.negativeExponent")),
            this._program,
            "exponents"
        );
        this.subscribeUniform1f(this._light.inverseViewport$.pipe(pluck(0)), this._program, "pixelWidth");
    }
}
