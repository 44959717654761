import { combineLatest } from "rxjs";
import { Program, Texture2D, VertexArray, Wizard } from "webgl-operate";

import { RenderPass, RenderPassParameters } from "../renderPass";
import { GroundSharedState } from "./groundPassGroup";
import fragmentShaderSource from "./groundScenePass.frag";
import vertexShaderSource from "./groundScenePass.vert";

export class GroundScenePass extends RenderPass {
    private readonly _shared: GroundSharedState;
    private readonly _normalTexture: Texture2D;
    private readonly _vertexArray: VertexArray;
    private readonly _program: Program;

    public constructor(shared: GroundSharedState, baseParameters: RenderPassParameters) {
        super(baseParameters, "GroundScene");
        this._shared = shared;

        const gl = this._context.gl as WebGLRenderingContext;

        // Set up shader
        this._program = this.loadShader(vertexShaderSource, fragmentShaderSource, [
            "in_position",
            "in_tangent",
            "in_bitangent",
            "in_normal",
        ]);
        this._program.bind();
        gl.uniform1i(this._program.uniform("normalMap"), 0);
        gl.uniform1i(this._program.uniform("reflectionMap"), 1);
        gl.uniform1i(this._program.uniform("shadowMap"), 2);

        this._vertexArray = this.createVertexArray([
            { buffer: this._shared.vertexBuffer, size: 3, type: gl.FLOAT, stride: 4 * 12, offset: 0 },
            { buffer: this._shared.vertexBuffer, size: 3, type: gl.FLOAT, stride: 4 * 12, offset: 4 * 3 },
            { buffer: this._shared.vertexBuffer, size: 3, type: gl.FLOAT, stride: 4 * 12, offset: 4 * 6 },
            { buffer: this._shared.vertexBuffer, size: 3, type: gl.FLOAT, stride: 4 * 12, offset: 4 * 9 },
        ]);
        this._normalTexture = this.loadNormalMap();

        this._textures[0] = this._normalTexture;

        this.createSubscriptions();
    }

    protected onRelease(): void {
        this._normalTexture.uninitialize();
        this._vertexArray.uninitialize();
        this._program.uninitialize();
    }

    protected onFrame(): void {
        const gl = this._context.gl as WebGLRenderingContext;

        gl.enable(gl.DEPTH_TEST);

        this._program.bind();
        this._vertexArray.bind();

        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);

        gl.disable(gl.DEPTH_TEST);
    }

    private loadNormalMap(): Texture2D {
        const gl = this._context.gl as WebGLRenderingContext;

        const formatType: [GLenum, GLenum, Wizard.Precision] = Wizard.queryInternalTextureFormat(
            this._context,
            gl.RGB,
            Wizard.Precision.byte
        );
        const normalTexture = new Texture2D(this._context, `${this.name}.normalTexture`);
        normalTexture.initialize(1, 1, formatType[0], gl.RGB, formatType[1]);
        normalTexture.data(new Uint8Array([127, 127, 255])); // Fallback data: 1x1 px normal [0, 0, 1]
        normalTexture.bind();
        normalTexture.filter(gl.LINEAR, gl.LINEAR_MIPMAP_LINEAR, false, false);
        normalTexture.wrap(gl.REPEAT, gl.REPEAT, false, false);
        normalTexture.fetch("data/textures/brushed-metal.png").then(
            (): void => {
                normalTexture.bind();
                gl.generateMipmap(gl.TEXTURE_2D);

                this.redraw$.next(false);
            },
            (reason: string): void => {
                console.error(`Failed to load normal map: ${reason}`);
            }
        );

        return normalTexture;
    }

    private createSubscriptions(): void {
        this.subscribeUniformMat4(this._camera.viewProjection$, this._program, "viewProjection");
        this.subscribeUniform3f(this._camera.eye$, this._program, "eye");
        this.subscribeUniform2f(this._camera.viewport$, this._program, "viewport");
        this.subscribeUniform3f(this._light.position$, this._program, "lightPosition");

        this.subscribeTexture(this._shared.reflectionMap$, 1);
        this.subscribeUniform1i(this._shared.reflectionEnabled$, this._program, "reflectionEnabled");

        this.subscribeTexture(this._shared.shadowMap$, 2);
        this.subscribeUniform1i(this._shared.shadowEnabled$, this._program, "shadowEnabled");
        this.subscribeUniform2f(
            combineLatest(this.config$("shadow.positiveExponent"), this.config$("shadow.negativeExponent")),
            this._program,
            "shadowExponents"
        );
        this.subscribeUniform1f(this.config$("shadow.bleedingReduction"), this._program, "shadowBleedingReduction");
        this.subscribeUniformMat4(this._light.viewProjection$, this._program, "shadowViewProjection");
        this.subscribeUniform2f(this._light.nearFar$, this._program, "shadowNearFar");
    }
}
