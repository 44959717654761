import { NdcFillingTriangle, Program, Texture2D } from "webgl-operate";

import { assertExists } from "../../helpers";
import { RenderPass, RenderPassParameters } from "../renderPass";
import fragmentShaderSource from "./blitPass.frag";
import vertexShaderSource from "./blitPass.vert";

export class BlitPass extends RenderPass {
    public colorTexture?: Texture2D;
    public depthTexture?: Texture2D;
    public objectIdTexture?: Texture2D;
    public postColorTexture?: Texture2D;

    private readonly _program: Program;
    private readonly _ndcTriangle: NdcFillingTriangle;

    public constructor(baseParameters: RenderPassParameters) {
        super(baseParameters, "Blit");

        const gl = this._context.gl as WebGLRenderingContext;

        this._program = this.loadShader(vertexShaderSource, fragmentShaderSource);
        this._program.bind();
        gl.uniform1i(this._program.uniform("colorTexture"), 0);
        gl.uniform1i(this._program.uniform("depthTexture"), 1);
        gl.uniform1i(this._program.uniform("objectIdTexture"), 2);
        gl.uniform1i(this._program.uniform("postColorTexture"), 3);

        this._ndcTriangle = new NdcFillingTriangle(this._context, `${this.name}.geometry`);
        this._ndcTriangle.initialize(this._program.attribute("in_position"));
    }

    protected onRelease(): void {
        this._program.uninitialize();
        this._ndcTriangle.uninitialize();
    }

    protected onFrame(): void {
        const gl = this._context.gl as WebGLRenderingContext;

        this._program.bind();
        assertExists(this.colorTexture).bind(gl.TEXTURE0);
        assertExists(this.depthTexture).bind(gl.TEXTURE1);
        assertExists(this.objectIdTexture).bind(gl.TEXTURE2);
        assertExists(this.postColorTexture).bind(gl.TEXTURE3);
        this._ndcTriangle.bind();
        this._ndcTriangle.draw();
    }
}
