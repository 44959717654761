import { createStyles, makeStyles, MenuItem, Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import React, { CSSProperties, FunctionComponent, PropsWithChildren } from "react";
import { of } from "rxjs";

import { ColorMap } from "../colorMap";
import { ControlGroup } from "./controlGroup";
import { SelectionPropertyControl } from "./selectionPropertyControl";
import { SwitchPropertyControl } from "./switchPropertyControl";
import { useObservable, useObservableProperty } from "./useObservable";

const useStyles = makeStyles(
    (theme: Theme): StyleRules<string, { url: string }> =>
        createStyles({
            input: {
                backgroundImage: ({ url }: { url: string }): string => (url === undefined ? "none" : `url(${url})`),
                backgroundSize: "100%",
                color: theme.palette.common.white,
            },
        })
);

const useLabelStyles = makeStyles(
    (theme: Theme): StyleRules =>
        createStyles({
            root: {
                color: "#CCCCCC",
                "&$focused": {
                    color: theme.palette.primary.light,
                },
            },
            focused: {},
        })
);

const itemStyle = (schemeUrl: string | undefined): CSSProperties => {
    if (schemeUrl === undefined) {
        return {};
    }

    return {
        backgroundColor: "none",
        backgroundImage: `url(${schemeUrl})`,
        backgroundSize: "100%",
        color: "white",
        transition: "unset",
    };
};

export interface ColorMapControlProps {
    map: ColorMap;
    disableGutter?: boolean;
}

// eslint-disable-next-line max-lines-per-function
export const ColorMapControl: FunctionComponent<ColorMapControlProps> = (
    props: PropsWithChildren<ColorMapControlProps>
): JSX.Element | null => {
    const [scheme] = useObservableProperty(props.map.scheme);
    const [enabled, setEnabled] = useObservableProperty(props.map.enabled);
    const schemeObjectUrls = useObservable(props.map.schemeObjectUrls$, new Map());
    const classes = useStyles({ url: schemeObjectUrls.get(scheme) });
    const labelClasses = useLabelStyles();

    const schemeItemGenerator = (choice: string): JSX.Element => (
        <MenuItem key={choice} style={itemStyle(schemeObjectUrls.get(choice))} value={choice}>
            {choice}
        </MenuItem>
    );

    return (
        <ControlGroup title="Color Mapping" checked={enabled} onChange={setEnabled} disableGutter={props.disableGutter}>
            <SelectionPropertyControl target={props.map.attribute} choices$={props.map.choices$} label="Attribute" />
            <SelectionPropertyControl
                target={props.map.scheme}
                choices$={of(ColorMap.schemes)}
                label="Scheme"
                InputProps={{
                    className: classes.input,
                }}
                InputLabelProps={{
                    classes: labelClasses,
                }}
                itemGenerator={schemeItemGenerator}
            />
            <SwitchPropertyControl target={props.map.invert} label="Invert" />
        </ControlGroup>
    );
};
