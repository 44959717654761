import React, { FunctionComponent, PropsWithChildren } from "react";

import { SimulationControlAdapter } from "../simulationControlAdapter";
import { ControlGroup } from "./controlGroup";
import { SliderPropertyControl } from "./sliderPropertyControl";

export interface SimulationControlProps {
    adapter: SimulationControlAdapter;
    disableGutter?: boolean;
}

export const SimulationControl: FunctionComponent<SimulationControlProps> = (
    props: PropsWithChildren<SimulationControlProps>
): JSX.Element | null => {
    return (
        <ControlGroup title="Simulation" disableGutter={props.disableGutter}>
            <SliderPropertyControl
                target={props.adapter.radius}
                label="Radius"
                min={1}
                max={50}
                step={1}
                scale={1000}
            />
            <SliderPropertyControl
                target={props.adapter.amplification}
                label="Amplification"
                min={1}
                max={10}
                step={0.1}
                valueLabelDecimalPlaces={1}
            />
        </ControlGroup>
    );
};
