import { makeStyles } from "@material-ui/core";
import React, { FunctionComponent, MouseEvent, MouseEventHandler, PropsWithChildren, useEffect, useState } from "react";
import { combineLatest, Subject } from "rxjs";
import { debounceTime, filter, map } from "rxjs/operators";
import { Renderer } from "webgl-operate";

import { GlCanvas } from "./glcanvas";

const useStyles = makeStyles({
    root: {
        height: "100%",
        width: "100%",
    },
    overlay: {
        position: "absolute",
        width: "100%",
        zIndex: 2,
    },
    underlay: {
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: 1,
    },
});

export interface GlUnderlayProps {
    renderer: Renderer;
    onClick?: MouseEventHandler<Element>;
    onTooltip?: (position: [number, number]) => void;
    onContextMenu?: MouseEventHandler<Element>;
    tooltipDelay?: number;
}

export const GlUnderlay: FunctionComponent<GlUnderlayProps> = (
    props: PropsWithChildren<GlUnderlayProps>
): JSX.Element => {
    const classes = useStyles();
    const [positions$] = useState(new Subject<[number, number]>());
    const [underMouse$] = useState(new Subject<boolean>());

    if (props.onTooltip !== undefined) {
        useEffect((): (() => void) => {
            const subscription = combineLatest(positions$, underMouse$)
                .pipe(
                    debounceTime(props.tooltipDelay === undefined ? 500 : props.tooltipDelay),
                    filter(([_position, underMouse]: [[number, number], boolean]): boolean => underMouse),
                    map(([position, _underMouse]: [[number, number], boolean]): [number, number] => position)
                )
                .subscribe(props.onTooltip);
            return (): void => {
                subscription.unsubscribe();
            };
        }, [props.onTooltip, props.tooltipDelay, positions$, underMouse$]);
    }

    function handleMove(event: MouseEvent<Element>): void {
        const target = event.currentTarget;
        const rect = target.getBoundingClientRect();
        const position: [number, number] = [
            Math.floor(event.clientX - rect.left),
            Math.floor(event.clientY - rect.top),
        ];

        positions$.next(position);
    }

    return (
        <div className={classes.root}>
            <div
                className={classes.underlay}
                onClick={props.onClick}
                onContextMenu={props.onContextMenu}
                onMouseMove={handleMove}
                onMouseOver={(): void => underMouse$.next(true)}
                onMouseOut={(): void => underMouse$.next(false)}
            >
                <GlCanvas renderer={props.renderer} />
            </div>
            <div className={classes.overlay}>{props.children}</div>
        </div>
    );
};
