/* eslint-disable max-lines */

import { distinctUntilChangedImmutable } from "distinct-until-changed-immutable";
import { merge, MonoTypeOperatorFunction, Observable, Subject } from "rxjs";
import { pluck } from "rxjs/operators";
import {
    Color,
    Context,
    DefaultFramebuffer,
    Framebuffer,
    MouseEventProvider,
    ReadbackPass,
    Texture2D,
    TouchEventProvider,
    vec2,
    vec3,
} from "webgl-operate";
import { GLfloat2, GLsizei2 } from "webgl-operate/lib/tuples";

import { MagnetSetController } from "../magnetSetController";
import { tapRedraw } from "../operators";
import { ParticleController } from "../particleController";
import { BlitPass } from "./blit/blitPass";
import { Camera } from "./camera";
import { Config, RendererConfig } from "./dmRenderer";
import { createDepthBuffer, createRgbaBuffer } from "./glHelpers";
import { GroundPassGroup } from "./ground/groundPassGroup";
import { Interaction } from "./interaction";
import { LabelPass } from "./labels/labelPass";
import { Light } from "./light";
import { MagnetPassGroup } from "./magnets/magnetPassGroup";
import { Navigation } from "./navigation";
import { ObjectPicker, PickResult } from "./objectPicker";
import { ParticlePassGroup } from "./particles/particlePassGroup";
import { PositionIntegrationPass } from "./position-integration/positionIntegrationPass";
import { PostProcessingProvider } from "./postProcessingProvider";
import { ReflectionProvider } from "./reflectionProvider";
import { RenderPass } from "./renderPass";
import { ShadowProvider } from "./shadowProvider";

function createCamera(): Camera {
    const camera = new Camera();
    camera.eye = vec3.fromValues(0, 1.7, 1.9);
    camera.center = vec3.fromValues(0, 0, 0.2);
    camera.up = vec3.fromValues(0, 1, 0);
    camera.near = 0.01;
    camera.far = 11;

    return camera;
}

function createLight(): Light {
    const light = new Light();
    light.position = vec3.fromValues(-3.0, 6.0, 4.5);
    light.color = new Color([1, 1, 1], 1);
    light.near = 6.0;
    light.far = 15.0;
    light.fovy = 20.0;
    light.viewport = [1024, 1024];
    light.aspect = 1.0;

    return light;
}

function createReadbackPass(context: Context, framebuffer: Framebuffer): ReadbackPass {
    const gl = context.gl as WebGLRenderingContext;
    const gl2 = context.gl2facade;

    const readbackPass = new ReadbackPass(context);
    readbackPass.initialize(undefined, false);
    readbackPass.depthFBO = framebuffer;
    readbackPass.depthAttachment = gl.DEPTH_ATTACHMENT;
    readbackPass.idFBO = framebuffer;
    readbackPass.idAttachment = gl2.COLOR_ATTACHMENT1;

    return readbackPass;
}

export class DmRendererImplementation {
    public readonly redraw$: Subject<boolean> = new Subject();

    private readonly context: Context;

    // Scene framebuffer
    private readonly sceneColorBuffer: Texture2D;
    private readonly sceneObjectIdBuffer: Texture2D;
    private readonly sceneDepthBuffer: Texture2D;
    private readonly sceneFramebuffer: Framebuffer;

    private readonly defaultFramebuffer: DefaultFramebuffer;

    // Render passes
    private readonly particlePassGroup: ParticlePassGroup;
    private readonly groundPassGroup: GroundPassGroup;
    private readonly magnetPassGroup: MagnetPassGroup;
    private readonly labelPass: LabelPass;
    private readonly readbackPass: ReadbackPass;
    private readonly blitPass: BlitPass;
    private readonly positionIntegrationPass: PositionIntegrationPass;

    private readonly reflectionProvider: ReflectionProvider;
    private readonly shadowProvider: ShadowProvider;
    private readonly postProcessingProvider: PostProcessingProvider;

    // Camera and navigation
    private readonly camera: Camera;
    private readonly light: Light;
    private readonly navigation: Navigation;
    private readonly interaction: Interaction;
    private readonly objectPicker: ObjectPicker;

    private _rendererConfig: RendererConfig = { frameSize: [0, 0], canvasSize: [0, 0], clearColor: [0, 0, 0, 1] };
    private readonly _rendererConfig$: Observable<RendererConfig>;
    private readonly _config$: Observable<Config>;

    // eslint-disable-next-line max-params, max-lines-per-function, max-statements
    public constructor(
        context: Context,
        mouseEventProvider: MouseEventProvider,
        touchEventProvider: TouchEventProvider,
        config$: Observable<Config>,
        rendererConfig$: Observable<RendererConfig>,
        magnetController: MagnetSetController,
        particleController: ParticleController
    ) {
        this.context = context;
        this._config$ = config$;
        this._rendererConfig$ = rendererConfig$;

        context.enable([
            "ANGLE_instanced_arrays",
            "WEBGL_color_buffer_float",
            "OES_texture_float",
            "OES_texture_float_linear",
            "OES_standard_derivatives",
        ]);

        const gl = context.gl as WebGLRenderingContext;
        const gl2 = context.gl2facade;

        // Set global state
        gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);

        this.camera = createCamera();
        this.light = createLight();

        this.sceneColorBuffer = createRgbaBuffer(context, "sceneColor", gl.LINEAR);
        this.sceneObjectIdBuffer = createRgbaBuffer(context, "sceneObjectId");
        this.sceneDepthBuffer = createDepthBuffer(context, "sceneDepth");
        this.sceneFramebuffer = new Framebuffer(context, "sceneFramebuffer");
        this.sceneFramebuffer.initialize([
            [gl.COLOR_ATTACHMENT0, this.sceneColorBuffer],
            [gl2.COLOR_ATTACHMENT1, this.sceneObjectIdBuffer],
            [gl.DEPTH_ATTACHMENT, this.sceneDepthBuffer],
        ]);

        this.defaultFramebuffer = new DefaultFramebuffer(context, "defaultFramebuffer");
        this.defaultFramebuffer.initialize();

        const passParameters = { context, camera: this.camera, light: this.light, config$ };

        this.shadowProvider = new ShadowProvider(context, this.config$.bind(this));
        this.reflectionProvider = new ReflectionProvider(
            context,
            this.config$.bind(this),
            this.rendererConfig$.bind(this)
        );
        this.postProcessingProvider = new PostProcessingProvider(
            this.sceneColorBuffer,
            passParameters,
            context,
            this.config$.bind(this),
            this.rendererConfig$.bind(this)
        );

        this.positionIntegrationPass = new PositionIntegrationPass(context, particleController);

        this.particlePassGroup = new ParticlePassGroup(particleController, passParameters);
        this.particlePassGroup.positionTexture$.src = this.positionIntegrationPass.integratedPositionTexture$;
        this.particlePassGroup.shadowMap$.src = this.shadowProvider.shadowMap$;

        this.groundPassGroup = new GroundPassGroup(passParameters);
        this.groundPassGroup.reflectionMap$.src = this.reflectionProvider.reflectionMap$;
        this.groundPassGroup.shadowMap$.src = this.shadowProvider.shadowMap$;
        this.magnetPassGroup = new MagnetPassGroup(magnetController, passParameters);

        this.labelPass = new LabelPass(magnetController, passParameters);

        this.readbackPass = createReadbackPass(context, this.sceneFramebuffer);

        this.blitPass = new BlitPass(passParameters);
        this.blitPass.colorTexture = this.sceneColorBuffer;
        this.blitPass.depthTexture = this.sceneDepthBuffer;
        this.blitPass.objectIdTexture = this.sceneObjectIdBuffer;

        this.postProcessingProvider.colorBuffer$.subscribe((colorBuffer: Texture2D): void => {
            const labelFramebuffer = new Framebuffer(context, "labelFramebuffer");
            labelFramebuffer.initialize([
                [gl.COLOR_ATTACHMENT0, colorBuffer],
                [gl.DEPTH_ATTACHMENT, this.sceneDepthBuffer],
            ]);
            this.labelPass.framebuffer = labelFramebuffer;
            this.blitPass.postColorTexture = colorBuffer;
        }, console.error.bind(console));

        merge(
            this.particlePassGroup.redraw$,
            this.groundPassGroup.redraw$,
            this.magnetPassGroup.redraw$,
            this.labelPass.redraw$,
            this.positionIntegrationPass.redraw$
        ).subscribe(this.redraw$);

        const invalidate = this.redraw$.next.bind(this.redraw$);
        this.objectPicker = new ObjectPicker(this.readbackPass, this.magnetPassGroup);
        this.navigation = new Navigation(invalidate, mouseEventProvider, touchEventProvider, this.camera);
        this.interaction = new Interaction(
            magnetController,
            invalidate,
            mouseEventProvider,
            touchEventProvider,
            this.objectPicker,
            this.camera
        );

        rendererConfig$.subscribe((next: RendererConfig): void => {
            this._rendererConfig = next;
        });

        // Resize
        this.rendererConfig$("canvasSize").subscribe((canvasSize: GLsizei2): void => {
            this.camera.aspect = canvasSize[0] / canvasSize[1];
        });
        this.rendererConfig$("frameSize").subscribe((frameSize: GLsizei2): void => {
            this.camera.viewport = frameSize;
            this.sceneFramebuffer.resize(...frameSize);
        });
    }

    public release(): void {
        for (const pass of this.renderPasses) {
            pass.release();
        }
        this.readbackPass.uninitialize();
        this.positionIntegrationPass.release();
        this.sceneFramebuffer.uninitialize();
        this.sceneDepthBuffer.uninitialize();
        this.sceneColorBuffer.uninitialize();
        this.reflectionProvider.release();
        this.shadowProvider.release();
        this.postProcessingProvider.release();
    }

    public get renderPasses(): RenderPass[] {
        const passes: RenderPass[] = [
            this.particlePassGroup,
            this.magnetPassGroup,
            this.groundPassGroup,
            this.labelPass,
            this.blitPass,
        ];
        return passes;
    }

    public set particleHeights$(heights: Observable<Float32Array | undefined> | undefined) {
        this.particlePassGroup.heights$.src = heights;
    }

    public set particleColors$(colors: Observable<Float32Array | undefined> | undefined) {
        this.particlePassGroup.colors$.src = colors;
    }

    public set particleSchemeUrl$(url: Observable<string | undefined> | undefined) {
        this.particlePassGroup.schemeUrl$.src = url;
    }

    public pick(viewportPosition: GLfloat2 | vec2): PickResult | undefined {
        return this.objectPicker.pick(viewportPosition);
    }

    public tapRedraw<T>(force: boolean = false): MonoTypeOperatorFunction<T> {
        return tapRedraw(this.redraw$, force);
    }

    public update(): void {
        // Update camera navigation (process events)
        this.navigation.update();
        this.interaction.update();

        // Update render passes
        for (const pass of this.renderPasses) {
            pass.update();
        }
    }

    public prepare(): void {
        if (this.positionIntegrationPass.redrawRequested) {
            this.positionIntegrationPass.process();
        }

        this.postProcessingProvider.prepare();

        for (const pass of this.renderPasses) {
            pass.prepare();
        }

        // Reset altered state
        this.camera.altered = false;
    }

    public frame(): void {
        const gl = this.context.gl as WebGLRenderingContext;

        this.renderShadows();
        this.resetTextureBindings();

        gl.viewport(0, 0, ...this._rendererConfig.frameSize);

        this.resetTextureBindings();
        this.renderReflections();

        this.resetTextureBindings();
        this.renderScene();

        this.resetTextureBindings();
        this.renderPostProcessing();

        this.resetTextureBindings();
        this.renderLabels();

        // Bookkeeping
        this.readbackPass.frame();
    }

    public swap(): void {
        this.defaultFramebuffer.bind();
        this.blitPass.frame();
    }

    private config$<K extends keyof Config>(property: K): Observable<Config[K]> {
        return this._config$.pipe(
            pluck(property),
            distinctUntilChangedImmutable(),
            this.tapRedraw()
        );
    }

    private rendererConfig$<K extends keyof RendererConfig>(property: K): Observable<RendererConfig[K]> {
        return this._rendererConfig$.pipe(
            pluck(property),
            distinctUntilChangedImmutable(),
            this.tapRedraw()
        );
    }

    private renderShadows(): void {
        this.shadowProvider.frame((): void => {
            this.particlePassGroup.shadowPass.frame();
            this.magnetPassGroup.topShadowPass.frame();
            this.magnetPassGroup.anchorShadowPass.frame();
            this.groundPassGroup.shadowPass.frame();
        });
    }

    private renderReflections(): void {
        this.reflectionProvider.frame((): void => {
            this.particlePassGroup.reflectionPass.frame();
            this.magnetPassGroup.anchorReflectionPass.frame();
        });
    }

    private renderScene(): void {
        this.sceneFramebuffer.bind();
        if (this.context.backend === Context.BackendType.WebGL2) {
            this.clearBuffersGl2();
        } else {
            this.clearBuffersGl1();
        }

        this.particlePassGroup.scenePass.frame();
        this.groundPassGroup.scenePass.frame();
        this.magnetPassGroup.anchorScenePass.frame();
        this.magnetPassGroup.topScenePass.frame();
    }

    private renderPostProcessing(): void {
        this.postProcessingProvider.frame();
    }

    private renderLabels(): void {
        this.labelPass.frame();
    }

    private resetTextureBindings(): void {
        const gl = this.context.gl as WebGLRenderingContext;
        gl.activeTexture(gl.TEXTURE0);
        gl.bindTexture(gl.TEXTURE_2D, null);
        gl.activeTexture(gl.TEXTURE1);
        gl.bindTexture(gl.TEXTURE_2D, null);
        gl.activeTexture(gl.TEXTURE2);
        gl.bindTexture(gl.TEXTURE_2D, null);
    }

    private clearBuffersGl1(): void {
        /*
         * There is not clearBufferfv equivalent in WebGL 1 or any of its extensions, therefore we must emulate
         * per-buffer clearing by switching clearColor and drawBuffers
         */
        const gl = this.context.gl as WebGLRenderingContext;
        const gl2 = this.context.gl2facade;
        if (gl2.drawBuffers === undefined) {
            throw new TypeError("gl.drawBuffers is not supported");
        }

        // Clear color buffer
        gl.clearColor(...this._rendererConfig.clearColor);
        gl2.drawBuffers([gl2.COLOR_ATTACHMENT0, gl.NONE]);
        gl.clear(gl.COLOR_BUFFER_BIT);

        // Clear object id buffer
        gl.clearColor(1, 1, 1, 1);
        gl2.drawBuffers([gl.NONE, gl2.COLOR_ATTACHMENT1]);
        gl.clear(gl.COLOR_BUFFER_BIT);

        // Clear depth buffer
        gl.clearDepth(1);
        gl.clear(gl.DEPTH_BUFFER_BIT);

        // Reset draw buffers to render to color & object id buffers
        gl2.drawBuffers([gl2.COLOR_ATTACHMENT0, gl2.COLOR_ATTACHMENT1]);
    }

    private clearBuffersGl2(): void {
        const gl2 = this.context.gl as WebGL2RenderingContext;

        gl2.clearBufferfv(gl2.COLOR, 0, this._rendererConfig.clearColor);
        gl2.clearBufferfv(gl2.COLOR, 1, [1, 1, 1, 1]);
        gl2.clearBufferfv(gl2.DEPTH, 0, [1, 1, 1, 1]);
    }
}
