import * as base64 from "base64-arraybuffer";
import { BehaviorSubject, Observable } from "rxjs";

import { ControlClientApi, DatasetDesc, MagnetDesc, SimulationSettings } from "./api";
import { AttributeMap } from "./attributeMap";
import { ColorMap } from "./colorMap";
import { ControlServerAdapter } from "./controlServerAdapter";
import { assertExists, nullToUndefined } from "./helpers";
import { MagnetSetController } from "./magnetSetController";
import { ParticleController } from "./particleController";
import { RpcEndpoint } from "./rpcEndpoint";
import { SimulationControlAdapter } from "./simulationControlAdapter";

const extractFileName = (urlstr: string): string => {
    const url = new URL(urlstr);
    const components = url.pathname.split("/");
    let component: string | undefined = undefined;
    while ((component = components.pop()) !== undefined) {
        if (component !== "") {
            return component;
        }
    }

    return url.hostname;
};

const fetchRemote = async (url: string): Promise<File> => {
    const response = await fetch(url);
    const type = nullToUndefined(response.headers.get("Content-Type"));
    return new File([await response.arrayBuffer()], extractFileName(url), { type });
};

export class DmSession implements ControlClientApi {
    public readonly remote: ControlServerAdapter;
    public readonly magnetController: MagnetSetController;
    public readonly particleController: ParticleController = new ParticleController();
    public readonly heightMap: AttributeMap;
    public readonly colorMap: ColorMap;
    public readonly simulationController: SimulationControlAdapter;

    private readonly _dataset: BehaviorSubject<DatasetDesc | undefined>;
    private readonly _rpcEndpoint: RpcEndpoint;
    private _controlSocket?: WebSocket;
    private _positionSocket?: WebSocket;
    private readonly _baseUrl: string;

    public constructor(baseUrl: string) {
        this._baseUrl = baseUrl;

        this._rpcEndpoint = new RpcEndpoint();
        this._rpcEndpoint.register("onMagnetCreated", this.onMagnetCreated.bind(this));
        this._rpcEndpoint.register("onMagnetChanged", this.onMagnetChanged.bind(this));
        this._rpcEndpoint.register("onMagnetRemoved", this.onMagnetRemoved.bind(this));
        this._rpcEndpoint.register("onDatasetLoaded", this.onDatasetChanged.bind(this));
        this._rpcEndpoint.register("onSimulationSettingsChanged", this.onSimulationSettingsChanged.bind(this));
        this.remote = new ControlServerAdapter(this._rpcEndpoint);

        this._dataset = new BehaviorSubject<DatasetDesc | undefined>(undefined);
        this.heightMap = new AttributeMap(this.dataset, ["ordinal", "numerical"], this.remote);
        this.colorMap = new ColorMap(this.dataset, this.remote);
        this.simulationController = new SimulationControlAdapter(this.remote);
        this.magnetController = new MagnetSetController(this.remote);
    }

    public async connect(): Promise<void> {
        const response = await fetch(`${this._baseUrl}/sessions`, {
            method: "put",
        });
        const responseBody: { sessionID: string } = (await response.json()) as { sessionID: string };
        const sessionID = assertExists(responseBody.sessionID, "Response has no sessionID");

        this._controlSocket = new WebSocket(`${this._baseUrl.replace('http', 'ws')}/sessions/${sessionID}/control`);
        this._controlSocket.onmessage = (event: MessageEvent): void => {
            this._rpcEndpoint.postMessage(event.data as string).catch((reason: string): void => {
                console.log(`Error during handling of RPC message '${event.data}': '${reason}'`);
            });
        };
        this._controlSocket.onopen = (): void => {
            console.log("Control socket connected");
        };
        this._controlSocket.onerror = (event: Event): void => {
            console.error(`WebSocket connection lost: ${event}`);
        };

        this._positionSocket = new WebSocket(`${this._baseUrl.replace('http', 'ws')}/sessions/${sessionID}/position`);
        this._positionSocket.binaryType = "arraybuffer";
        this._positionSocket.onmessage = (event: MessageEvent): void => {
            if (!(event.data instanceof ArrayBuffer)) {
                throw new TypeError("Expected ArrayBuffer message");
            }

            this.particleController.next(new Uint16Array(event.data));
        };
        this._positionSocket.onopen = (): void => {
            console.log("Position socket connected");
        };
        this._positionSocket.onerror = (event: Event): void => {
            console.error(`WebSocket connection lost: ${event}`);
        };

        this._rpcEndpoint.onsend = this._controlSocket.send.bind(this._controlSocket);
    }

    public get dataset(): Observable<DatasetDesc | undefined> {
        return this._dataset.asObservable();
    }

    public onMagnetCreated(desc: MagnetDesc): void {
        this.magnetController.createMagnet(desc);
    }

    public onMagnetChanged(desc: MagnetDesc): void {
        this.magnetController.setMagnet(desc);
    }

    public onMagnetRemoved(desc: MagnetDesc): void {
        this.magnetController.removeMagnet(desc);
    }

    public onDatasetChanged(dataset: DatasetDesc | null): void {
        const datasetOrUndefined = dataset === null ? undefined : dataset;
        this._dataset.next(datasetOrUndefined);
        this.particleController.reset(dataset === null ? 0 : dataset.length);
    }

    public onSimulationSettingsChanged(settings: SimulationSettings): void {
        this.simulationController.update(settings);
    }

    public async importDataset(fileOrUrl: string | File): Promise<void> {
        const file = fileOrUrl instanceof File ? fileOrUrl : await fetchRemote(fileOrUrl);
        console.log(`Uploading ${file.name}...`);
        const base64data = base64.encode(await new Response(file).arrayBuffer());
        await this.remote.importDataset(base64data, file.name);
    }

    public async closeDataset(): Promise<void> {
        await this.remote.importDataset(null, null);
    }
}
