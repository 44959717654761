import { Collapse, createStyles, Link, makeStyles, Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import { distinctUntilChangedImmutable } from "distinct-until-changed-immutable";
import React, { FunctionComponent, PropsWithChildren, useContext, useState } from "react";
import { of } from "rxjs";
import { pluck } from "rxjs/operators";

import { ObservableProperty } from "../observableProperty";
import { Config, DmRenderer } from "../renderer/dmRenderer";
import { ControlGroup } from "./controlGroup";
import { DmRendererContext } from "./dmContext";
import { SelectionPropertyControl } from "./selectionPropertyControl";
import { SliderPropertyControl } from "./sliderPropertyControl";
import { SwitchPropertyControl } from "./switchPropertyControl";

const useStyles = makeStyles(
    (theme: Theme): StyleRules =>
        createStyles({
            expansionSummary: {
                padding: 0,
            },
            expansionPaper: {
                background: "rgba(0, 0, 0, 0)",
                boxShadow: "none",
            },
            controlGroup: {
                marginBottom: theme.spacing(4),
            },
            alignRight: {
                textAlign: "right",
            },
            hidden: {
                display: "none",
            },
        })
);

function observeConfigProperty<K extends keyof Config>(renderer: DmRenderer, key: K): ObservableProperty<Config[K]> {
    return {
        // eslint-disable-next-line id-length
        $: renderer.config$.pipe(
            pluck(key),
            distinctUntilChangedImmutable()
        ),
        get value(): Config[K] {
            return renderer.get(key);
        },
        set value(value: Config[K]) {
            renderer.set(key, value);
        },
    };
}

export interface RenderingControlProps {
    disableGutter?: boolean;
}

// eslint-disable-next-line max-lines-per-function
export const RenderingControl: FunctionComponent<RenderingControlProps> = (
    props: PropsWithChildren<RenderingControlProps>
): JSX.Element | null => {
    const classes = useStyles();
    const renderer = useContext(DmRendererContext);
    const [showExpert, setShowExpert] = useState(false);

    return (
        <ControlGroup title="Rendering" disableGutter={props.disableGutter}>
            <SelectionPropertyControl target={renderer.preset} choices$={of(renderer.presets)} label="Preset" />
            <SwitchPropertyControl target={observeConfigProperty(renderer, "shadow.enabled")} label="Shadow" />
            <SwitchPropertyControl target={observeConfigProperty(renderer, "reflection.enabled")} label="Reflections" />
            <SwitchPropertyControl target={observeConfigProperty(renderer, "fxaa.enabled")} label="FXAA" />
            <Collapse in={showExpert}>
                <div>
                    <SliderPropertyControl
                        target={observeConfigProperty(renderer, "shadow.mapSize")}
                        label="Shadow Map Size"
                        min={512}
                        max={4096}
                        step={null}
                        marks={[
                            { value: 512, label: "512" },
                            { value: 1024, label: "1024" },
                            { value: 2048, label: "2048" },
                            { value: 4096, label: "4096" },
                        ]}
                    />
                    {/* <SliderPropertyControl
                        target={observeConfigProperty(renderer, "shadow.blurSize")}
                        label="Shadow Blur Size"
                        min={3}
                        max={63}
                        step={2} // steps are always counted from 0 and not the min, so this does not yield odd numbers
                    /> */}
                    <SliderPropertyControl
                        target={observeConfigProperty(renderer, "shadow.bleedingReduction")}
                        label="Light Bleeding Reduction"
                        min={0}
                        max={1}
                        step={0.01}
                        valueLabelDecimalPlaces={2}
                    />
                    <SliderPropertyControl
                        target={observeConfigProperty(renderer, "shadow.positiveExponent")}
                        label="Positive Exponent"
                        min={1}
                        max={80}
                    />
                    <SliderPropertyControl
                        target={observeConfigProperty(renderer, "shadow.negativeExponent")}
                        label="Negative Exponent"
                        min={1}
                        max={80}
                    />
                </div>
            </Collapse>
            <div className={classes.alignRight}>
                <Link component="button" onClick={(): void => setShowExpert(!showExpert)}>
                    {showExpert ? "Hide" : "Show"} expert settings
                </Link>
            </div>
        </ControlGroup>
    );
};
