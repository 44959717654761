import { makeStyles, Paper, Popper, Typography } from "@material-ui/core";
import { PopoverPosition } from "@material-ui/core/Popover";
import { createStyles, StyleRules, Theme } from "@material-ui/core/styles";
import { ReferenceObject } from "popper.js";
import React, { FunctionComponent, PropsWithChildren, useEffect, useState } from "react";

const useStyles = makeStyles(
    (theme: Theme): StyleRules =>
        createStyles({
            tooltip: {
                zIndex: theme.zIndex.tooltip,
            },
            tooltipPaper: {
                padding: theme.spacing(0.5),
            },
        })
);

export interface TooltipProps {
    anchorPosition: PopoverPosition;
    text: string;
    onClose?: () => void;
}

class FakeReferenceObject implements ReferenceObject {
    public readonly clientHeight = 1;
    public readonly clientWidth = 1;
    public readonly position: PopoverPosition;

    public constructor(position: PopoverPosition) {
        this.position = position;
    }

    public getBoundingClientRect(): ClientRect {
        return {
            top: this.position.top,
            right: this.position.left + this.clientWidth,
            bottom: this.position.top + this.clientHeight,
            left: this.position.left,
            width: this.clientWidth,
            height: this.clientHeight,
        };
    }
}

export const Tooltip: FunctionComponent<TooltipProps> = (props: PropsWithChildren<TooltipProps>): JSX.Element => {
    const classes = useStyles();
    const [referenceObject, setReferenceObject] = useState(new FakeReferenceObject(props.anchorPosition));

    useEffect((): void => {
        setReferenceObject(new FakeReferenceObject(props.anchorPosition));
    }, [props.anchorPosition]);

    useEffect((): (() => void) => {
        const onClose = props.onClose;
        const events: (keyof WindowEventMap)[] = [
            "mousedown",
            "mouseenter",
            "mouseleave",
            "mousemove",
            "mouseout",
            "mouseover",
            "mouseup",
            "mousewheel",
        ];
        events.forEach((event: keyof WindowEventMap): void => {
            if (onClose !== undefined) {
                window.addEventListener(event, onClose);
            }
        });
        return (): void => {
            events.forEach((event: keyof WindowEventMap): void => {
                if (onClose !== undefined) {
                    window.removeEventListener(event, onClose);
                }
            });
        };
    });

    return (
        <Popper open className={classes.tooltip} anchorEl={referenceObject} placement="top">
            <Paper className={classes.tooltipPaper} square>
                <Typography>{props.text}</Typography>
            </Paper>
        </Popper>
    );
};
