import { distinctUntilChangedImmutable } from "distinct-until-changed-immutable";
import { BehaviorSubject, Observable } from "rxjs";
import { pluck } from "rxjs/operators";

export interface ObservableProperty<T> {
    $: Observable<T>;
    value: T;
}

export class BasicObservableProperty<T> implements ObservableProperty<T> {
    private _value: T;
    private readonly _$: BehaviorSubject<T>;
    private readonly _validator?: (newValue: T) => void;

    public constructor(initialValue: T, validator?: (newValue: T) => void) {
        this._value = initialValue;
        this._$ = new BehaviorSubject(initialValue);
        this._validator = validator;
    }

    // eslint-disable-next-line id-length
    public get $(): Observable<T> {
        return this._$.asObservable();
    }

    public get value(): T {
        return this._value;
    }

    public set value(value: T) {
        if (this._validator !== undefined) {
            this._validator(value);
        }
        this._value = value;
        this._$.next(value);
    }
}

export class RemoteObservableProperty<T> implements ObservableProperty<T> {
    public readonly $: Observable<T>;

    private readonly _getter: () => T;
    private readonly _setter: (value: T) => void;

    public constructor(observable: Observable<T>, getter: () => T, setter: (value: T) => void) {
        // eslint-disable-next-line id-length
        this.$ = observable;
        this._getter = getter;
        this._setter = setter;
    }

    public get value(): T {
        return this._getter();
    }

    public set value(value: T) {
        this._setter(value);
    }
}

export function createRemoteObservableProperty<T, K extends keyof T>(
    object$: Observable<T>,
    property: K,
    initialValue: T[K],
    setter: (object: Partial<T>) => Promise<void>
): ObservableProperty<T[K]> {
    const state = { value: initialValue };
    return new RemoteObservableProperty(
        object$.pipe(
            pluck(property),
            distinctUntilChangedImmutable()
        ),
        (): T[K] => state.value,
        (value: T[K]): void => {
            state.value = value;
            const partial: Partial<T> = {};
            partial[property] = value;
            setter(partial).catch((reason: unknown): void =>
                console.log(`Failed to send setSimulationSettings request: ${reason}`)
            );
        }
    );
}
