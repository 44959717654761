import { ControlServerApi, PartialMagnetDesc, SimulationSettings } from "./api";
import { undefinedToNull } from "./helpers";
import { RpcEndpoint } from "./rpcEndpoint";

export class ControlServerAdapter implements ControlServerApi {
    private readonly _rpcEndpoint: RpcEndpoint;

    public constructor(rpcEndpoint: RpcEndpoint) {
        this._rpcEndpoint = rpcEndpoint;
    }

    public async createMagnet(attribute?: string): Promise<void> {
        await this._rpcEndpoint.requestRemote("createMagnet", undefinedToNull(attribute));
    }

    public async setMagnet(desc: PartialMagnetDesc): Promise<void> {
        await this._rpcEndpoint.notifyRemote("setMagnet", desc);
    }

    public async removeMagnet(id: number): Promise<void> {
        await this._rpcEndpoint.notifyRemote("removeMagnet", id);
    }

    public async importDataset(base64data: string | null, filename: string | null): Promise<void> {
        await this._rpcEndpoint.requestRemote("importDataset", base64data, filename);
    }

    public async getAttributeData(attribute: string): Promise<number[]> {
        return (await this._rpcEndpoint.requestRemote("getAttributeData", attribute)) as number[];
    }

    public async getRawAttributeData(attribute: string): Promise<string[]> {
        return (await this._rpcEndpoint.requestRemote("getRawAttributeData", attribute)) as string[];
    }

    public async setSimulationSettings(settings: Partial<SimulationSettings>): Promise<void> {
        await this._rpcEndpoint.requestRemote("setSimulationSettings", settings);
    }
}
