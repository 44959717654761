import { Typography } from "@material-ui/core";
import Slider, { SliderProps } from "@material-ui/core/Slider";
import React, { ChangeEvent, FunctionComponent, PropsWithChildren, ReactNode } from "react";
import { isArray } from "util";

import { ObservableProperty } from "../observableProperty";
import { useObservableProperty } from "./useObservable";

export interface SliderPropertyControlProps extends Omit<SliderProps, "value" | "onChange" | "onChangeComitted"> {
    target: ObservableProperty<number>;
    label: ReactNode;
    scale?: number;
    valueLabelDecimalPlaces?: number;
}

export const SliderPropertyControl: FunctionComponent<SliderPropertyControlProps> = (
    props: PropsWithChildren<SliderPropertyControlProps>
): JSX.Element => {
    const { target, label, scale, valueLabelDecimalPlaces, ...sliderProps } = props;
    const [value, setValue] = useObservableProperty(target);
    const scaleOrDefault = scale === undefined ? 1 : scale;
    const valueLabelDecimalPlacesOrDefault = valueLabelDecimalPlaces === undefined ? 0 : valueLabelDecimalPlaces;

    const sliderPropsWithDefaults: SliderProps = {
        valueLabelDisplay: "auto",
        valueLabelFormat: (val: number, _index: number): string => val.toFixed(valueLabelDecimalPlacesOrDefault),
        ...sliderProps,
    };

    const handleChange = (_event: ChangeEvent<{}>, newValue: number | number[]): void => {
        if (isArray(newValue)) {
            throw new TypeError("Expected scalar, not array");
        }
        setValue(newValue / scaleOrDefault);
    };

    return (
        <div>
            <Typography>{label}</Typography>
            <Slider value={value * scaleOrDefault} onChange={handleChange} {...sliderPropsWithDefaults} />
        </div>
    );
};
