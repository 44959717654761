import { combineLatest } from "rxjs";
import { Program, VertexArray } from "webgl-operate";

import { RenderPass, RenderPassParameters } from "../renderPass";
import { GroundSharedState } from "./groundPassGroup";
import fragmentShaderSource from "./groundShadowPass.frag";
import vertexShaderSource from "./groundShadowPass.vert";

export class GroundShadowPass extends RenderPass {
    private readonly _shared: GroundSharedState;
    private readonly _program: Program;
    private readonly _vertexArray: VertexArray;

    public constructor(shared: GroundSharedState, baseParameters: RenderPassParameters) {
        super(baseParameters, "GroundShadow");
        this._shared = shared;

        const gl = this._context.gl as WebGLRenderingContext;

        this._program = this.loadShader(vertexShaderSource, fragmentShaderSource, ["in_position"]);
        this._vertexArray = this.createVertexArray([
            { buffer: this._shared.vertexBuffer, size: 3, type: gl.FLOAT, stride: 4 * 12 },
        ]);

        this.createSubscriptions();
    }

    protected onRelease(): void {
        this._program.uninitialize();
        this._vertexArray.uninitialize();
    }

    protected onFrame(): void {
        const gl = this._context.gl as WebGLRenderingContext;

        gl.enable(gl.DEPTH_TEST);

        this._program.bind();
        this._vertexArray.bind();

        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);

        gl.disable(gl.DEPTH_TEST);
    }

    private createSubscriptions(): void {
        this.subscribeUniformMat4(this._light.viewProjection$, this._program, "viewProjection");
        this.subscribeUniform3f(this._light.eye$, this._program, "eye");
        this.subscribeUniform2f(this._light.nearFar$, this._program, "nearFar");
        this.subscribeUniform2f(
            combineLatest(this.config$("shadow.positiveExponent"), this.config$("shadow.negativeExponent")),
            this._program,
            "exponents"
        );
    }
}
