import { makeStyles, Theme } from "@material-ui/core";
import { createStyles, StyleRules } from "@material-ui/core/styles";
import React, { FunctionComponent, useContext } from "react";

import { ColorMapControl } from "./colorMapControl";
import { ControlGroup } from "./controlGroup";
import { DmSessionContext, LabelingContext } from "./dmContext";
import { HeightMapControl } from "./heightMapControl";
import { RenderingControl } from "./renderingControl";
import { SelectionPropertyControl } from "./selectionPropertyControl";
import { SimulationControl } from "./simulationControl";

const useStyles = makeStyles(
    (theme: Theme): StyleRules =>
        createStyles({
            container: {
                padding: theme.spacing(3),
            },
            controlGroup: {
                marginBottom: theme.spacing(4),
            },
        })
);

export const DmSideMenu: FunctionComponent = (): JSX.Element => {
    const session = useContext(DmSessionContext);
    const labeling = useContext(LabelingContext);
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <HeightMapControl map={session.heightMap} />
            <ColorMapControl map={session.colorMap} />
            <ControlGroup title="Labeling">
                <SelectionPropertyControl
                    target={labeling.nameAttribute}
                    choices$={labeling.nameAttributeChoices$}
                    label="Name Attribute"
                />
            </ControlGroup>
            <SimulationControl adapter={session.simulationController} />
            <RenderingControl disableGutter />
        </div>
    );
};
