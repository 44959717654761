import * as base64 from "base64-arraybuffer";
import { combineLatest, from, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { DatasetDesc } from "./api";
import { AttributeMap } from "./attributeMap";
import { ControlServerAdapter } from "./controlServerAdapter";
import { BasicObservableProperty } from "./observableProperty";

export class ColorMap extends AttributeMap {
    public static readonly schemes: string[] = ["inferno", "magma", "plasma", "viridis"];
    public static readonly nullSchemeData = new Uint8Array(
        base64.decode(
            "iVBORw0KGgoAAAANSUhEUgAAAQAAAAABCAIAAAC+O+cgAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAEklEQVQoz2NsaGhgGAWjYKQCAIS+AYLENvWLAAAAAElFTkSuQmCC"
        )
    );

    public static readonly nullSchemeObjectUrl = URL.createObjectURL(
        new Blob([ColorMap.nullSchemeData], { type: "image/png" })
    );

    public scheme = new BasicObservableProperty<string | undefined>(
        ColorMap.schemes[0],
        (scheme: string | undefined): void => {
            if (scheme !== undefined && !ColorMap.schemes.includes(scheme)) {
                throw new RangeError(`Scheme ${scheme} does not exist`);
            }
        }
    );

    private readonly _schemeData: Map<string, Promise<Uint8Array>> = new Map();
    private readonly _schemeObjectUrls: Map<string, Promise<string>> = new Map();

    public constructor(dataset: Observable<DatasetDesc | undefined>, remote: ControlServerAdapter) {
        super(dataset, ["nominal", "ordinal", "numerical"], remote);
    }

    private static async fetchSchemeData(scheme: string): Promise<Uint8Array> {
        return new Uint8Array(await (await fetch(`./data/colormaps/${scheme}.png`)).arrayBuffer());
    }

    // eslint-disable-next-line @typescript-eslint/promise-function-async
    public getSchemeData(scheme: string): Promise<Uint8Array> {
        let data = this._schemeData.get(scheme);
        if (data === undefined) {
            data = ColorMap.fetchSchemeData(scheme);
            this._schemeData.set(scheme, data);
        }
        return data;
    }

    // eslint-disable-next-line @typescript-eslint/promise-function-async
    public getSchemeObjectUrl(scheme: string): Promise<string> {
        let url = this._schemeObjectUrls.get(scheme);
        if (url === undefined) {
            url = (async (): Promise<string> =>
                URL.createObjectURL(new Blob([await this.getSchemeData(scheme)], { type: "image/png" })))();
            this._schemeObjectUrls.set(scheme, url);
        }
        return url;
    }

    public get schemeObjectUrls$(): Observable<Map<string, string>> {
        return combineLatest(
            ColorMap.schemes.map(
                (scheme: string): Observable<[string, string]> =>
                    from(this.getSchemeObjectUrl(scheme)).pipe(map((url: string): [string, string] => [scheme, url]))
            ),
            (...pairs: [string, string][]): Map<string, string> => new Map(pairs)
        );
    }

    public get schemeObjectUrl$(): Observable<string | undefined> {
        return combineLatest(this.scheme.$, this.enabled.$).pipe(
            switchMap(
                ([scheme, enabled]: [string | undefined, boolean]): Observable<string> =>
                    enabled && scheme !== undefined
                        ? from(this.getSchemeObjectUrl(scheme))
                        : of(ColorMap.nullSchemeObjectUrl)
            )
        );
    }
}
