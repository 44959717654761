/* eslint-disable @typescript-eslint/member-ordering */

import React, { createRef, PureComponent, RefObject } from "react";
import { Canvas, Color, Renderer } from "webgl-operate";

import styles from "./fill.module.css";

export interface GlCanvasProps {
    renderer: Renderer;
}

export class GlCanvas extends PureComponent<GlCanvasProps> {
    private _canvas?: Canvas;
    private readonly _canvasElement: RefObject<HTMLCanvasElement>;

    public constructor(props: GlCanvasProps) {
        super(props);

        this._canvasElement = createRef();
    }

    public componentDidMount(): void {
        this.createCanvas();
    }

    public componentDidUpdate(): void {
        this.disposeCanvas();
        this.createCanvas();
    }

    public componentWillUnmount(): void {
        this.disposeCanvas();
    }

    private createCanvas(): void {
        if (this._canvasElement.current === null) {
            throw new TypeError("this._canvasElement.current is null");
        }

        const contextAttributes: WebGLContextAttributes = {
            alpha: false,
            antialias: false,
            depth: true,
            failIfMajorPerformanceCaveat: true,
            premultipliedAlpha: false,
            preserveDrawingBuffer: true,
            stencil: false,
        };
        this._canvas = new Canvas(this._canvasElement.current, contextAttributes);
        this._canvas.clearColor = new Color([1, 1, 1, 1]);
        this._canvas.renderer = this.props.renderer;
    }

    private disposeCanvas(): void {
        if (this._canvas !== undefined) {
            this._canvas.dispose();
            this._canvas = undefined;
        }
    }

    // eslint-disable-next-line class-methods-use-this
    public render(): JSX.Element {
        return <canvas className={styles.fill} data-backend="webgl2" ref={this._canvasElement} />;
    }
}
