import { PopoverPosition } from "@material-ui/core/Popover";
import React, { FunctionComponent, PropsWithChildren, useContext, useEffect, useState } from "react";
import { combineLatest, Observable } from "rxjs";

import { LabelingContext } from "./dmContext";
import { Tooltip } from "./tooltip";

export interface TooltipRequest {
    anchorPosition: PopoverPosition;
    particleId: number;
}

export interface TooltipProviderProps {
    requests: Observable<TooltipRequest>;
}

export const TooltipProvider: FunctionComponent<TooltipProviderProps> = (
    props: PropsWithChildren<TooltipProviderProps>
): JSX.Element | null => {
    const labeling = useContext(LabelingContext);
    const [activeRequest, setActiveRequest] = useState<[PopoverPosition, string] | undefined>(undefined);

    useEffect((): (() => void) => {
        const subscription = combineLatest(
            props.requests,
            labeling.particleNames$,
            (request: TooltipRequest, names: string[]): [PopoverPosition, string] => [
                request.anchorPosition,
                names[request.particleId],
            ]
        ).subscribe(setActiveRequest);

        return (): void => subscription.unsubscribe();
    }, [props.requests, labeling.particleNames$, setActiveRequest]);

    function cancelRequest(): void {
        setActiveRequest(undefined);
    }

    if (activeRequest === undefined) {
        return null;
    }

    const [position, text] = activeRequest;

    return <Tooltip anchorPosition={position} text={text} onClose={cancelRequest} />;
};
