import { createStyles, Fab, Grow, makeStyles, Theme, Tooltip } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import React, { FunctionComponent, MouseEvent, useCallback, useContext, useState } from "react";

import { AddMagnetMenu } from "./addMagnetMenu";
import { DmSessionContext } from "./dmContext";
import { HelpTooltip } from "./helpTooltip";
import { useObservable } from "./useObservable";

const useStyles = makeStyles(
    (theme: Theme): StyleRules =>
        createStyles({
            fab: {
                position: "fixed",
                bottom: theme.spacing(3),
                right: theme.spacing(3),
            },
        })
);

// eslint-disable-next-line max-lines-per-function
export const AddMagnet: FunctionComponent = (): JSX.Element | null => {
    const session = useContext(DmSessionContext);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const dataset = useObservable(session.dataset);
    const [tooltipAnchor, setTooltipAnchor] = useState<HTMLElement | null>(null);
    const tooltipAnchorRef = useCallback(setTooltipAnchor, []);
    const [clicked, setClicked] = useState(false);

    function openMenu(event: MouseEvent<HTMLButtonElement>): void {
        setAnchorEl(event.currentTarget);
        if (!clicked) {
            setClicked(true);
        }
    }

    function closeMenu(): void {
        setAnchorEl(null);
    }

    return (
        <>
            <Grow in={dataset !== undefined && anchorEl === null}>
                <Tooltip title="Add Magnet">
                    <Fab
                        color="secondary"
                        className={classes.fab}
                        onClick={openMenu}
                        disabled={session.dataset === undefined}
                        ref={tooltipAnchorRef}
                    >
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </Grow>
            <AddMagnetMenu onClose={closeMenu} anchorEl={anchorEl} />
            <HelpTooltip
                open={
                    !clicked &&
                    dataset !== undefined &&
                    anchorEl === null &&
                    session.magnetController.magnets.length === 0
                }
                anchorEl={tooltipAnchor}
                placement="left"
                text="Click here to add a new magnet."
                delay={3000}
            />
        </>
    );
};
