import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { Context, Framebuffer, Texture2D } from "webgl-operate";
import { GLsizei2 } from "webgl-operate/lib/tuples";

import { assert, assertExists } from "../helpers";
import { Config, RendererConfig } from "./dmRenderer";
import { FxaaPass } from "./fxaa/fxaaPass";
import { createRgbaBuffer } from "./glHelpers";
import { RenderPassParameters } from "./renderPass";

export class PostProcessingProvider {
    private readonly _sceneColorBuffer: Texture2D;
    private colorBuffer?: Texture2D;
    private framebuffer?: Framebuffer;
    private fxaaPass?: FxaaPass;
    private readonly _passParameters: RenderPassParameters;
    private readonly _context: Context;

    private readonly _config$: <K extends keyof Config>(property: K) => Observable<Config[K]>;
    private readonly _rendererConfig$: <K extends keyof RendererConfig>(property: K) => Observable<RendererConfig[K]>;
    private readonly _subscriptions: Subscription[] = [];
    private readonly _colorBuffer$: BehaviorSubject<Texture2D>;

    public constructor(
        sceneColorBuffer: Texture2D,
        passParameters: RenderPassParameters,
        context: Context,
        config$: <K extends keyof Config>(property: K) => Observable<Config[K]>,
        rendererConfig$: <K extends keyof RendererConfig>(property: K) => Observable<RendererConfig[K]>
    ) {
        this._sceneColorBuffer = sceneColorBuffer;
        this._passParameters = passParameters;
        this._context = context;
        this._config$ = config$;
        this._rendererConfig$ = rendererConfig$;

        this._colorBuffer$ = new BehaviorSubject<Texture2D>(this._sceneColorBuffer);

        this._config$("fxaa.enabled").subscribe(this.setEnabled.bind(this));
    }

    public release(): void {
        this.setEnabled(false);
    }

    public get colorBuffer$(): Observable<Texture2D> {
        return this._colorBuffer$.asObservable();
    }

    public prepare(): void {
        if (this.fxaaPass !== undefined) {
            this.fxaaPass.prepare();
        }
    }

    public frame(): void {
        if (this.framebuffer === undefined) {
            return;
        }

        this.framebuffer.bind();
        assertExists(this.fxaaPass, "Expected fxaaPass to exist when framebuffer exists").frame();
    }

    private setEnabled(enabled: boolean): void {
        if (enabled) {
            this.enable();
        } else {
            this.disable();
        }
    }

    private enable(): void {
        if (this.framebuffer !== undefined) {
            return;
        }
        assert(this.colorBuffer === undefined, "Expected colorBuffer to not exist when framebuffer does not exist");
        assert(this.fxaaPass === undefined, "Expected fxaaPass to not exist when framebuffer does not exist");

        if (!this._context.isWebGL2) {
            console.warn("PostProcessing requires WebGL2");
            return;
        }

        const gl = this._context.gl as WebGLRenderingContext;

        this.colorBuffer = createRgbaBuffer(this._context, "postColor");
        const framebuffer = new Framebuffer(this._context, "postFramebuffer");
        framebuffer.initialize([[gl.COLOR_ATTACHMENT0, this.colorBuffer]]);
        this.framebuffer = framebuffer;

        this.fxaaPass = new FxaaPass(this._passParameters);
        this.fxaaPass.colorTexture = this._sceneColorBuffer;

        this._subscriptions.push(
            this._rendererConfig$("frameSize").subscribe((frameSize: GLsizei2): void => {
                framebuffer.resize(...frameSize);
            })
        );

        this._colorBuffer$.next(this.colorBuffer);
    }

    private disable(): void {
        if (this.framebuffer === undefined) {
            return;
        }

        assertExists(this.colorBuffer, "Expected colorBuffer to exist when framebuffer exists").uninitialize();
        this.colorBuffer = undefined;
        this.framebuffer.uninitialize();
        this.framebuffer = undefined;

        assertExists(this.fxaaPass, "Expected fxaaPass to exist when framebuffer exists").release();
        this.fxaaPass = undefined;

        while (this._subscriptions.length > 0) {
            assertExists(this._subscriptions.pop()).unsubscribe();
        }

        this._colorBuffer$.next(this._sceneColorBuffer);
    }
}
