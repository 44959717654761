import { MenuItem, TextField } from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField";
import React, { ChangeEvent, FunctionComponent, PropsWithChildren, ReactNode } from "react";
import { Observable } from "rxjs";

import { ObservableProperty } from "../observableProperty";
import { useObservable, useObservableProperty } from "./useObservable";

const defaultItemGenerator = (choice: string): JSX.Element => (
    <MenuItem key={choice} value={choice}>
        {choice}
    </MenuItem>
);

export interface SelectionPropertyControlProps
    extends Omit<TextFieldProps, "value" | "onChange" | "select" | "multiline" | "type"> {
    target: ObservableProperty<string | undefined>;
    choices$: Observable<string[]>;
    label?: ReactNode;
    itemGenerator?: (choice: string) => JSX.Element;
}

export const SelectionPropertyControl: FunctionComponent<SelectionPropertyControlProps> = (
    props: PropsWithChildren<SelectionPropertyControlProps>
): JSX.Element => {
    const { target, choices$, label, itemGenerator, ...textFieldProps } = props;
    const [value, setValue] = useObservableProperty(target);
    const choices = useObservable(choices$, []);

    const textFieldPropsWithDefaults: TextFieldProps = {
        variant: "filled",
        margin: "dense",
        fullWidth: true,
        ...textFieldProps,
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setValue(event.target.value);
    };

    return (
        <TextField select label={label} value={value} onChange={handleChange} {...textFieldPropsWithDefaults}>
            {choices.map(itemGenerator === undefined ? defaultItemGenerator : itemGenerator)}
        </TextField>
    );
};
