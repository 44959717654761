import { Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { Buffer, Texture2D } from "webgl-operate";

import { SwitchSubject } from "../../operators";
import { RenderPass, RenderPassParameters } from "../renderPass";
import { GroundScenePass } from "./groundScenePass";
import { GroundShadowPass } from "./groundShadowPass";

/* eslint-disable @typescript-eslint/indent */
// prettier-ignore
const VERTEX_DATA = new Float32Array([
  /* Position      Tangent       Bitangent     Normal */
    -1,  0, -1,    1,  0,  0,    0,  0,  1,    0,  1,  0,
    -1,  0,  1,    1,  0,  0,    0,  0,  1,    0,  1,  0,
     1,  0, -1,    1,  0,  0,    0,  0,  1,    0,  1,  0,
     1,  0,  1,    1,  0,  0,    0,  0,  1,    0,  1,  0,
]);
/* eslint-enable @typescript-eslint/indent */

export interface GroundSharedState {
    readonly vertexBuffer: Buffer;
    readonly shadowMap$: Observable<Texture2D | undefined>;
    readonly shadowEnabled$: Observable<GLuint>;
    readonly reflectionMap$: Observable<Texture2D | undefined>;
    readonly reflectionEnabled$: Observable<GLuint>;
}

export class GroundPassGroup extends RenderPass {
    public readonly shadowMap$ = new SwitchSubject<Texture2D | undefined>(undefined);
    public readonly reflectionMap$ = new SwitchSubject<Texture2D | undefined>(undefined);

    public readonly scenePass: GroundScenePass;
    public readonly shadowPass: GroundShadowPass;

    private readonly _shared: GroundSharedState;

    public constructor(baseParameters: RenderPassParameters) {
        super(baseParameters, "Ground");

        this._shared = {
            vertexBuffer: this.createVertexBuffer(),
            shadowMap$: this.shadowMap$,
            shadowEnabled$: this.shadowMap$.pipe(
                map((texture: Texture2D | undefined): GLuint => (texture === undefined ? 0 : 1)),
                distinctUntilChanged()
            ),
            reflectionMap$: this.reflectionMap$,
            reflectionEnabled$: this.reflectionMap$.pipe(
                map((texture: Texture2D | undefined): GLuint => (texture === undefined ? 0 : 1)),
                distinctUntilChanged()
            ),
        };
        this.scenePass = new GroundScenePass(this._shared, baseParameters);
        this.scenePass.redraw$.subscribe(this.redraw$);
        this.shadowPass = new GroundShadowPass(this._shared, baseParameters);
        this.shadowPass.redraw$.subscribe(this.redraw$);
    }

    protected onRelease(): void {
        this.scenePass.release();
        this.shadowPass.release();
    }

    protected onUpdate(): void {
        this.scenePass.update();
        this.shadowPass.update();
    }

    protected onPrepare(): void {
        this.scenePass.prepare();
        this.shadowPass.prepare();
    }

    // eslint-disable-next-line class-methods-use-this
    protected onFrame(): void {
        throw new Error("Don't call GroundPassGroup.frame(), call GroundPassGroup.[xyz]Pass.frame() instead!");
    }

    private createVertexBuffer(): Buffer {
        const gl = this._context.gl as WebGLRenderingContext;

        const buffer = new Buffer(this._context, `${this.name}.vertexBuffer`);
        buffer.initialize(gl.ARRAY_BUFFER);
        buffer.data(VERTEX_DATA, gl.STATIC_DRAW);

        return buffer;
    }
}
