/* eslint-disable no-bitwise */
import { mat4, ReadbackPass, vec2, vec3 } from "webgl-operate";
import { GLsizei2 } from "webgl-operate/lib/tuples";

import { MagnetPassGroup } from "./magnets/magnetPassGroup";

export interface PickResult {
    instanceId: GLuint;
    objectId: number;
    objectType: "magnet" | "particle";
}

export class ObjectPicker {
    private readonly _readback: ReadbackPass;
    private readonly _magnetPass: MagnetPassGroup;

    public constructor(readback: ReadbackPass, magnetPass: MagnetPassGroup) {
        this._readback = readback;
        this._magnetPass = magnetPass;
    }

    public pick(viewportPosition: GLsizei2 | vec2): PickResult | undefined {
        let instanceId: GLsizei | undefined = this._readback.idAt(viewportPosition[0], viewportPosition[1]);
        if (instanceId === undefined) {
            return undefined;
        }

        instanceId >>>= 0; // Interpret as unsigned

        // Clear color -- hit nothing
        if (instanceId === 0xffffffff) {
            return undefined;
        }

        // Discard alpha channel which is always 255 due to blending
        instanceId &= 0x00ffffff;

        // If blue channel is 0xFF, RG encode a magnet id
        if (instanceId >= 0x00ff0000) {
            instanceId &= 0x0000ffff; // Discard blue channel

            return this.pickMagnet(instanceId);
        }

        // If blue channel is < 0xFF, RGB encode a dust particle id
        return this.pickParticle(instanceId);
    }

    public depthAt(viewportPosition: GLsizei2 | vec2): GLfloat | undefined {
        return this._readback.depthAt(viewportPosition[0], viewportPosition[1]);
    }

    public coordsAt(
        viewportPosition: GLsizei2 | vec2,
        zInNDC: GLfloat | undefined,
        viewProjectionInverse: mat4
    ): vec3 | undefined {
        return this._readback.coordsAt(viewportPosition[0], viewportPosition[1], zInNDC, viewProjectionInverse);
    }

    // eslint-disable-next-line class-methods-use-this
    private pickParticle(instanceId: GLuint): PickResult {
        return { instanceId, objectId: instanceId, objectType: "particle" };
    }

    private pickMagnet(instanceId: GLuint): PickResult {
        const objectId = this._magnetPass.magnetId(instanceId);

        return { instanceId, objectId, objectType: "magnet" };
    }
}
