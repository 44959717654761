import { makeStyles, Switch, Toolbar, Typography } from "@material-ui/core";
import { createStyles, StyleRules, Theme } from "@material-ui/core/styles";
import React, { ChangeEvent, FunctionComponent, PropsWithChildren } from "react";

const useStyles = makeStyles(
    (theme: Theme): StyleRules<string> =>
        createStyles({
            spacer: {
                flexGrow: 1,
            },
            gutter: {
                marginBottom: theme.spacing(3),
            },
        })
);

export interface ControlGroupProps {
    title: string;
    checked?: boolean;
    onChange?: (checked: boolean) => void;
    disableGutter?: boolean;
}

export const ControlGroup: FunctionComponent<ControlGroupProps> = (
    props: PropsWithChildren<ControlGroupProps>
): JSX.Element => {
    const classes = useStyles();

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (props.onChange !== undefined) {
            props.onChange(event.target.checked);
        }
    };

    return (
        <div className={props.disableGutter !== undefined && props.disableGutter ? undefined : classes.gutter}>
            <Toolbar variant="dense" disableGutters>
                <Typography variant="h6">{props.title}</Typography>
                {props.onChange === undefined ? null : (
                    <>
                        <div className={classes.spacer} />
                        <Switch checked={props.checked} onChange={handleChange} />
                    </>
                )}
            </Toolbar>
            {props.children}
        </div>
    );
};
