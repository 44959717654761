import { Menu, MenuItem } from "@material-ui/core";
import React, { FunctionComponent, PropsWithChildren, useContext } from "react";

import { AttributeDesc, DatasetDesc } from "../api";
import { DmSessionContext } from "./dmContext";
import { useObservable } from "./useObservable";

interface AddMagnetMenuProps {
    anchorEl: null | Element;
    onClose: () => void;
}

interface AttributeMenuItemProps {
    attribute: string;
    onClick: (attribute: string) => void;
}

const AttributeMenuItem: FunctionComponent<AttributeMenuItemProps> = (
    props: PropsWithChildren<AttributeMenuItemProps>
): JSX.Element => {
    function onClick(): void {
        props.onClick(props.attribute);
    }

    return <MenuItem onClick={onClick}>{props.attribute}</MenuItem>;
};

export const AddMagnetMenu: FunctionComponent<AddMagnetMenuProps> = (
    props: PropsWithChildren<AddMagnetMenuProps>
): JSX.Element | null => {
    const session = useContext(DmSessionContext);
    const dataset: DatasetDesc | undefined = useObservable(session.dataset, undefined);

    if (dataset === undefined) {
        return null;
    }

    const handleSelect = (attribute: string): void => {
        props.onClose();
        session.remote.createMagnet(attribute).catch(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (reason: any): void => {
                console.log(`Failed to send createMagnet request: ${reason}`);
            }
        );
    };

    return (
        <Menu
            anchorEl={props.anchorEl}
            open={Boolean(props.anchorEl)}
            onClose={props.onClose}
            keepMounted
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "bottom", horizontal: "right" }}
            getContentAnchorEl={undefined}
        >
            {dataset.attributes
                .map((attribute: AttributeDesc): string => attribute.name)
                .sort((lhs: string, rhs: string): number =>
                    lhs.localeCompare(rhs, undefined, { sensitivity: "base", numeric: true })
                )
                .map(
                    (attribute: string): JSX.Element => {
                        return <AttributeMenuItem key={attribute} attribute={attribute} onClick={handleSelect} />;
                    }
                )}
        </Menu>
    );
};
