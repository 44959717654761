import { FormControlLabel, Switch } from "@material-ui/core";
import { SwitchProps } from "@material-ui/core/Switch";
import React, { ChangeEvent, FunctionComponent, PropsWithChildren, ReactNode } from "react";

import { ObservableProperty } from "../observableProperty";
import { useObservableProperty } from "./useObservable";

export interface SwitchPropertyControlProps extends Omit<SwitchProps, "checked" | "onChange"> {
    target: ObservableProperty<boolean>;
    label: ReactNode;
}

export const SwitchPropertyControl: FunctionComponent<SwitchPropertyControlProps> = (
    props: PropsWithChildren<SwitchPropertyControlProps>
): JSX.Element => {
    const { target, label, ...switchProps } = props;
    const [checked, setChecked] = useObservableProperty(target);

    const switchPropsWithDefaults: SwitchProps = {
        color: "primary",
        ...switchProps,
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setChecked(event.target.checked);
    };

    return (
        <FormControlLabel
            // eslint-disable-next-line react/jsx-props-no-spreading
            control={<Switch checked={checked} onChange={handleChange} {...switchPropsWithDefaults} />}
            label={label}
        />
    );
};
