import { PopoverPosition } from "@material-ui/core/Popover";
import React, { FunctionComponent, PropsWithChildren, useContext, useEffect, useState } from "react";
import { Observable } from "rxjs";

import { DmSessionContext } from "./dmContext";
import { DmContextMenu } from "./dmContextMenu";

export interface ContextMenuRequest {
    anchorPosition: PopoverPosition;
    magnetId: number;
}

export interface DmContextMenuProviderProps {
    requests: Observable<ContextMenuRequest>;
}

export const DmContextMenuProvider: FunctionComponent<DmContextMenuProviderProps> = (
    props: PropsWithChildren<DmContextMenuProviderProps>
): JSX.Element | null => {
    const session = useContext(DmSessionContext);
    const [request, setRequest] = useState<ContextMenuRequest | undefined>(undefined);

    useEffect((): (() => void) => {
        const subscription = props.requests.subscribe(
            (newValue: ContextMenuRequest): void => {
                setRequest(newValue);
            }
        );
        return (): void => subscription.unsubscribe();
    }, []);

    function cancelRequest(): void {
        setRequest(undefined);
    }

    if (request === undefined) {
        return null;
    }

    const magnet = session.magnetController.magnet(request.magnetId);
    if (magnet === undefined) {
        return null;
    }

    return <DmContextMenu magnet={magnet} anchorPosition={request.anchorPosition} onClose={cancelRequest} />;
};
